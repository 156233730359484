import { Row, Col } from "antd";
import { primaryColor } from "colors-config";
const PrescriptionTreatmentTypes = ({ prescriptionPDFObj }) => {
  return (
    <div className="prescriptionPDF-card">
      <h2>Treatment Type</h2>
      {prescriptionPDFObj.treatmentTypes?.length &&
        prescriptionPDFObj.treatmentTypes.map((treatment, i) => {
          return (
            <div key={i} style={{ position: "relative" }}>
              <div
                className="num-btn"
                style={{ backgroundColor: primaryColor }}
              >
                {i + 1}
              </div>
              <div
                className="prescriptionPDF-treat"
                style={{ paddingLeft: "20px" }}
              >
                <div className="border-right">
                  <label className="treatment-label">Aligner</label>
                  <p className="treatment-info">{treatment.aligner}</p>
                </div>

                <div>
                  <div className="border-right">
                    <label className="treatment-label">Arches Treated</label>
                    <p className="treatment-info">{treatment.arches}</p>
                  </div>
                </div>
                <div
                  className="border-right"
                >
                  <label className="treatment-info">
                    {treatment.whitening === "No" ? "No Retainer And Whitening Tray" : treatment.whitening}
                  </label>
                  <p className="treatment-info">{treatment.whitening_arches}</p>
                </div>
                <div style={{ paddingLeft: "20px" }}>
                  <div>
                    <label className="treatment-label">Comment</label>
                    <p className="treatment-info">
                      {treatment.comment == null ? "--" : treatment.comment}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PrescriptionTreatmentTypes;
