import { Button,Row } from "antd";
import { useRef } from "react";
import dayjs from "dayjs";
import { DownloadOutlined } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import { useReactToPrint } from "react-to-print";
import classes from "assets/styles/routes/assesmentForm.module.scss";

import {
  PATIENT_RESPONSIBILITY,
  ALIGNER_TERMS,
  TOOTH_SENSITIVITY_DAMAGE,
  GUM_TISSUE,
  ROOT_RESORPTION,
  FINAL_CONSENT,
  TREATMENT_TIME,
  TMJ,
  LIMITATION,
  MEDICATION,
  OCCURENCE,
  TERMINATION_OF_TREATMENT,
  EXPECTATIONS,
  RELAPSE,
  INFO,
} from "../../../utilities/consentFormJson";

const pageStyle = `
body {
  -webkit-print-color-adjust: exact;
  font-family: "Mulish", sans-serif;
  color: #000;
}

h2, h3 {
  page-break-before: avoid;
  margin: 10px 0;
}
h2{
  margin-top:0;
}

pre {
  font-size: 14px;
  page-break-inside: auto;
  word-wrap: break-word; /* Ensures long words wrap */
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, old versions */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
  margin: 0px;
  color: #343434;
  font-family: "Mulish", sans-serif;
}
.no-print {
  display: none;
}
`;

const ConsentFormCard = ({ patientDetails, clinic, clinicLongAddress }) => {
  const consentFormRef = useRef();
  const onConsentFormDownload = useReactToPrint({
    content: () => {
      const printImageContainer = document.getElementById("clinic-info-print");
      printImageContainer.style.display = "block";

      return consentFormRef.current;
    },
    onAfterPrint: () => {
      document.querySelectorAll(".print-part-element").forEach(function (container) {
        if (container) {
          container.style.display = "none";
        }
      });
      const printImageContainer = document.getElementById("clinic-info-print");
      printImageContainer.style.display = "none";
    },
    pageStyle,
  });
  if(!patientDetails?.consent_date){
    return(
      <div style={{ paddingTop: "1.5rem" }} className="padding-class">
      <Row className="questionnaire-responses-component ">
      Consent Form is yet to be accepted by the patient.
      </Row>
    </div>
    )
  }
  return (
    <div className="consent-form-container">
      <div className="consent-form-button-container">
        <Button onClick={onConsentFormDownload} type="link" className="download-button-consent">
          Download
          <DownloadOutlined />
        </Button>
      </div>
      <div ref={consentFormRef} className="print-consent-form">
        <ConsentForm
          clinicLongAddress={clinicLongAddress}
          clinic={clinic}
          patientDetails={patientDetails}
        />
      </div>
    </div>
  );
};

const ConsentForm = ({ patientDetails, clinic, clinicLongAddress }) => {

  const consentDate = patientDetails.consent_date
    ? dayjs(patientDetails.consent_date).format("DD-MM-YYYY")
    : "";
    
  return (
    <div className="consent-form-wrapper">
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <div className={classes["upper-case"] + " consent-title"} style={{ color: primaryColor }}>
            {patientDetails.patient_first_name} {patientDetails.patient_last_name}
          </div>
          <div className="consent-date">{consentDate}</div>
        </div>
        <div id="clinic-info-print" style={{ display: "none", justifySelf: "flex-end" }}>
          <div className={classes["clinic-info"]}>
            <div className={classes["upper-case"]}>{clinic?.clinicName || ""}</div>
            <div className={classes["upper-case"]}>{clinicLongAddress || ""}</div>
            <div className={classes["upper-case"]}>
              {clinic?.clinicCountryCode || ""}
              {clinic?.clinicPhoneNumber || ""}
            </div>
            <div>{clinic?.clinicEmail || ""}</div>
          </div>
        </div>
      </div>

      <div className="consent-details">
        <h2>Treatment Informed Consent</h2>
        <pre className={classes["pre-styles"]}>{INFO.formText}</pre>
        <h3>The patient's Responsibility</h3>
        <pre>{PATIENT_RESPONSIBILITY.formText}</pre>
        <h3>Aligners:</h3>
        <pre>{ALIGNER_TERMS.formText}</pre>
        <h3>Oral Hygiene:</h3>
        <pre>{ALIGNER_TERMS.formText}</pre>
        <h3>Tooth Sensitivity/Damage:</h3>
        <pre>{TOOTH_SENSITIVITY_DAMAGE.formText}</pre>
        <h3>Root resorption:</h3>
        <pre>{ROOT_RESORPTION.formText}</pre>
        <h3>Gum & Bone Tissues:</h3>
        <pre>{GUM_TISSUE.formText}</pre>
        <h3>Treatment Time:</h3>
        <pre>{TREATMENT_TIME.formText}</pre>
        <h3>TMJ:</h3>
        <pre>{TMJ.formText}</pre>

        <h3>Limitations:</h3>
        <pre>{LIMITATION.formText}</pre>
        <h3>Medications:</h3>
        <pre>{MEDICATION.formText}</pre>

        <h3>Very unsual occurrences:</h3>
        <pre>{OCCURENCE.formText}</pre>
        <h3>Termination of Treatment:</h3>
        <pre>{TERMINATION_OF_TREATMENT.formText}</pre>

        <h3>Expectations:</h3>
        <pre>{EXPECTATIONS.formText}</pre>
        <h3>Relapse:</h3>
        <pre>{RELAPSE.formText}</pre>

        <h3>Final Consent:</h3>
        <pre>{FINAL_CONSENT.formText}</pre>
      </div>
    </div>
  );
};

export default ConsentFormCard;
