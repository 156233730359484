import { useState, useEffect, useMemo } from "react";
import { Row, Col, Typography, message, Select, Modal, Button, Tooltip, Dropdown } from "antd";
import "../../../assets/styles/components/patient-journey/patientTreatmentDetailsComponent.scss";
import GenericButton from "../../shared/buttons/genericButton";
import { ExclamationCircleFilled } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import { CheckCircleOutlined, DownOutlined } from "@ant-design/icons";
import { ReactComponent as ExpandIcon } from "../../../assets/images/expand_icon.svg";
import {
  createStripePaymentIntentAPI,
  setPatientTreatmentApproveAPI,
  startRevisionTreatmentApi,
  startTreatmentApi,
} from "api/patient";
import { dentistClinicApiTasks } from "models/dentistClinic";
import { getRefinementData } from "api/clinic";
import { companyType, primaryColor } from "colors-config";
import EndTreatment from "./endTreatment/index";
import { JOURNEY_STATUS, TAG_STATUS, TREATMENT_STATUS } from "../../../constants";
import IPRDetail from "components/IPRDetail/IPRDetail";
import ModifyPlan from "./PatientTreatmentCard/Modals/ModifyPlan";
import AppTag from "components/shared/Tag/Tag";
import { getTreatmentButtonText } from "utilities/prescription";
import ApproveSentToPatient from "./TreatmentSection/ApproveSentToPatient";
import ModificationRequestModal from "../ModificationRequestModal/ModificationRequestModal";
import TreatmentDetailsModal from "../patient-journey/endTreatment/treatmentDetailsModal";
import { addCommentApi, sendMessage } from "api/chat";
import { getOrderBtnBackground } from "utilities/settings";
import { checkClinicIntercomTourStatus, updateClinicIntercomStatus } from "api/clinic";
import RefinementViewModal from "../patient-journey/endTreatment/refinementViewModal";
import StartRefinementModal from './StartRefinementModal'
import { getOrderBtnText } from "utilities/commonMethod";
import TreatmentFrame from "../TreatmentFrame/TreatmentFrame";
const { confirm } = Modal;

const { Title } = Typography;
const { Option } = Select;
function PatientTreatmentDetailsComponent({
  wholeTreatmentPlan,
  treatmentPlansState,
  treatmentSetup,
  confirmTreatmentStatus,
  treatmentPlans,
  setShowOlderPlansModal,
  labOrders,
  getOrders,
  patientOrders,
  setShowLabOrderModal,
  patientInfo,
  patientId,
  getPatientInformation,
  paymentStatus,
  setPaymentStatus,
  setPayCheckOrder,
  paidStatus,
  setPaidStatus,
  setDropdownLabTreatmentId,
  dropdownLabTreatmentId,
  setOrderRetainerClick,
  status,
  getCurrentTreatmentStatus,
  alignerList,
  setOrderRefinementClick,
  isModalUpdateOrderVisible,
  setIsModalUpdateOrderVisible,
  setFromPatientJourneyButton,
  productList,
  clinic,
  treatmentProgressDet,
  getData,
  sendAppStage,
  setIsModalSendVisible,
  setSendOrderWithoutPatientApproval,
  setSharePatientMessage,
  setFromSendToPatientAppRegistration,
  isModalApproveSendPlanVisible,
  setIsModalApproveSendPlanVisible,
  setFromShareWithPatient,
  setPatientInfoState,
  fromShareWithPatient,
}) {
  const [treatment, setTreatment] = useState({});
  const [checkTreatment, setCheckTreatment] = useState([]);
  const [labOrder, setLabOrder] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalConsentVisible, setIsModalConsentVisible] = useState(false);
  const [consentPopLoading, setConsentPopLoading] = useState(false);
  const [isModifyPlanTouched, setIsModifyPlanTouched] = useState(false);
  const [isModalApprovePlanVisible, setIsModalApprovePlanVisible] = useState(false);
  const [approvePlanPopLoading, setApprovePlanPopLoading] = useState(false);
  const [isTourExecuted, setIsTourExecuted] = useState(false);
  const [refinementRequested, setRefinementRequested] = useState(false);
  const [showRefinementButton, setShowRefinementButton] = useState(false);
  const [viewStartRefinementModal, setViewStartRefinementModal] = useState(false);
  // const [isModalApproveSendPlanVisible, setIsModalApproveSendPlanVisible] = useState(false);

  // const [isModalUpdateOrderVisible, setIsModalUpdateOrderVisible] = useState(false);

  const [treatmentValue, setTreatmentValue] = useState(1);
  // const [paymentStatus, setPaymentStatus] = useState(mainPaymentStatus);

  const [modifyPlanPopLoading, setModifyPlanPopLoading] = useState(false);
  const [isModalModifyPlanVisible, setIsModalModifyPlanVisible] = useState(false);
  const [modifyComment, setModifyComment] = useState("");
  const [productOrServiceCurrencyOptions, setProductOrServiceCurrencyOptions] = useState([]);
  const [modificationModalOpen, setModificationModalOpen] = useState(false);
  const [treatmentDetailModalOpen, setTreatmentDetailModalOpen] = useState(false);

  const [refinementData, setRefinementData] = useState();
  const [refinementViewModalOpen, setRefinementViewModalOpen] = useState(false);

  const getRefinementDetails = async (patient_id) => {
    if (patient_id) {
      const refinementDetailsRes = await getRefinementData(patient_id);
      if (
        refinementDetailsRes?.data?.data ||
        status.clinic_patient_journey_status_name == JOURNEY_STATUS.TREATMENT_COMPLETED
      ) {
        setShowRefinementButton(false);
        if (status.clinic_patient_journey_status_name == JOURNEY_STATUS.REFINEMENT_REQUESTED) {
          setRefinementRequested(true);
        }
      } else {
        setShowRefinementButton(true);
      }
      setRefinementData(refinementDetailsRes.data.data);
    }
  };

  useEffect(() => {
    if (treatment?.patient_id) {
      getRefinementDetails(treatment.patient_id);
    }
  }, [treatment]);

  const parseCurrency = (currencies) => {
    const currencyArray = currencies.map((currency) => {
      return currency;
    });
    return currencyArray;
  };

  useEffect(() => {
    async function fetchClinicOptions() {
      try {
        const res = await dentistClinicApiTasks.getAll("clinic/getClinicListOfValues");

        setProductOrServiceCurrencyOptions(parseCurrency(res.body.currencyNames));
      } catch (error) {
        console.error(error);
      }
    }

    fetchClinicOptions();
  }, []);


  useEffect(() => {
    if (
      !isTourExecuted &&
      treatment?.lab_treatment_id &&
      treatment?.approval_status === TREATMENT_STATUS.PENDING
    ) {
      setIsTourExecuted(true);
      if (
        !labOrders?.length ||
        (treatment.revision_plan_version && checkPatientLabOrder(treatment))
      ) {
        startIntercomTour();
      }
    }
  }, [treatment, labOrders, isTourExecuted]);

  const startIntercomTour = async () => {
    console.log(
      "process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT",
      process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT
    );
    if (
      process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT &&
      process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT !== ""
    ) {
      const clinicIntercomTourStats = await checkClinicIntercomTourStatus(clinic.clinicId);
      if (
        !clinicIntercomTourStats?.data?.data?.intercomData ||
        clinicIntercomTourStats?.data?.data?.intercomData?.sendToPatient === false
      ) {
        setTimeout(async () => {
          window.Intercom("startTour", process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT);
          await updateClinicIntercomStatus(clinic.clinicId, {
            sendToPatient: true,
          });
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (treatmentSetup.length) {
      const defaultTreatment = dropdownApprovedTreatment(wholeTreatmentPlan);
      setTreatment(defaultTreatment);
      setDropdownLabTreatmentId(defaultTreatment?.lab_treatment_id);

      setTreatmentValue(defaultTreatment?.treatment_plan_version);
    } else setTreatment({});
  }, [treatmentSetup, wholeTreatmentPlan]);

  useEffect(() => {
    if (labOrders.length) {
      setLabOrder(labOrders[0]);
    }
  }, [labOrders]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleConsentCancel = () => {
    setIsModalConsentVisible(false);
  };
  const handleApprovePlanCancel = () => {
    setIsModalApprovePlanVisible(false);
  };
  const handleApproveSendPlanCancel = () => {
    setIsModalApproveSendPlanVisible(false);
    setFromShareWithPatient(false);
  };

  const showConfirm = () => {
    confirm({
      title: "Close Modification form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
        },
      },
      onOk() {
        setIsModalModifyPlanVisible(false);
        setModifyComment("");
        setIsModifyPlanTouched(false);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  const handleModifyPlanCancel = () => {
    if (isModifyPlanTouched) {
      showConfirm();
    } else {
      setIsModalModifyPlanVisible(false);
      setModifyComment("");
      setIsModifyPlanTouched(false);
    }
  };

  const payAtClinic = async () => {
    payAtClinicApi();
    // setIsModalConsentVisible(false);
  };

  const approvePlanOk = async () => {
    try {
      setApprovePlanPopLoading(true);
      const inputData = {
        patientId: treatment.patient_id,
        treatmentSetupId: treatment.lab_treatment_id,
        caseId: treatment.case_id,
        status: true,
      };
      const treatmentApproveResponse = await setPatientTreatmentApproveAPI(inputData);
      console.log("treatment.lab_treatment_id", treatment.lab_treatment_id);
      setDropdownLabTreatmentId(treatment.lab_treatment_id);
      message.success("Patient Treatment has been approved.");
      treatmentPlans();
      getPatientInformation();
    } catch (error) {
      console.log("error", error);
      message.success("Error occured!");
    } finally {
      setApprovePlanPopLoading(false);
      handleApprovePlanCancel();
    }
  };

  const modifyPlanOk = async () => {
    try {
      setModifyPlanPopLoading(true);
      await confirmTreatmentStatus(treatment?.lab_treatment_id, "rejected", modifyComment);

      sendMessage({
        meta: {
          sender_id: clinic.clinicId,
          receiver_id: treatment.lab_id,
          module: "clinic",
          patient_id: patientId,
        },
        message: `
        <p><strong>Modification Request</strong></p>
        <p>${modifyComment}</p>
        `,
      });
      // await addCommentApi({
      //   sender_id: clinic.clinicId,
      //   receiver_id: treatment.lab_id,
      //   comment_description: `
      //   <p><strong>Modification Request</strong></p>
      //   <p>${modifyComment}</p>
      //   `,
      //   module: "clinic",
      //   patient_id: patientId,
      // })
    } catch (err) {
      console.log("err", err);
    } finally {
      setModifyPlanPopLoading(false);
      setIsModalModifyPlanVisible(false);
    }
  };

  const startTreatment = async () => {
    const findLabOrder = labOrders.find(
      (obj) => obj.lab_treatment_id == treatment.lab_treatment_id
    );
    let patientOrderId = labOrder.patient_order_id;

    if (findLabOrder) {
      patientOrderId = findLabOrder.patient_order_id;
    }
    let apiPromise;
    if (treatment.revision_plan_version) {
      apiPromise = startRevisionTreatmentApi({
        order_id: patientOrderId,
        treatmentId: treatment.lab_treatment_id,
      });
    } else {
      apiPromise = startTreatmentApi({
        order_id: patientOrderId,
      });
    }

    apiPromise.then((res) => {
      message.success("Patient Treatment has been started.");

      setIsModalVisible(false);
      getData();
      // getOrders();
    });
  };

  const payAtClinicApi = async () => {
    setConsentPopLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/patient/treatmentSetup/price?patientId=${patientId}`)
      .then(async (res) => {
        if (res?.data?.body?.length) {
          const payObj = { ...res.data.body[0] };

          const insertData = {
            clinic_id: treatment?.clinic_id,
            patient_id: patientId,
            product_id: payObj.product_id,
            product_type_id: payObj.product_type_id,
            product_name: payObj.product_name,
            product_name_id: payObj.product_name_id,
            product_price: payObj?.price?.toFixed(2),
            product_currency_id: payObj.product_currency_id,
            currency_name: payObj.currency_name,
            payment_method: "payatclinic",
            paymentType: "offline",
            notify: false,
            client: "clinic",
          };
          await createStripePaymentIntentAPI(insertData);
          setConsentPopLoading(false);
          getOrders();
          treatmentPlans();
          setIsModalConsentVisible(false);
          getPatientInformation();
        } else {
          // message.error("Some Error Occured!");
        }

        //
      });
  };

  useMemo(() => {
    setCheckTreatment(
      wholeTreatmentPlan?.map((i) => ({
        ...i,
        label: i.revision_plan_version
          ? `Refinement Plan Version ${i.revision_plan_version}.0`
          : `Treatment Plan Version ${i.treatment_plan_version}.0`,
        value: i.treatment_plan_version,
      }))
    );
  }, [wholeTreatmentPlan]);

  const dropdownApprovedTreatment = (wholeTreatmentPlan) => {
    const findApprovedTreatment = wholeTreatmentPlan.find((obj) => obj.approved);
    return findApprovedTreatment ? findApprovedTreatment : wholeTreatmentPlan[0];
  };

  const checkPatientLabOrder = (treatment) => {
    const findLabOrder = labOrders.find(
      (obj) => obj.lab_treatment_id == treatment.lab_treatment_id
    );
    return !findLabOrder;
  };

  const onOrderWithoutPatientClick = () => {
    setFromPatientJourneyButton(true);
    setSendOrderWithoutPatientApproval(true);
    if (!paymentStatus || paymentStatus == "-" || paymentStatus == "Awaiting Payment") {
      setIsModalUpdateOrderVisible(true);
    } else {
      // setPayCheckOrder(true); // if need to place order on patient behalf
      setShowLabOrderModal(true);
      if (treatment.revision_plan_version) {
        setOrderRefinementClick(true);
      }
    }
  };

  const onSendPatientApprovalClick = () => {
    //

    if (sendAppStage == 0) {
      setIsModalSendVisible(true);
      setSharePatientMessage(true);
      setFromSendToPatientAppRegistration(true);
    } else {
      setIsModalApproveSendPlanVisible(true);
    }
  };

  const items = [
    {
      key: "1",
      label: "Approve without sharing with patient",
      onClick: onOrderWithoutPatientClick,
    },
    {
      key: "2",
      label: "Send to patient for approval",
      onClick: onSendPatientApprovalClick,
    },
  ];
  const statusMessageComponent = () => {
    if (treatment?.approval_status === TREATMENT_STATUS.REJECTED) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            textAlign: "right",
          }}
          className="treatment-actions-buttons"
        >
          {wholeTreatmentPlan.length > 1 ? (
            <Button
              shape="round"
              className="app-button-class"
              type="primary"
              onClick={() => {
                setIsModalApprovePlanVisible(true);
              }}
              style={{ marginRight: "6px" }}
            >
              Select plan
            </Button>
          ) : (
            ""
          )}
          <Button
            shape="round"
            type="default"
            onClick={() => setModificationModalOpen(true)}
            style={{ height: "40px" }}
          >
            View Modification Request
          </Button>
          <div style={{ display: "flex" }}>
            <Button
              shape="round"
              type="default"
              onClick={() => setTreatmentDetailModalOpen(true)}
              style={{ height: "40px" }}
            >
              Treatment Details
            </Button>
            <AppTag
              color={TAG_STATUS.ERROR}
              text={JOURNEY_STATUS.MODIFICATION_REQUESTED + ""}
              customStyle={{ height: "40px", marginLeft: "10px" }}
            />
          </div>
        </div>
      );
    } else if (treatment?.approval_status === TREATMENT_STATUS.PENDING) {
      return (
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          {refinementData && (
            <Button
              // className="app-button-class"
              shape="round"
              type="default"
              onClick={() => setRefinementViewModalOpen(true)}
            >
              View Refinement details
            </Button>
          )}
          <div>
            {!labOrders?.length ||
            (treatment.revision_plan_version && checkPatientLabOrder(treatment)) ? (
              <Button shape="round" onClick={() => setIsModalModifyPlanVisible(true)}>
                Modify Plan
              </Button>
            ) : (
              ""
            )}
            {!labOrders?.length ||
            (treatment.revision_plan_version && checkPatientLabOrder(treatment)) ? (
              <Dropdown
                menu={{
                  items,
                }}
              >
                <Button
                  shape="round"
                  style={{
                    marginLeft: "5px",
                    background: getOrderBtnBackground(),
                  }}
                  type="primary"
                >
                  {getOrderBtnText(refinementData)} <DownOutlined />
                </Button>
              </Dropdown>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (treatment?.approval_status === TREATMENT_STATUS.APPROVED) {
      return (
        <div style={{ display: "flex", width: "100%" }}>
          <EndTreatment
            setViewStartRefinementModal={setViewStartRefinementModal}
            setRefinementViewModalOpen={setRefinementViewModalOpen}
            refinementViewModalOpen={refinementViewModalOpen}
            getRefinementDetails={getRefinementDetails}
            refinementData={refinementData}
            setRefinementData={setRefinementData}
            showRefinementButton={showRefinementButton}
            refinementRequested={refinementRequested}
            setRefinementRequested={setRefinementRequested}
            treatment={treatment}
            patientInfo={patientInfo}
            setShowLabOrderModal={setShowLabOrderModal}
            setOrderRetainerClick={setOrderRetainerClick}
            status={status}
            getCurrentTreatmentStatus={getCurrentTreatmentStatus}
            alignerList={alignerList}
            getPatientInformation={getPatientInformation}
            setIsModalModifyPlanVisible={setIsModalModifyPlanVisible}
            labOrders={labOrders}
            setTreatment={setTreatment}
            setIsModalVisible={setIsModalVisible}
            labOrder={labOrder}
            setIsModalApprovePlanVisible={setIsModalApprovePlanVisible}
            setIsModalApproveSendPlanVisible={setIsModalApproveSendPlanVisible}
            sendAppStage={sendAppStage}
            setIsModalSendVisible={setIsModalSendVisible}
            setSharePatientMessage={setSharePatientMessage}
            treatmentPlans={treatmentPlans}
            setTreatmentDetailModalOpen={setTreatmentDetailModalOpen}
            setFromShareWithPatient={setFromShareWithPatient}
            setPatientInfoState={setPatientInfoState}
            setShowRefinementButton={setShowRefinementButton}
            setFromPatientJourneyButton={setFromPatientJourneyButton}
            orderButtonRender={
              (treatment.revision_plan_version &&
                // treatment.sendToPatient!=0 &&
                checkPatientLabOrder(treatment) &&
                treatment?.approved) ||
              // treatment.sendToPatient!=0 &&
              (checkPatientLabOrder(treatment) && !labOrders?.length && treatment?.approved) ? (
                <Button
                  type="primary"
                  shape="round"
                  className="app-button-class"
                  onClick={() => {
                    setFromPatientJourneyButton(true);
                    // setIsModalConsentVisible(true);
                    if (
                      !paymentStatus ||
                      paymentStatus == "-" ||
                      paymentStatus == "Awaiting Payment"
                    ) {
                      setIsModalUpdateOrderVisible(true);
                    } else {
                      setPayCheckOrder(true);
                      setShowLabOrderModal(true);
                      if (treatment.revision_plan_version) {
                        setOrderRefinementClick(true);
                      }
                    }
                  }}
                >
                  Order
                </Button>
              ) : (
                ""
              )
            }
          />
        </div>
      );
    } else {
      return "";
    }
  };

  const handleChange = (value) => {
    setTreatment(wholeTreatmentPlan.filter((i) => i.treatment_plan_version === value)[0]);
    setDropdownLabTreatmentId(
      wholeTreatmentPlan.filter((i) => i.treatment_plan_version === value)[0]?.lab_treatment_id
    );
    setTreatmentValue(value);
  };

  const onOrderTreatmentClick = (index) => {
    console.log("here", index);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "53.2rem",
        // maxHeight: "53.5rem",
        boxShadow: "0px 10px 16px rgba(0, 0, 0, 0.02)",
        borderRadius: "8px",
        backgroundColor: "white",
        paddingBottom: "1rem",
      }}
    >
      {treatmentSetup?.length > 0 && checkTreatment?.length > 0 ? (
        <div>
          <div className="treatment-dropdown-container">
            <Row
              justify="space-between"
              style={{
                flexDirection: "row",
                alignItems: "center",
                textAlign: "left",
                padding: "8px 24px",
              }}
            >
              <Col>
                <Title level={5} style={{ margin: "unset" }}>
                  {checkTreatment.length > 1 ? (
                    <Select
                      size="large"
                      // defaultValue={dropdownApprovedTreatment()}
                      value={treatmentValue}
                      onChange={(value) => {
                        handleChange(value);
                      }}
                    >
                      {checkTreatment.map((item, index) => {
                        return (
                          <Option
                            className="treatment-selectbox-multi"
                            key={index}
                            value={item.value}
                          >
                            {item.label} {item.approved ? <CheckCircleOutlined /> : ""}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <span>{`Treatment Plan Version ${treatment?.treatment_plan_version}.0`}</span>
                  )}
                  <Tooltip title="Open in another tab">
                    <ExpandIcon
                      style={{
                        width: "18px",
                        height: "auto",
                        marginLeft: "5px",
                        cursor: "pointer",
                        marginBottom: "-3px",
                        fill: primaryColor,
                      }}
                      onClick={() => {
                        window.open(treatment?.treatment_plan_link);
                      }}
                    />
                  </Tooltip>
                </Title>
              </Col>
              <Col>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  {treatment?.approval_status === TREATMENT_STATUS.APPROVED && (
                    <div>
                      {/* {status.clinic_patient_journey_status_name === JOURNEY_STATUS.ALIGNER_IN_PRODUCTION && (
              <AppTag color={TAG_STATUS.DEFAULT} text={JOURNEY_STATUS.ALIGNER_IN_PRODUCTION} />
              )} */}
                    </div>
                  )}
                  <div style={{ display: "block" }}>
                    {
                      // treatment?.approval_status !== "pending" &&
                      moment(treatment?.date_created).format("DD-MM-YYYY")
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row
            justify="space-between"
            style={{
              borderBottom: "1px solid lightgray",
              flexDirection: "row",
              alignItems: "center",
              textAlign: "left",
              padding: "8px 24px",
            }}
          >
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                width: "100%",
              }}
              className="treatment-actions-buttons"
            >
              {statusMessageComponent()}
            </div>
          </Row>
          <TreatmentFrame treatment={treatment}/>
          <IPRDetail
            treatment={treatment}
            treatmentPlansState={treatmentPlansState}
            status={status}
            clinic={clinic}
            patientInfo={patientInfo}
          />
        </div>
      ) : (
        <>
          {!treatmentSetup?.length && (
            <div>
              <Row
                justify="space-between"
                style={{
                  borderBottom: "1px solid #f0f0f0",
                  height: "3.65rem",
                  textAlign: "left",
                  padding: "1rem",
                }}
              >
                <Col>
                  <Title style={{ marginTop: 0 }} level={5}>
                    {" "}
                    Treatment Plan
                  </Title>
                </Col>
                <Col style={{ marginTop: "-0.5rem" }}>{statusMessageComponent()}</Col>
              </Row>
              <Row className="empty-plan-component ">No Treatment Plan has been added yet.</Row>
            </div>
          )}
        </>
      )}

      {isModalVisible && (
        <Modal
          title="Start Aligner Treatment"
          okText="Confirm"
          cancelText="Not Yet"
          visible={isModalVisible}
          onOk={startTreatment}
          onCancel={handleCancel}
          okButtonProps={{
            style: { width: "7rem", borderRadius: "30px" },
          }}
          cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
        >
          <p>Are you sure you want to start?</p>
        </Modal>
      )}
      {isModalConsentVisible && (
        <Modal
          title="Pay & Consent"
          confirmLoading={consentPopLoading}
          okText="Yes"
          cancelText="No"
          visible={isModalConsentVisible}
          onOk={payAtClinic}
          onCancel={handleConsentCancel}
          okButtonProps={{
            style: { width: "7rem", borderRadius: "30px" },
          }}
          cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
        >
          <p>Are you sure you want pay at clinic?</p>
        </Modal>
      )}

      {isModalApprovePlanVisible && (
        <Modal
          title="Select plan & Send"
          confirmLoading={approvePlanPopLoading}
          okText="Confirm"
          cancelText="Cancel"
          visible={isModalApprovePlanVisible}
          onOk={approvePlanOk}
          onCancel={handleApprovePlanCancel}
          okButtonProps={{
            style: { width: "7rem", borderRadius: "30px" },
          }}
          cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
        >
          <p>Do you want to select this plan and send to patient?</p>
        </Modal>
      )}

      {isModalApproveSendPlanVisible && (
        <ApproveSentToPatient
          isModalApproveSendPlanVisible={isModalApproveSendPlanVisible}
          handleApproveSendPlanCancel={handleApproveSendPlanCancel}
          treatment={treatment}
          productList={productList}
          treatmentProgressDet={treatmentProgressDet}
          productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
          setIsModalApproveSendPlanVisible={setIsModalApproveSendPlanVisible}
          confirmTreatmentStatus={confirmTreatmentStatus}
          patientId={patientId}
          clinic={clinic}
          wholeTreatmentPlan={wholeTreatmentPlan}
          fromShareWithPatient={fromShareWithPatient}
          setFromShareWithPatient={setFromShareWithPatient}
          setSendOrderWithoutPatientApproval={setSendOrderWithoutPatientApproval}
        />
      )}

      <ModifyPlan
        modifyComment={modifyComment}
        setModifyComment={setModifyComment}
        modifyPlanPopLoading={modifyPlanPopLoading}
        modifyPlanOk={modifyPlanOk}
        handleModifyPlanCancel={handleModifyPlanCancel}
        isModalModifyPlanVisible={isModalModifyPlanVisible}
        setIsModifyPlanTouched={setIsModifyPlanTouched}
      />
      {modificationModalOpen && (
        <ModificationRequestModal
          modificationModalOpen={modificationModalOpen}
          setModificationModalOpen={setModificationModalOpen}
          modifyComment={treatment?.modifyComment}
        />
      )}
      {treatmentDetailModalOpen && (
        <TreatmentDetailsModal
          treatmentDetailModalOpen={treatmentDetailModalOpen}
          setTreatmentDetailModalOpen={setTreatmentDetailModalOpen}
          treatment={treatment}
          setTreatment={setTreatment}
          patientInfo={patientInfo}
        />
      )}

      {refinementViewModalOpen && (
        <RefinementViewModal
          refinementModalOpen={refinementViewModalOpen}
          setRefinementModalOpen={setRefinementViewModalOpen}
          treatment={treatment}
          refinementData={refinementData}
        />
      )}

      {viewStartRefinementModal && (
        <StartRefinementModal
          alignerList={alignerList}
          labOrders={labOrders}
          labOrder={labOrder}
          treatment={treatment}
          viewStartRefinementModal={viewStartRefinementModal}
          setViewStartRefinementModal={setViewStartRefinementModal}
          getData={getData}
        />
      )}
    </div>
  );
}

export default PatientTreatmentDetailsComponent;
