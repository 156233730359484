import { useState, useEffect } from "react";

import {
  Form,
  Input,
  Select,
  Row,
  Divider,
  Checkbox,
  message,
  ConfigProvider,
} from "antd";
import { v4 as uuidv4 } from "uuid";

import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import DeleteConfirmationModal from "../../shared/modals/deleteConfirmationModal";
import { InfoIcon } from "components/shared/InfoIcon/index";
import { companyType, primaryColor, appCurrencyName } from "colors-config";

const { Option } = Select;

const ProductsAndServicesFormComponent = ({
  setProductOrServiceTypes,
  productOrServiceFields,
  productOrServiceTypes,
  productOrServiceTypeOptions,
  productOrServiceNameOptions,

  productOrServiceCurrencyOptions,

  setProductOrServiceNameOptions,
  removeFromDB,
  mode,
  paymentList,
}) => {
  const [newProductOrServiceTypeOption, setNewProductOrServiceTypeOption] =
    useState("");
  const [newProductOrServiceNameOption, setNewProductOrServiceNameOption] =
    useState("");

  const [showdeleteConfirmationModal, setShowdeleteConfirmationModal] =
    useState(false);

  const [selectedProductOrServiceType, setSelectedProductOrServiceType] =
    useState(
      productOrServiceFields.productOrServiceType
        ? JSON.parse(productOrServiceFields.productOrServiceType)
            .product_type_id
        : productOrServiceTypeOptions[0].product_type_id
    );

  const [hideProductOrService, setHideProductOrService] = useState(false);
  const [disablePrice, setDisablePrice] = useState(productOrServiceFields?.free ?  true : false);
  const [hideFromPatient, setHideFromPatient] = useState(productOrServiceFields?.hidePatientPrice ?  true : false);


  const [form] = Form.useForm();
 
  const initialFormValues = {
    paymentIds: productOrServiceFields?.paymentIds || [],
    productOrServiceType: productOrServiceFields.productOrServiceType
      ? productOrServiceFields.productOrServiceType
      : JSON.stringify(productOrServiceTypeOptions[0]),

    productOrServiceName: productOrServiceFields.productOrServiceName
      ? productOrServiceFields.productOrServiceName
      : JSON.stringify(
          productOrServiceNameOptions[selectedProductOrServiceType][0]
        ),

    currency: productOrServiceFields.currency
      ? productOrServiceFields.currency
      : JSON.stringify(appCurrencyName),
    price: productOrServiceFields.price ? productOrServiceFields.price : "0",
    description: productOrServiceFields.description
      ? productOrServiceFields.description
      : "",
    discount: productOrServiceFields.discount,
    total: isNaN(productOrServiceFields.price - productOrServiceFields.discountAmount) ? "0" : (productOrServiceFields.price - productOrServiceFields.discountAmount),
    discountAmount: productOrServiceFields.discountAmount ? productOrServiceFields.discountAmount : "0",
    free: productOrServiceFields?.free ? true : false,
    hidePatientPrice: productOrServiceFields?.hidePatientPrice ? true : false,

  };

  useEffect(() => {
    form.resetFields();

    const formValues = form.getFieldsValue(true);
    const productOrServiceTypeId = productOrServiceFields.id;
    if (mode !== "view") {
      setProductOrServiceTypes({
        ...productOrServiceTypes,
        [productOrServiceTypeId]: { ...productOrServiceFields, ...formValues },
      });
    }
  }, []);
  useEffect(() => {
    if (mode === "edit" && Object.keys(productOrServiceTypes).length === 1) {
      const formValues = form.getFieldsValue(true);
      const productOrServiceTypeId = productOrServiceFields.id;
      setProductOrServiceTypes({
        ...productOrServiceTypes,
        [productOrServiceTypeId]: { ...productOrServiceFields, ...formValues },
      });
    }
  }, [mode]);

  const currencySelector = (
    <Form.Item name="currency" noStyle>
      <Select size="large"
       showSearch
       filterOption={(input, option) =>
         option.children
           .toLowerCase()
           .indexOf(input.toLowerCase()) >= 0
       }
      disabled={mode === "view" || disablePrice} style={{ width: 80 }}>
        {productOrServiceCurrencyOptions.map((currency) => {
          return (
            <Option key={JSON.stringify(currency)}>
              {currency.currency_name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const onFinish = (clinicDetailsForm) => {
    // console.log(clinicDetailsForm, "clinic details");
  };
  function onValueChange(val) {
    const productOrServiceTypeId = productOrServiceFields.id;
    if (val.productOrServiceType) {
      const productOrServiceTypeSelected = JSON.parse(
        val?.productOrServiceType
      );
      setSelectedProductOrServiceType(
        productOrServiceTypeSelected?.product_type_id
      );
      form.setFieldsValue({
        productOrServiceName: JSON.stringify(
          productOrServiceNameOptions[
            productOrServiceTypeSelected?.product_type_id
          ][0]
        ),
      });

      setProductOrServiceTypes({
        ...productOrServiceTypes,
        [productOrServiceTypeId]: {
          ...productOrServiceFields,
          productOrServiceName: JSON.stringify(
            productOrServiceNameOptions[
              productOrServiceTypeSelected?.product_type_id
            ][0]
          ),
          productOrServiceType: val?.productOrServiceType,
        },
      });
    } else {
      if (val?.discountAmount) {
        onPriceChange(val.discountAmount, "discountAmount");
      } else {
        setProductOrServiceTypes({
          ...productOrServiceTypes,
          [productOrServiceTypeId]: { ...productOrServiceFields, ...val },
        });
      }

      if (val?.price) {
        onPriceChange(val.price, "price");
      }
      if (val?.discount) {
        onPriceChange(val.discount, "discount");
      }
    }
  }

  const onProductOrServiceTypeOptionChange = (e) => {
    const newType = e.target.value;
    setNewProductOrServiceTypeOption(newType);
  };

  const onProductOrServiceNameOptionChange = (e) => {
    const newName = e.target.value;
    setNewProductOrServiceNameOption(newName);
  };

  const addProductOrServiceNameOption = () => {
    const newProductOrServiceNameOptions = { ...productOrServiceNameOptions };
    const excistingservice = newProductOrServiceNameOptions[
      selectedProductOrServiceType
    ].some(
      (service) =>
        service.product_name.toLowerCase() ===
        newProductOrServiceNameOption.toLowerCase()
    );
    if (excistingservice) {
      return message.error("Duplicate product or service name");
    }

    if (
      newProductOrServiceNameOption.trim().length !== 0 &&
      newProductOrServiceNameOption.length <= 30
    ) {
      newProductOrServiceNameOptions[selectedProductOrServiceType].push(
        { product_name_id: "", product_name: newProductOrServiceNameOption }
        // newProductOrServiceNameOption
      );

      setProductOrServiceNameOptions(newProductOrServiceNameOptions);
      setNewProductOrServiceNameOption("");
    } else {
      if (newProductOrServiceNameOption.length > 30) {
        message.error("Input cannot be more than 30 characters");
      } else {
        message.error("Input must be valid");
      }
    }
  };

  const removeProductOrServiceType = () => {
    setShowdeleteConfirmationModal(true);
  };
  const remove = async () => {
    const deleteProductOrServiceId = productOrServiceFields.id;
    if (mode !== "edit") {
      const newProductOrServiceTypes = { ...productOrServiceTypes };
      delete newProductOrServiceTypes[deleteProductOrServiceId];
      setProductOrServiceTypes(newProductOrServiceTypes);
      message.success("Successfully removed");
    }

    if (mode === "edit") {
      if (!isNaN(deleteProductOrServiceId)) {
        try {
          await removeFromDB(deleteProductOrServiceId, "product");
          const newProductOrServiceTypes = { ...productOrServiceTypes };
          delete newProductOrServiceTypes[deleteProductOrServiceId];
          setProductOrServiceTypes(newProductOrServiceTypes);
          message.success("Successfully removed");
        } catch (error) {
          console.log(error);
        }
      } else {
        const newProductOrServiceTypes = { ...productOrServiceTypes };
        delete newProductOrServiceTypes[deleteProductOrServiceId];
        setProductOrServiceTypes(newProductOrServiceTypes);
        message.success("Successfully removed");
      }
    }
  };

  const onHideProductOrService = (e) => {
    const shouldHide = e.target.checked;
    setHideProductOrService(shouldHide);
  };

  const createHeader = (
    // mode !== "view" ? (
    <span>
      {`${
        productOrServiceFields.productOrServiceType
          ? JSON.parse(productOrServiceFields.productOrServiceType).product_type
          : productOrServiceTypeOptions[0].product_type
      } - ${
        productOrServiceFields.productOrServiceName
          ? JSON.parse(productOrServiceFields.productOrServiceName).product_name
          : productOrServiceNameOptions[selectedProductOrServiceType][0]
              .product_name
      }`}
    </span>
  );
  //  : (
  //   <span>
  //     {`${productOrServiceFields.productOrServiceType} - ${productOrServiceFields.productOrServiceName}`}
  //   </span>
  // );

  const onPriceChange = (value, key) => {
    // console.log("productOrServiceTypes", productOrServiceTypes)
    
    const productOrServiceTypeId = productOrServiceFields.id;
    // console.log("productOrServiceTypeId", productOrServiceTypeId)
    if (key == "discount") {
      const price = form.getFieldValue("price");
      const totalPrice = calculateTotalPrice(price, value);
      form.setFieldsValue({
        total: totalPrice,
        discountAmount: price - totalPrice,
      });
      const saveData = {
        discount: value,
        discountAmount: price - totalPrice,
        total: totalPrice,
      };

      const productOrServiceTypeId = productOrServiceFields.id;
      setProductOrServiceTypes({
        ...productOrServiceTypes,
        [productOrServiceTypeId]: { ...productOrServiceFields, ...saveData },
      });

    }
    if (key == "price") {
      const discount = form.getFieldValue("discount");
      const totalPrice = calculateTotalPrice(value, discount);
      const discountPrice = value - totalPrice
      form.setFieldsValue({
        total: totalPrice,
        discountAmount: discountPrice,
      });
      const saveData = {
        discount: discount,
        discountAmount: discountPrice,
        price: value
      };
     
   

      setProductOrServiceTypes({
        ...productOrServiceTypes,
        [productOrServiceTypeId]: { ...productOrServiceFields, ...saveData },
      });
    }
    if (key == "discountAmount") {
      const price = form.getFieldValue("price");
      const total = price - value;
      const diffPercentage = (total / price) * 100;
      const discount = Number(100 - diffPercentage).toFixed(1);
      form.setFieldsValue({
        total: total,
        discount: discount,
        discountAmount: value,
      });

      const saveData = {
        discount: discount,
        discountAmount: value,
      };
      const productOrServiceTypeId = productOrServiceFields.id;
   

      setProductOrServiceTypes({
        ...productOrServiceTypes,
        [productOrServiceTypeId]: { ...productOrServiceFields, ...saveData },
      });
    }
  };


  const calculateTotalPrice = (price, discount) => {
    var numVal1 = Number(price ? price : 0);
    var numVal2 = Number(discount ? discount : 0);

    const totalValue = numVal1 * ((100 - numVal2) / 100);
    return Math.ceil(totalValue);
  };


  const onDisablePrice = (e) => {
    const shouldDisable = e.target.checked;
    setDisablePrice(shouldDisable);
  };

  const onHideFromPatient = (e) => {
    const shouldDisable = e.target.checked;
    setHideFromPatient(shouldDisable)
  }

  const hideCheckboxId = uuidv4();

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "6px",
        fontFamily: "Mulish",
      }}
    >
      {showdeleteConfirmationModal && (
        <DeleteConfirmationModal
          title="Remove ProductOrService type"
          content={createHeader}
          showModal={showdeleteConfirmationModal}
          onConfirmation={remove}
          setShow={setShowdeleteConfirmationModal}
          mode={"onboarding"}
        ></DeleteConfirmationModal>
      )}
      <Form
            style={{
              width: "95%",
              marginLeft: "2rem",
              paddingBottom: "0.5rem",
              marginTop: "-12px",
            }}
            form={form}
            onValuesChange={onValueChange}
            name="complex-form"
            layout={"vertical"}
            initialValues={initialFormValues}
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
          >
      <Row
        style={{
          // marginLeft: "2rem",
          marginTop: "1rem",
          fontWeight: "500",
          paddingBottom: "0.5rem",
          fontWeight: "bold",
        }}
      >
        {createHeader}{" "}
        <span
          style={{
            color: "red",
            cursor: "pointer",
            display: mode === "view" ? "none" : "",
          }}
          onClick={removeProductOrServiceType}
        >
          <DeleteOutlined style={{ margin: "0 0.5rem" }} />
          Remove
        </span>
        <span style={{ marginLeft: "1rem" }}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: primaryColor,
                borderRadius: 4,
              },
            }}
          >
            <Form.Item
                label=""
                name="hidePatientPrice"
                valuePropName="checked"
                style={{
                  marginTop: '-5px',
                  color:'black'
                }}
              >
              <Checkbox id={`hide_checkbox_${hideCheckboxId}`}  disabled={mode === "view"} onChange={onHideFromPatient}>
                <label style={{cursor:"pointer"}} htmlFor={`hide_checkbox_${hideCheckboxId}`}>Hide Treatment Name & Price from Patient</label>
              </Checkbox>
              </Form.Item>
          </ConfigProvider>
        </span>
      </Row>
      {!hideProductOrService && (
        <Row>
            <Form.Item className="parent-form-item">
              <Form.Item
                label="Type"
                name="productOrServiceType"
                className="inline-form-left"
                rules={[
                  { required: true, message: "Product type is required" },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  marginRight: "0.4rem",

                  textAlign: "left",
                }}
              >
                <Select
                  size="large"
                  disabled={mode === "view"}
                  defaultValue={productOrServiceTypeOptions[0].product_type}
                  style={{ width: "100%" }}
                  placeholder="custom dropdown render"
                  dropdownRender={(menu) => <div>{menu}</div>}
                >
                  {productOrServiceTypeOptions.map((item, index) => (
                    <Option key={JSON.stringify(item)}>
                      {item.product_type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Name"
                name="productOrServiceName"
                rules={[
                  { required: true, message: "Product name is required" },
                ]}
                className="inline-form-right"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  margin: "0 0.5rem 0  2rem ",

                  textAlign: "left",
                }}
              >
                <Select
                  size="large"
                  disabled={mode === "view"}
                  style={{ width: "100%" }}
                  placeholder="custom dropdown render"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          size="large"
                          style={{ flex: "auto" }}
                          value={newProductOrServiceNameOption}
                          onChange={onProductOrServiceNameOptionChange}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                            color:primaryColor
                          }}
                          onClick={addProductOrServiceNameOption}
                        >
                          <PlusOutlined /> Add Name
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {productOrServiceNameOptions[
                    selectedProductOrServiceType
                  ].map((item) => (
                    <Option key={JSON.stringify(item)}>
                      {item.product_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            {!hideFromPatient && <><div style={{display:"flex", alignItems:"center"}}>
              <Form.Item
                label=""
                name="free"
                valuePropName="checked"
              >
              <Checkbox  disabled={mode === "view"} onChange={onDisablePrice}>
                <label>Free</label>
              </Checkbox>
              
              </Form.Item>

              {/* <Form.Item
                label=""
                name="hidePatientPrice"
                valuePropName="checked"
              >
              <Checkbox  disabled={mode === "view"} onChange={onDisablePrice}>
                <label>Hide price from patient</label>
              </Checkbox>
              
              </Form.Item> */}

              </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0 2.5rem",
                marginRight: "10px",
              }}
            >
              <div className="price-row">
              
                <Form.Item
                  label="Price"
                  name="price"
                  rules={[
                    { required: true, message: "Price is required" },

                    () => ({
                      validator(_, value) {
                        const convertedValue = Number(value);
                        if (
                          !value.length ||
                          (!isNaN(convertedValue) && value.trim().length !== 0)
                        ) {
                          return Promise.resolve();
                        }

                        return Promise.reject("Invalid Price");
                      },
                    }),
                  ]}
                  style={{
                    width: "100%",
                    minWidth:"150px",
                    // margin: "0 0 2rem 0",
                    textAlign: "left",
                  }}
                >
                  <Input
                    size="large"
                    disabled={mode === "view" || disablePrice}
                    placeholder="Price"
                    addonAfter={currencySelector}
                    // onChange={(event)=>onPriceChange(event.target.value,"price")}
                  />
                </Form.Item>
                <div className="col-two">
                  <Form.Item label="Discount" name="discount">
                    <Input
                      size="large"
                      type="number"
                      disabled={mode === "view" || disablePrice}
                      placeholder=""
                      // onChange={(event)=>onPriceChange(event.target.value,"discount")}
                      addonAfter={<b>%</b>}
                    />
                  </Form.Item>
                  <Form.Item label="" name="discountAmount">
                    <Input
                      size="large"
                      type="number"
                      disabled={mode === "view" || disablePrice}
                      placeholder="Amount"
                      // onChange={(event)=>onPriceChange(event.target.value,"discount")}
                      addonAfter={<b>{ initialFormValues  ? JSON.parse(initialFormValues.currency).currency_name : "EUR"}</b>}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Total"
                  name="total"
                  // style={{
                  //   display: "inline-block",
                  //   width: "100%",
                  //   marginRight: "0.4rem",
                  //   textAlign: "left",
                  // }}
                >
                  <Input
                    size="large"
                    disabled={true}
                    placeholder="Total"
                    // addonAfter={<b>EUR</b>}
                  />
                </Form.Item>
              </div>

              <Form.Item
                rules={[{ required: true }]}
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
                label="Payment Types"
                name="paymentIds"
              >
                <Select
                  disabled={mode === "view" || disablePrice}
                  size="large"
                  style={{ width: "100%" }}
                  mode="multiple"
                >
                  {paymentList?.map((el) => {
                    return <Option value={el.id}>{el.buttonText}</Option>;
                  })}
                </Select>
              </Form.Item>
            </div>
      

        <div style={{width:"100%"}}>
        <Form.Item
              label={
                <span>
                  Description&nbsp;
                  <InfoIcon info={"Description of the product / service"} />
                </span>
              }
              name="description"
              style={{
                marginRight: "0.5rem",
                marginTop: "2rem",
              }}
              rules={[
                {
                  required: true,
                  message: "Please add a description!",
                },
                () => ({
                  validator(_, value) {
                    if (value.trim().length !== 0 || !value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Description should be valid!");
                  },
                }),
              ]}
            >
              <Input
                size="large"
                disabled={mode === "view"}
                placeholder="Description"
              />
            </Form.Item>
        </div>
            </>}
        </Row>
      )}
    </Form>
    </div>
  );
};

export default ProductsAndServicesFormComponent;
