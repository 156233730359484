import { useState, useRef } from "react";
import { Modal, Button } from "antd";
import PaymentSettingsContent from "./PaymentSettingsContent";
import { useSelector } from "react-redux";

const PaymentSettings = ({ setMultiSelectPaymentList, getServicesAndResources }) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const clinicId = useSelector((state) => state.clinic.clinicId);
  const paymentContentRef = useRef(null);
  const openPaymentModal = () => {
    setShowPaymentModal(true);
  };

  const onCancel = () => {
    if (paymentContentRef.current.paymentList.length) {

      getServicesAndResources && getServicesAndResources();
      setMultiSelectPaymentList(
        paymentContentRef.current.paymentList.filter((el) => el.isActive)
      );
    }

    setShowPaymentModal(false);
  };
  return (
    <>
      <Button shape="round" onClick={openPaymentModal} type="primary">
        Payment Settings
      </Button>

      {showPaymentModal && (
        <Modal
          width={800}
          footer={null}
          title="Payment Settings"
          onCancel={onCancel}
          open={showPaymentModal}
        >
          <PaymentSettingsContent
            ref={paymentContentRef}
            setMultiSelectPaymentList={setMultiSelectPaymentList}
            clinicId={clinicId}
          />
        </Modal>
      )}
    </>
  );
};

export default PaymentSettings;
