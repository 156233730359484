import React from "react";
import { withRouter } from "react-router-dom";

import { Menu, Button } from "antd";

import "../../../assets/styles/components/patientJourneyMenubarComponent.scss";
import { companyType } from "colors-config";

class PatientOrdersMenubarComponent extends React.Component {
  state = {
    current: companyType !== 5 ? "0" : "1",
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const redirect = searchParams.get("redirect");
    if (redirect == "lab") {
      this.setState({ current: "1" });
    }
  }

  handleClick = (e) => {
    if (e.key) {
      this.setState({ current: e.key });
      this.props.setCurrentSelection(+e.key);
    }
  };

  render() {
    const { current } = this.state;
    return (
      <Menu
        className="patient-journey-menubar"
        onClick={this.handleClick}
        selectedKeys={[current]}
        mode="horizontal"
      >
        {companyType !== 5 && (
          <Menu.Item
            className="patient-journey-menubar-items rest-order"
            key={0}
          >
            Patient
          </Menu.Item>
        )}
        <Menu.Item className="patient-journey-menubar-items " key={1}>
          Labs
        </Menu.Item>
        <Menu.Item disabled className="patient-journey-menubar-items " key={3}>
          {this.state.current === "1" && (
            <Button
              disabled={this.props.selectedCaseId !== this.props.activeCaseId}
              onClick={(e) => {
                const {
                  paymentStatus,
                  setIsModalUpdateOrderVisible,
                  patientOrders,
                } = this.props;
                if (
                  patientOrders.length &&
                  (!paymentStatus ||
                    paymentStatus == "-" ||
                    paymentStatus == "Awaiting Payment")
                ) {
                  setIsModalUpdateOrderVisible(true);
                } else {
                  this.props.setShowLabOrderModal(true);
                }
              }}
              style={{
                width: "11rem",
                float: "right",
                marginRight: "1rem",
                marginTop: "0.5rem  ",
              }}
            >
              Place Order
            </Button>
          )}
          {this.state.current === "0" && companyType !== 5 && (
            <Button
              disabled={this.props.selectedCaseId !== this.props.activeCaseId}
              onClick={(e) => {
                this.props.setShowPatientOrderModal(true);
              }}
              style={{
                width: "12rem",
                float: "right",
                marginRight: "1rem",
                marginTop: "0.4rem  ",
              }}
            >
              Request Order to Patient
            </Button>
          )}
        </Menu.Item>
      </Menu>
    );
  }
}

export default withRouter(PatientOrdersMenubarComponent);
