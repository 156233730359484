import { useState, useEffect } from "react";
import { Radio, Row, Col, Typography, Spin, message, Select } from "antd";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { dentistClinicApiTasks } from "../../models/dentistClinic";
import { newAppointmentCreated } from "../../services/redux/actions/clinicActions";
import AppointmentCalendarComponent from "../../components/private/scheduling/appointmentCalendarComponent";
import { createDateTime, pickStatusColor } from "../../utilities/commonMethod";
import "../../assets/styles/routes/appointmentScheduling.scss";
import { setCurrentVideoCallAppointment } from "../../services/redux/actions/videoCallActions";
import {
  getAllApproveAppointments,
  getAllCancelledAppointments,
  getAllPendingAppointments,
  reschedulePatientAppointment,
} from "api/clinic";
// import { ApiOutlined } from "@ant-design/icons";
const { Title } = Typography;
const { Option } = Select;

function AppointmentScheduling(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { clinic, computedMatch } = props;
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [approvedAppointments, setApprovedAppointments] = useState({});
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [cancelledAppointments, setCancelledAppointments] = useState({});
  const [clinicians, setClinicians] = useState([]);
  const [shouldLoadCalendar, setShouldLoadCalendar] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedClinicianId, setSelectedClinicianId] = useState("");
  const [selectedAppointmentType, setSelectedAppointmentType] = useState("");
  const history = useHistory();
  // const [status] = useState(computedMatch.params.status);
  const { status } = useParams();
  useEffect(() => {
    if (status) {
      setSelectedAppointmentType(status.toUpperCase());
    }
  }, [status]);

  useEffect(() => {
    if (clinic.clinicId) _getAppointmentDetails(false, true);
  }, [clinic.clinicId]);

  useEffect(() => {
    if (clinic.newAppointment) {
      _getAppointmentDetails(false, true);
      dispatch(newAppointmentCreated(false));
    }
  }, [clinic.newAppointment]);

  const parseAppointments = (appointments) => {
    const approvedAppointmentsObject = {};
    appointments.forEach((appointment) => {
      if (approvedAppointmentsObject[appointment.clinician_id]) {
        approvedAppointmentsObject[appointment.clinician_id] = [
          ...approvedAppointmentsObject[appointment.clinician_id],
          {
            ...appointment,
            title: `${appointment.patient_first_name} ${appointment.patient_last_name}`,
            start: createDateTime(
              appointment.appointment_date,
              appointment.appointment_start_time
            ),
            end: createDateTime(
              appointment.appointment_date,
              appointment.appointment_end_time
            ),
            bgColor: pickStatusColor(appointment.appointment_status, "slot"),
            resource: appointment,
          },
        ];
      } else {
        approvedAppointmentsObject[appointment.clinician_id] = [appointment];
      }
    });

    return approvedAppointmentsObject;
  };

  const _getAppointmentDetails = async (
    isStatusDifferent = false,
    isFirstLoad,
    appointmentType,
    clinician_id
  ) => {
    setShouldLoadCalendar(false);
    setApprovedAppointments({});
    setCancelledAppointments({});

    try {
      const inputAppointmentData = {
        clinicId: clinic.clinicId,
      };
      const approvedRes = await getAllApproveAppointments(inputAppointmentData);

      // await dentistClinicApiTasks.getAll(
      //   "clinic/appointment/get/approved",
      //   {
      //     clinicId: clinic.clinicId,
      //   }
      // );

      const approvedAppointmentsObject = parseAppointments(
        approvedRes.body.data
      );

      setApprovedAppointments(approvedAppointmentsObject);

      const pendingRes = await getAllPendingAppointments(inputAppointmentData);
      //  await dentistClinicApiTasks.getAll(
      //   "clinic/appointment/get/pending",
      //   {
      //     clinicId: clinic.clinicId,
      //   }
      // );

      setPendingAppointments(pendingRes.body.data);

      const cancelledRes = await getAllCancelledAppointments(
        inputAppointmentData
      );
      // await dentistClinicApiTasks.getAll(
      //   "clinic/appointment/get/cancelled",
      //   {
      //     clinicId: clinic.clinicId,
      //   }
      // );

      const cancelledAppointmentsObject = parseAppointments(
        cancelledRes.body.data
      );

      setCancelledAppointments(cancelledAppointmentsObject);

      const clinicianRes = await dentistClinicApiTasks.getAll(
        "clinic/clinician/getByClinic",
        {
          clinicId: clinic.clinicId,
        }
      );

      const clinicians = clinicianRes.body.data;

      setClinicians(clinicians);

      if (!isStatusDifferent && !isFirstLoad) {
        setSelectedAppointments(pendingRes.body.data);
      } else {
        if (selectedAppointmentType === "APPROVED") {
          setSelectedAppointments(
            approvedAppointmentsObject[selectedClinicianId]
              ? approvedAppointmentsObject[selectedClinicianId]
              : []
          );
        }
      }

      if (isFirstLoad) {
        const defaultClinician = selectDefaultClinician(clinicians);
        const statusFromUrl = status.toUpperCase();
        if (statusFromUrl === "APPROVED") {
          setSelectedAppointments(
            approvedAppointmentsObject[defaultClinician]
              ? approvedAppointmentsObject[defaultClinician]
              : []
          );
        } else if (statusFromUrl === "CANCELLED") {
          setSelectedAppointments(
            cancelledAppointmentsObject[defaultClinician]
              ? cancelledAppointmentsObject[defaultClinician]
              : []
          );
        } else {
          setSelectedAppointments(pendingRes.body.data);
        }
      }

      if (appointmentType && clinician_id) {
        if (
          appointmentType == "confirm" ||
          appointmentType == "reschedule" ||
          appointmentType == "changeClinician"
        ) {
          const appointmentTypeValue = "approved";
          setSelectedAppointmentType(appointmentTypeValue.toUpperCase());
          history.push(`/clinic/scheduling/${appointmentTypeValue}`);
        }
        setSelectedAppointments(
          approvedAppointmentsObject[clinician_id]
            ? approvedAppointmentsObject[clinician_id]
            : []
        );
        setSelectedClinicianId(clinician_id);
      }

      setShouldLoadCalendar(true);
    } catch (error) {
      message.error("Failed to get appointments");
      setShouldLoadCalendar(true);
      console.log(error);
    }
  };

  const selectDefaultClinician = (clinicians) => {
    let defaultClinician;
    clinicians.map((clinician) => {
      if (clinician.clinician_id === clinician.default_clinician_id) {
        defaultClinician = clinician.clinician_id;
      }
    });
    if (queryParams && queryParams.get("clinician_id")) {
      defaultClinician = queryParams.get("clinician_id");
    }
    setSelectedClinicianId(defaultClinician);

    return defaultClinician;
  };

  const confirmAppointment = async (appointment) => {
    const hide = message.loading("Confirming Appointment", 0);
    const model = {
      logged_in_user: "Clinic",
      user_action: "Confirm",
      appointment_id: appointment.appointment_id,
      clinician_id: appointment.clinician_id,
    };
    try {
      const res = await dentistClinicApiTasks.post(
        "appointment/cancelConfirmRescheduleAppointment",
        model
      );
      setTimeout(hide, 0);
      message.success(res.body?.msg.msg);
      setDrawerVisible(false);
      _getAppointmentDetails(false, false, "confirm", appointment.clinician_id);
    } catch (error) {
      setTimeout(hide, 0);
      if (error.response?.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Failed to confirm appointment");
      }

      console.log(error);
    }
  };

  const cancelAppointment = async (appointment) => {
    const hide = message.loading("Cancelling Appointment", 0);

    const model = {
      logged_in_user: "Clinic",
      user_action: "Cancel",
      appointment_id: appointment.appointment_id,
      clinician_id: appointment.clinician_id,
    };
    try {
      const res = await dentistClinicApiTasks.post(
        "appointment/cancelConfirmRescheduleAppointment",
        model
      );

      setTimeout(hide, 0);
      message.success("Appointment cancelled");
      setDrawerVisible(false);
      _getAppointmentDetails();
    } catch (error) {
      setTimeout(hide, 0);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Failed to cancel appointment");
      }

      console.log(error);
    }
  };

  const confirmReschedule = async (slot, date, appointment) => {
    const hide = message.loading("Rescheduling Appointment", 0);

    const model = {
      logged_in_user: "Clinic",
      user_action: "Reschedule",
      appointment_id: appointment.appointment_id,
      clinician_id: appointment.clinician_id,
      appointment_date: date,
      appointment_start_time: slot.appointmentStartTime,
      appointment_end_time: slot.appointmentEndTime,
      appointment_slot_id: slot.appointmentSlotId,
    };
    try {
      const res = await reschedulePatientAppointment(model);

      setTimeout(hide, 0);
      message.success(res.body.msg);
      setDrawerVisible(false);
      _getAppointmentDetails(
        false,
        false,
        "reschedule",
        appointment.clinician_id
      );
      // reRoute();
    } catch (error) {
      setTimeout(hide, 0);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Failed to reschedule appointment");
      }

      console.log(error);
    }
  };

  const reRoute = () => {
    // history.push("/clinic/scheduling/approved");
    // window.location.reload();
    history.go(0);
  };

  const confirmAppointmentStatus = async (appointment, status) => {
    const hide = message.loading("Updating Appointment Status", 0);

    const model = {
      appointment_id: appointment.appointment_id,
      appointment_status: status,
    };
    try {
      const res = await dentistClinicApiTasks.put(
        "clinic/appointment/updateStatus",
        model
      );

      setTimeout(hide, 0);
      message.success(res.body);
      setDrawerVisible(false);
      _getAppointmentDetails(true);
    } catch (error) {
      setTimeout(hide, 0);
      message.error("Failed to update appointment status");

      console.log(error);
    }
  };

  const updateClinician = async (appointment, clinicianId) => {
    const hide = message.loading("Updating Clinician", 0);
    const model = {
      clinician_id: clinicianId,
      appointment_id: appointment.appointment_id,
    };
    try {
      const res = await dentistClinicApiTasks.post(
        "clinic/appointment/approved/updateClinician",
        model
      );

      setTimeout(hide, 0);
      message.success(res.body);
      setDrawerVisible(false);

      _getAppointmentDetails(false, false, "changeClinician", clinicianId);
    } catch (error) {
      setTimeout(hide, 0);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Failed to update clinician");
      }

      console.log(error);
    }
  };

  const appointmentStatusChanged = (e) => {
    const val = e.target.value;
    setSelectedAppointmentType(val);
    history.push(`/clinic/scheduling/${val.toLowerCase()}`);

    if (val === "PENDING") {
      setSelectedAppointments(pendingAppointments);
    } else if (val === "APPROVED") {
      setSelectedAppointments(
        approvedAppointments[selectedClinicianId]
          ? approvedAppointments[selectedClinicianId]
          : []
      );
    } else if (val === "CANCELLED") {
      setSelectedAppointments(
        cancelledAppointments[selectedClinicianId]
          ? cancelledAppointments[selectedClinicianId]
          : []
      );
    }
  };

  const handleSelectChange = (clinicianId) => {
    setSelectedClinicianId(clinicianId);
    if (selectedAppointmentType === "APPROVED") {
      setSelectedAppointments(
        approvedAppointments[clinicianId]
          ? approvedAppointments[clinicianId]
          : []
      );
    } else if (selectedAppointmentType === "CANCELLED") {
      setSelectedAppointments(
        cancelledAppointments[clinicianId]
          ? cancelledAppointments[clinicianId]
          : []
      );
    }
  };
  const checkStatus = () => {
    if (status === "pending") {
      return "PENDING";
    } else if (status === "approved") {
      return "APPROVED";
    } else if (status === "cancelled") {
      return "CANCELLED";
    }
  };
  const startVideoCall = (appointment) => {
    history.push("/videoChatRoom", {
      refFrom: "appointments",
      appointment,
    });
    props.setCurrentVideoCallAppointment(appointment);
  };

  return (
    <div>
      <Row justify="space-between" style={{ marginLeft: "1rem" }}>
        <Title className="appointment-scheduling-heading" level={2}>
          Appointments
        </Title>
      </Row>
      <Row
        justify="space-between"
        style={{
          height: "2.5rem",
          // width: "calc(95% - 1rem) ",
          marginLeft: "1rem",
          // backgroundColor: "red",
        }}
      >
        <Col>
          <Radio.Group
            // defaultValue={checkStatus()}
            value={selectedAppointmentType}
            buttonStyle="solid"
            style={{ height: "100%" }}
            onChange={appointmentStatusChanged}
          >
            <Radio.Button className="status-radio-buttons" value="PENDING">
              Pending
            </Radio.Button>
            <Radio.Button className="status-radio-buttons" value="APPROVED">
              Approved
            </Radio.Button>
            <Radio.Button className="status-radio-buttons" value="CANCELLED">
              Cancelled
            </Radio.Button>
          </Radio.Group>
        </Col>

        <Col>
          {clinicians.length > 0 &&
            selectedAppointmentType &&
            selectedAppointmentType !== "PENDING" &&
            selectedClinicianId && (
              <Select
                size="large"
                style={{ width: 150 }}
                onChange={handleSelectChange}
                value={selectedClinicianId}
              >
                {clinicians.map((clinician) => {
                  return (
                    <Option
                      key={clinician.clinician_id}
                      value={clinician.clinician_id}
                    >
                      {clinician.clinician_name}
                    </Option>
                  );
                })}
              </Select>
            )}
        </Col>
      </Row>
      <div style={{ marginLeft: "1rem" }}>
        <Spin spinning={!shouldLoadCalendar}>
          <AppointmentCalendarComponent
            startVideoCall={startVideoCall}
            appointments={selectedAppointments}
            clinicians={clinicians}
            confirmAppointment={confirmAppointment}
            cancelAppointment={cancelAppointment}
            drawerVisible={drawerVisible}
            setDrawerVisible={setDrawerVisible}
            defaultClinicianId={selectedClinicianId}
            confirmAppointmentReschedule={confirmReschedule}
            confirmAppointmentStatus={confirmAppointmentStatus}
            updateClinician={updateClinician}
          ></AppointmentCalendarComponent>
        </Spin>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps, {
  setCurrentVideoCallAppointment,
})(AppointmentScheduling);
