import { useState } from "react";
import { Row, Col, Modal, Divider, Radio } from "antd";
import { useRef } from "react";
import TeethType from "components/shared/TeethType/TeethType";
import IprSessionList from "../IprSessionList/IprSessionList";
import IPRInfo from "../IPRInfo/IPRInfo";
import AttachmentsInfo from "../IPRInfo/AttachmentsInfo";
import FileList from "./FileList/FileList";
import ReactToPrint from "react-to-print";
import { primaryColor } from "colors-config";
import AttachmentInfo from "../AttachmentInfo";

import AttachmentRow from "../AttachmentRow";

import { companyType } from "colors-config";
import { checkTextEditorContent } from "utilities/IPR";
import { isSafari } from "utilities/commonMethod";

const IprModal = ({
  alignersLabel,
  chartValue,
  handleStructureChange,
  treatment,
  openIprGuideModal,
  setOpenIprGuideModal,
  lowerAligners,
  upperAligners,
  onAlignerLabelClicked,
  upperAttachments,
  lowerAttachments,
  attachmentsLabel,
  onAttachmentsLabelClicked,
  treatmentPlansState,
}) => {
  const [value, setValue] = useState("ipr");
  const modalRef = useRef();
  // console.log("treatment", treatment);
  const handleCancel = () => {
    setOpenIprGuideModal(false);
  };

  const detailOptions = [
    {
      label: "IPR",
      value: "ipr",
    },
    {
      label: "Attachment",
      value: "attachment",
    },
  ];

  const onChange4 = ({ target: { value } }) => {
    setValue(value);
  };

  const showAdditionalDetails = (additionalDetails = "") => {
    const hasContent = checkTextEditorContent(additionalDetails);

    return (
      <div>
        <Row>
          <p className="patient-question">Additional Details</p>
        </Row>
        <Row>
          <div
            className={`patient-response ${hasContent ? "detail-container" : ""}`}
            style={{ fontWeight: "400", textAlign: "justify" }}
          >
            {hasContent ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: additionalDetails,
                }}
              />
            ) : (
              "--"
            )}
          </div>
        </Row>
      </div>
    );
  };
  const getPageStyles = () => {
    // check of safari

    return `
      @media print {
      
         @page {
           margin: 0 !important;
           padding: 0 !important;
           size: A4;
         }
       
      .ipr-detail-container {
    .teethChart-container .teethRow-container .teethRow-one svg {
      width: 40px !important;
    }
  }
      }
   .aligner-row{
           margin-left: 28px;
           }
     .ipr-session-list-labels{
        margin-left:auto;}
        ${isSafari() ? 

            `
              html, body {
       margin: 0 !important;
           padding: 0 !important;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
    
  `:''
          }
    `;
  };

  return (
    <Modal open={true} title="Preview" onCancel={handleCancel} footer={null} width={850}>
      <style>{getPageStyles()}</style>
      <div
        className="ipr-modal-container aligner-component"
        // style={{ padding: "0 20px" }}
        ref={modalRef}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Radio.Group
            className="heading-radio"
            options={detailOptions}
            onChange={onChange4}
            value={value}
            optionType="button"
            buttonStyle="solid"
          />
          {/* <Row align="end" style={{ paddingBottom: "10px" }}>
            <ReactToPrint
              trigger={() => {
                return (
                  <div
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "flex-end",
                  //   marginRight: "10px",
                  //   marginBottom: "10px",
                  //   marginTop: "-20px",
                  // }}
                  >
                    <span
                      style={{
                        color: primaryColor,
                        cursor: "pointer",
                      }}
                    >
                      Download
                    </span>
                  </div>
                );
              }}
              content={() => modalRef.current}
            />
          </Row> */}
        </div>

        {companyType === 5 && (
          <div>
            <Row>
              <p className="patient-question">Phasing</p>
            </Row>
            <Row>
              <p
                className="patient-response"
                style={{
                  fontWeight: "400",
                  marginBottom: 0,
                  textAlign: "justify",
                }}
              >
                {treatment?.phasing.trim() ? treatment.phasing : "--"}
              </p>
            </Row>
          </div>
        )}

        {showAdditionalDetails(treatment?.additionalDetails)}

        {value == "ipr" ? (
          <>
            <div>
              <p className="patient-question" style={{ textAlign: "left" }}>
                Treatment Guide PDF
              </p>
              <FileList
                iprFile={treatment?.iprDocument}
                // iprFile={treatmentPlansState?.body?.treatmentFile}
              />
            </div>
            <Row align="top">
              <Col lg={10}>
                <div>
                  <Row>
                    <p className="patient-question" style={{ marginTop: "0px" }}>
                      No. of IPR sessions
                    </p>
                  </Row>
                  <Row>
                    <p className="patient-response">{alignersLabel.length}</p>
                  </Row>
                </div>
              </Col>
              <Col lg={14} className='ipr-session-list-labels'>
                <IprSessionList
                  alignersLabel={alignersLabel}
                  onAlignerLabelClicked={onAlignerLabelClicked}
                />
              </Col>
            </Row>
            <div className="chart-header">
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <h1>Treatment Guide</h1> */}
              </div>
              <TeethType teethTypeName={chartValue} onTeethTypeChange={handleStructureChange} />
            </div>
            <Divider />
            <IPRInfo
              lowerAligners={lowerAligners}
              upperAligners={upperAligners}
              chartValue={chartValue}
              upperAttachments={upperAttachments}
              lowerAttachments={lowerAttachments}
            />
          </>
        ) : (
          <>
            <div className="chart-header">
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <h1>Treatment Guide</h1> */}
              </div>
              <TeethType teethTypeName={chartValue} onTeethTypeChange={handleStructureChange} />
            </div>
            <Divider />

            <AttachmentInfo
              alignersLabel={attachmentsLabel}
              onAlignerLabelClicked={onAttachmentsLabelClicked}
              upperAttachments={upperAttachments}
              lowerAttachments={lowerAttachments}
            />
            <AttachmentsInfo
              lowerAligners={lowerAligners}
              upperAligners={upperAligners}
              chartValue={chartValue}
              upperAttachments={upperAttachments}
              lowerAttachments={lowerAttachments}
            />

            <AttachmentRow
              alignersLabel={attachmentsLabel}
              onAlignerLabelClicked={onAttachmentsLabelClicked}
              upperAttachments={upperAttachments}
              lowerAttachments={lowerAttachments}
              chartValue={chartValue}
            />
          </>
        )}

        {/* <div
          style={{ padding: "0px" }}
          ref={componentRef}
          className="aligner-component"
        >
          <Row align="top">
            <Col lg={12}>
              <div>
                <Row>
                  <p className="patient-question">No. of IPR sessions</p>
                </Row>
                <Row>
                  <p className="patient-response">{alignersLabel.length}</p>
                </Row>
              </div>
            </Col>
            <Col lg={12}>
              <IprSessionList
                alignersLabel={alignersLabel}
                onAlignerLabelClicked={onAlignerLabelClicked}
              />
            </Col>
          </Row>

          <Row align="middle" justify="end" style={{ position: "relative" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TeethType
                teethTypeName={chartValue}
                onTeethTypeChange={handleStructureChange}
              />
            </div>
          </Row>
          <IPRInfo
            lowerAligners={lowerAligners}
            upperAligners={upperAligners}
            chartValue={chartValue}
            upperAttachments={upperAttachments}
            lowerAttachments={lowerAttachments}
          />
          <Row align="top">
            <Col lg={12}>
              <div>
                <Row>
                  <p className="patient-question">Additional Detail</p>
                </Row>
                <Row>
                  <p className="patient-response" style={{ fontWeight: "500" }}>
                    {treatment?.additionalDetails
                      ? treatment?.additionalDetails
                      : "--"}
                  </p>
                </Row>
              </div>
            </Col>
            <Col lg={12}>
              <p className="patient-question">IPR Attachments</p>

              <FileList iprFile={treatmentPlansState?.body?.treatmentFile} />
            </Col>
          </Row>

          <AttachmentInfo
            alignersLabel={attachmentsLabel}
            onAlignerLabelClicked={onAttachmentsLabelClicked}
            upperAttachments={upperAttachments}
            lowerAttachments={lowerAttachments}
          />
        </div> */}
      </div>
    </Modal>
  );
};

export default IprModal;
