const PrescriptionInstructions = ({prescriptionPDFObj}) => {
  return (
    <div className="prescriptionPDF-card">
    <h2>Treatment Instructions</h2>
    <iv className="prescriptionPDF-ins">
      <div>
        <div className="border-right">
          <label className="treatment-label">Overjet</label>
          <p className="treatment-info">{prescriptionPDFObj.overjet}</p>
        </div>
      </div>
      <div>
        <div className="border-right">
          <label className="treatment-label">Overbite</label>
          <p className="treatment-info">{prescriptionPDFObj.overbite}</p>
        </div>
      </div>

      <div>
        <div className="border-right">
          <label className="treatment-label">Openbit</label>
          <p className="treatment-info">{prescriptionPDFObj.openbite} {prescriptionPDFObj.openBiteValue !== "--" ?`(${prescriptionPDFObj.openBiteValue})`:""}</p>
        </div>
      </div>
      <div>
        <div className="border-right">
          <label className="treatment-label">Crossbite</label>
          <p className="treatment-info">{prescriptionPDFObj.crossbite} {prescriptionPDFObj.crossBiteValue  !== "--" ?`(${prescriptionPDFObj.crossBiteValue})`:""} </p>
        </div>
      </div>
      <div>
        <div >
          <label className="treatment-label">Midline</label>
          <p className="treatment-info">{prescriptionPDFObj.midline}</p>
        </div>
      </div>
    </iv>
  </div>
  )
}

export default PrescriptionInstructions