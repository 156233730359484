import { Input, Row, Col, Select } from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const AppointmentForm = ({
  selectedPatient,
  clinicUsers,
  clinicAppointmentTypes,
  clinicAppointmentNames,
  dropDown,
  onSelect
}) => {
  const { clinicianId, appointmentNameId, appointmentTypeId } = dropDown;

  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (clinicUsers?.length) {
      const tempUsers = clinicUsers.map((cUser) => {
        return {
          value: cUser.clinician_id,
          label: cUser.clinician_name,
        };
      });
      onSelect(tempUsers[0].value, "clinicianId");
      setUsers(tempUsers);
    }
  }, [clinicUsers]);



  return (
    <div className="patient-disabled-results">
      <Row gutter={[20, 0]}>
        <Col md={5}>
          <Input
          size='large'
            value={selectedPatient?.patient_first_name || ""}
            disabled
            placeholder="First name"
          />

          <Input
          size='large'
            value={selectedPatient?.patient_last_name || ""}
            disabled
            placeholder="Last name"
          />
        </Col>
        <Col md={9}>
          <Input
          size='large'
            value={selectedPatient?.patient_email || ""}
            disabled
            placeholder="Email"
          />

          <Input
          size='large'
            value={selectedPatient?.patient_phone_number || ""}
            disabled
            placeholder="Phone"
          />
        </Col>

        <Col md={10}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Select
              size="small"
              options={clinicAppointmentNames}
              style={{ width: "100%" }}
              value={appointmentNameId}
              onChange={(value) => onSelect(value, "appointmentNameId")}
            />
            <Select
              size="small"
              style={{ marginTop: "10px", width: "100%" }}
              options={clinicAppointmentTypes}
              value={appointmentTypeId}
              onChange={(value) => onSelect(value, "appointmentTypeId")}
            />
            <Select
              size="small"
              isRequired
              style={{ marginTop: "10px", width: "100%" }}
              options={users}
              value={clinicianId}
              onChange={(value) => onSelect(value, "clinicianId")}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentForm;

AppointmentForm.propTypes = {
  selectedPatient: PropTypes.shape({}).isRequired,
};
