import { useState, useEffect } from "react"
import { Spin } from "antd";

import "./prescriptionPDF.scss";
import PrescriptionHeader from "./Components/PrescriptionHeader";
import PatientInfo from "./Components/PatientInfo";
import PrescriptionTreatmentTypes from "./Components/PrescriptionTreatmentTypes";
import PrescriptionAttachments from "./Components/PrescriptionAttachments";
import PrescriptionAlignment from "./Components/PrescriptionAlignment";
import PrescriptionDuration from "./Components/PrescriptionDuration";
import PrescriptionOcclusion from "./Components/PrescriptionOcclusion";
import PrescriptionPeri from "./Components/PrescriptionPeri";
import PrescriptionToothChart from "./Components/PrescriptionToothChart";
import { prescriptionPDFDataMapper } from "utilities/prescriptionPDF.js";
import PrescriptionInstructions from "./Components/PrescriptionInstructions";
import { getPreliminaryImagesApi } from "api/prescription";
import PatientImages from "./Components/PatientImages";
import { companyType } from "colors-config";
import SmilePrescriptionTreatmentTypes from "./Components/SmilePrescriptionTreatmentTypes";
import { renderTreatmentTypes } from "utilities/commonMethod";
const PrescriptionPDF = ({ prescriptionForm, teethChartData, chartValue, patientInfo, selectedVersion, patientId, treatmentId, clinic, treatmentOptions }) => {
  // console.log("clinicclinic", clinic)
  const [prescriptionPDFObj, setPrescriptionObj] = useState({})
  const [loading, setLoading] = useState(false)
  const [preliminaryImages, setPreliminaryImages] = useState([])

  useEffect(() => {
    if (prescriptionForm) {
      // console.log("prescriptionFormprescriptionFormprescriptionForm", prescriptionForm)
      const prescriptionPDFData = prescriptionPDFDataMapper(prescriptionForm)
      // console.log("prescriptionPDFData", prescriptionPDFData)
      setPrescriptionObj(prescriptionPDFData)
    }

  }, [prescriptionForm])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const request = {
          patientId,
          patientStage: "preliminary",
          alignerId: 100,
          labTreatmentId: treatmentId ? treatmentId : "",

        }
        const { data } = await getPreliminaryImagesApi(request)
        setPreliminaryImages(data?.body?.results?.patient_onboarding_images)
      } catch (error) {

      }
      finally {
        setLoading(false)
      }
    })()

  }, [treatmentId])

  return (
    <div className="prescriptionPDF-container">
      <Spin spinning={loading}>
        <PrescriptionHeader prescriptionPDFObj={prescriptionPDFObj} selectedVersion={selectedVersion} />
        <PatientInfo patientInfo={patientInfo} clinic={clinic} />
        <PatientImages preliminaryImages={preliminaryImages} />
        {
          renderTreatmentTypes(companyType, prescriptionPDFObj?.createdAt).show ? <SmilePrescriptionTreatmentTypes prescriptionPDFObj={prescriptionPDFObj} treatmentOptions={treatmentOptions} /> : <PrescriptionTreatmentTypes prescriptionPDFObj={prescriptionPDFObj} />
        }
        <PrescriptionInstructions prescriptionPDFObj={prescriptionPDFObj} />
        <PrescriptionAttachments prescriptionPDFObj={prescriptionPDFObj} />
        <PrescriptionAlignment prescriptionPDFObj={prescriptionPDFObj} />
        <PrescriptionDuration prescriptionPDFObj={prescriptionPDFObj} />
        <PrescriptionToothChart toothStatuses={teethChartData} chartValue={chartValue} />
        <PrescriptionOcclusion prescriptionPDFObj={prescriptionPDFObj} />
        <PrescriptionPeri prescriptionPDFObj={prescriptionPDFObj} />
      </Spin>

    </div>
  );
};

export default PrescriptionPDF;
