import { useState, useEffect, useRef } from "react";
import { logout } from "services/redux/actions/authActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { checkLastActiveStatus } from "utilities/commonMethod";

const timeout = 60 * 60 * 1000; //60 mins timeout

const useInactivityLogout = () => {
  const [isInactive, setIsInactive] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const inactivityTimerRef = useRef(null);

  const resetInactivityTimer = () => {
    localStorage.setItem("lastActiveTime",dayjs().format("YYYY-MM-DD HH:mm:ss"));
    clearTimeout(inactivityTimerRef.current);
    inactivityTimerRef.current = setTimeout(handleInactive, timeout);
  };

  const handleActivity = () => {
    if (isInactive) {
      setIsInactive(false); // User is active again
    }
    resetInactivityTimer();
  };

  const handleInactive = () => {
    const token = localStorage.getItem("idToken");
    if (!token) {
      return setIsInactive(false);
    }
    console.log("checkLastActiveStatus",checkLastActiveStatus());
    if(checkLastActiveStatus()){
      setIsInactive(true);
      // Call the logout callback function when inactive
      dispatch(logout());
      history.push("/login");
    }
    else{
      return setIsInactive(false);
    }
   
  };

  useEffect(() => {
    resetInactivityTimer();

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("mousedown", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("scroll", handleActivity);

    return () => {
      clearTimeout(inactivityTimerRef.current);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("scroll", handleActivity);
    };
  }, []); // Only run this effect once on component mount

  return isInactive;
};

export default useInactivityLogout;
