import { Select, Space } from "antd";
export const Dropdown = ({
  defaultValue,
  options,
  value,
  onChange,
  allowClear,
}) => (
  <Space wrap>
    <Select
     size="large"
      defaultValue={defaultValue}
      style={{
        width: 120,
      }}
      onChange={onChange}
      value={value}
      options={options}
      allowClear={allowClear}
    />
  </Space>
);
