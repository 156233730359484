import { Button, Spin, Modal, Tooltip } from "antd";
import { ReactComponent as EditIcon } from "assets/images/edit_svg_icon.svg";
import { ReactComponent as SentIcon } from "assets/images/SentIcon.svg";
import { ReactComponent as PatientUser } from "assets/images/PatientUser.svg";
import { Dropdown } from "../../shared/dropdown/Dropdown";
import { primaryColor } from "colors-config";
import PatientForgetIcon from "components/shared/Icon/PatientForgetIcon";
import PatientFlag from "./PatientFlag";
import { loginFlagText, registerFlagText } from "messages/messages";
import { forgetPasswordPatientService } from "api/patient";
import { createConfirmConfig } from "components/shared/ConfirmationModal/config";

export const AssignClinician = ({
  name,
  options,
  value,
  onChange,
  showDropdown,
  setShowDropdown,
  setShowEditIcon,
  sendAppStage,
  showEditIcon,
  onSendAppClick,
  patientEmail,
  messageApi,
  modal,
}) => {
  const onResendConfirmation = async () => {
    try {
      await forgetPasswordPatientService({ email: patientEmail });
      messageApi.success("Patient reset password email sent successfully.");
    } catch (error) {
      messageApi.error("Password reset attempt limit exceeded, please try after some time.");
    }
  };
  const resendPasswordConfig = createConfirmConfig({
    title: "Send Forgot Password Email",
    content: "Are you sure you want to send forgot password email to the patient?",
    onOk: onResendConfirmation,
    onCancel: () => {},
    okText: "Send",
  });

  // const [modal] = Modal.useModal();
  const onHover = () => {
    setShowEditIcon(true);
  };
  const onLeave = () => {
    setShowEditIcon(false);
  };
  const onIconCLick = () => {
    setShowDropdown(true);
  };

  const PatientAppIcon = () => (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.625 18H12.375C13.6181 18 14.625 16.9931 14.625 15.75V2.25C14.625 1.00688 13.6181 0 12.375 0H5.625C4.38188 0 3.375 1.00688 3.375 2.25V15.75C3.375 16.9931 4.38188 18 5.625 18ZM4.5 2.25H13.5V15.75H4.5V2.25Z" fill={primaryColor} />
      <path d="M6 9.20433L7.68 11L12 7" stroke={primaryColor} strokeLinecap="round" />
    </svg>
  )
  const appStatus = () => {
    return (
      <>
        {sendAppStage === 2 ? (
          <span
            style={{
              paddingLeft: "5px",
              marginRight: "10px",
            }}
          >
            <PatientFlag
              title={loginFlagText}
              icon={<PatientAppIcon />}
              flagText="Patient Logged In"
            />
          </span>
        ) : sendAppStage === 1 ? (
          <span
            style={{
              paddingLeft: "5px",
              marginRight: "10px",
            }}
          >
            <PatientFlag
              title={registerFlagText}
              flagText="Patient Registered"
              icon={<PatientUser stroke={primaryColor} />}
            />
          </span>
        ) : (
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              onSendAppClick();
            }}
            icon={<SentIcon />}
            className="send-appBtn"
          >
            Send App
          </Button>
        )}
      </>
    );
  };

  const onForceForgetClick = async () => {
    modal.confirm(resendPasswordConfig);
  };
  return (
    <div className="assign-clinician">
      {showDropdown ? (
        <Dropdown
          value={value}
          onChange={onChange}
          options={options}
          allowClear={false}
          onSearch={false}
        />
      ) : (
        <div className="assign-clinician-hover">
          Clinician |{" "}
          <span
            className="clinician-name"
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
            style={showEditIcon ? { color: primaryColor } : {}}
          >
            {name ? (
              <span className="clinician-name">
                {" "}
                <b>{name}</b>
              </span>
            ) : (
              <Spin size="small" />
            )}
            <EditIcon fill={primaryColor} className="editIcon" onClick={onIconCLick} />
          </span>
        </div>
      )}
      {appStatus()}

      {(sendAppStage === 1 || sendAppStage === 2) && (
        <Tooltip title="Send a reset password email">
          <PatientForgetIcon onClick={onForceForgetClick} style={{ fontSize: "2rem" }} />
        </Tooltip>
      )}
    </div>
  );
};
