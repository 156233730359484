import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import SearchBar from 'components/shared/SearchBar/SearchBar';
import appointmentIcon from "../../../../../../assets/images/appointment.png";
const AppointmentHeader = ({ setSelectedPatient, clinicId,onNewPatientClick }) => {
  return (
    <div style={{ paddingRight: "20px" }}>
      <Row justify="space-between" align="middle">
        <Col md={6}>
          <div className="appointment-header-title">
            <div style={{ width: "28px", height: "auto" }}>
              <img width={"100%"} height={"auto"} src={appointmentIcon} alt="logo" />
            </div>
            <h2 style={{ margin: "0 0 0 10px" }}>Appointment</h2>
          </div>
        </Col>
        <Col md={4}>
          <Button type="primary" shape="round">
            <a
            target="_blank"
              onClick={onNewPatientClick}
            >
              {" "}
              New Patient
            </a>
          </Button>
        </Col>
        <Col md={13}>
          <SearchBar enterButton={true} clinicId={clinicId} onSelectCallback={setSelectedPatient} />
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentHeader;

AppointmentHeader.propTypes = {
  setSelectedPatient: PropTypes.func.isRequired,
};
