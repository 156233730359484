import { useState } from "react";

import { Form, Input, Row, Col, Button, message, Spin } from "antd";
import errorModal from "../shared/modals/errorModal";

import { useHistory } from "react-router-dom";

import { Auth } from "aws-amplify";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 12,
  },
};

export default function SignUpConfirmation({ username }) {
  const history = useHistory();
  const [isSubmitButtonSpinning, setIsSubmitButtonSpinning] = useState(false);

  async function confirmSignUp({ code }) {
    try {
      await Auth.confirmSignUp(username, code);
      message.success("User succesfully confirmed");
      setIsSubmitButtonSpinning(false);

      history.push("/login");
    } catch (error) {
      setIsSubmitButtonSpinning(false);

      errorModal(
        error.message.includes("1 validation error detected: Value")
          ? "Invalid verification code, please try again."
          : error.message
      );
    }
  }
  const onFinish = (values) => {
    if (values.code.trim().length === 0) {
      message.error("Confirmation code cannot be empty spaces. Try again!");
    } else {
      setIsSubmitButtonSpinning(true);
      confirmSignUp(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ marginTop: "30%" }}>
      <Row justify="center" align="middle">
        <Col span={12}>
          <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Enter the code sent to your email to confirm your registration
          </p>
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            layout={"vertical"}
            style={{ width: "100%" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Security Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Please check your email and provide the verification code",
                },
              ]}
            >
              <Input.Password size='large'/>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Spin spinning={isSubmitButtonSpinning}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Spin>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
