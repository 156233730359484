import { Modal, Upload, Image, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { dentistClinicApiTasks } from "../../../models/dentistClinic";
import axios from "axios";
import { DownloadIcon } from "components/shared/Icon/index";
import { StlViewer } from "react-stl-file-viewer";
import { downloadS3File } from "api/patient";
import dayjs from "dayjs";

const UploadClinicalFiles = ({ clinicalFiles, patientId, activeCaseId, getImg }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [type, setType] = useState("");
  const [file, setFile] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  // const [visible, setVisible] = useState(false);
  // let [dataSource, setDataSource] = useState([]);
  let [deleteFileOpen, setDeleteFileOpen] = useState(false);
  let [deleteFileData, setDeleteFileData] = useState();
  useEffect(() => {
    const updatedClinicalFiles = clinicalFiles.filter(
      (f) => f.resource_type !== "video" && f.resource_type !== "application"
    );
    updatedClinicalFiles.push(
      ...clinicalFiles
        .filter((f) => f.resource_type === "video")
        .map((obj) => ({
          ...obj,
          thumbUrl: require("../../../assets/images/video-icon.svg").default,
        }))
    );

    updatedClinicalFiles.push(
      ...clinicalFiles
        .filter((f) => f.resource_type === "application" && !f.resource_url.includes(".zip"))
        .map((obj) => ({
          ...obj,
          thumbUrl: require("../../../assets/images/doc-icon.svg").default,
        }))
    );
    updatedClinicalFiles.push(
      ...clinicalFiles
        .filter((f) => f.resource_type === "application" && f.resource_url.includes(".zip"))
        .map((obj) => ({
          ...obj,
          thumbUrl: require("../../../assets/images/zip_icon.png"),
        }))
    );
    const finalFileList = updatedClinicalFiles.map((obj) => ({
      ...obj,
      uid: obj.url,
      name: obj?.patient_image_view?.split("-")[0],
    }));

    const sortedArray = finalFileList
      .sort((a, b) => dayjs(a.upload_date).diff(dayjs(b.upload_date)))
      .reverse();

    setFileList(sortedArray);
  }, [clinicalFiles]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (file.resource_type === "application") {
      window.open(file.url, "_blank");
      return false;
    }
    if (file.resource_type === "video") {
      setPreviewOpen(true);
      setFile(file);
      setType("video");
      return false;
    }
    let imageType = "image";
    if (file.resource_url.includes(".stl")) {
      imageType = "stl";
    }

    setType(imageType);
    setFile(file);
    setPreviewOpen(true);
    setPreviewTitle("Preview");
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const deleteClinicalFile = async (data) => {
    const deleteData = {
      patient_id: data?.patient_id,
      file_name: data?.resource_url,
    };
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/patient/resource/delete`, deleteData)
      .then((res) => {
        message.success("Resource Deleted Successfully");
        setFileList(fileList.filter((obj) => obj.resource_url !== data.resource_url));
        getImg();
      });
  };

  async function uploadFiles(options) {
    if (activeCaseId) {
      const { onSuccess, onError, file, onProgress, filename } = options;
      const fileNameArray = file.name.split(".");
      fileNameArray[0] = fileNameArray[0] + "-" + new Date().valueOf();
      const fileName = fileNameArray.join(".");
      const fmData = new FormData();
      const config = {
        headers: {
          "content-type": file.type,
        },

        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);

          if (percent === 100) {
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append("file", file);
      try {
        const preSignRequestBody = {
          patient_id: patientId,
          file_name: fileName,
          resource_type: "file",
          file_type: file.type,
          content_type: file.type,
        };
        const preSignedResponse = await dentistClinicApiTasks.post(
          "patient/resource/add",
          preSignRequestBody
        );
        const accessUrl = preSignedResponse.body.success.accessUrl?.split("?")[0];
        const uploadUrl = preSignedResponse.body.success.uploadUrl;
        const uploadResponse = await axios.put(uploadUrl, file, config);
        const successFullUpload = await dentistClinicApiTasks.post(
          "clinic/patient/resource/upload/confirm",
          {
            patient_id: patientId,
            file_url: accessUrl,
            patient_upload_stage: "clinical",
            resource_type: file?.type?.split("/")[0],
            scan_type: filename,
            case_id: activeCaseId,
          }
        );
        onSuccess("Ok");
        getImg();
      } catch (error) {
        console.log(error);

        onError({ error });
      }
    } else message.error("Cannot upload scan files for this case");
  }
  const properties = {
    customRequest: uploadFiles,

    beforeUpload: (file) => {
      // const isJpgOrPng =
      //   file.type === "image/jpeg" ||
      //   file.type === "image/png" ||
      //   file.type === "image/jpg";
      // if (!isJpgOrPng) {
      //   message.error("You can only upload JPG, JPEG and PNG files!");
      // }

      // const isLt2M = file.size / 1024 / 1024 < 20;
      // if (!isLt2M) {
      //   message.error("Image must smaller than 20MB!");
      // }
      // return  isLt2M;
      return true;
    },
  };
  const perview = () => {
    return (
      <>
        {file?.patient_image_view?.split("-")[0]}
        <span
          style={{ paddingLeft: "5px" }}
          onClick={() => {
            window.open(downloadS3File(file?.resource_url?.split("/")[1]));
          }}
        >
          {<DownloadIcon />}
        </span>
      </>
    );
  };

  const extractFileName = (file) => {
    if (file && file?.resource_url) {
      let fileName = file.resource_url.split("/");
      if (fileName.length && fileName.length > 1) {
        return fileName[1];
      }
    }
    return "";
  };

  const handleFileOk = () => {
    deleteClinicalFile(deleteFileData);
    setDeleteFileOpen(false);
  };

  const handleFileCancel = () => {
    setDeleteFileOpen(false);
  };

  const fileViewer = (type, url = "") => {
    if (!type) {
      return null;
    }
    if (type === "video") {
      return (
        <div className="preview-video">
          <ReactPlayer url={url} height="250px" width={"450px"} controls />
        </div>
      );
    }

    if (type === "stl") {
      return (
        <StlViewer
          width={500}
          height={300}
          orbitControls
          shadows
          url={url}
          objectColor="grey"
          volume={(sum) => {
            console.log("sum", sum);
          }}
          groundColor="rgb(255, 255, 255)"
          skyboxColor="rgb(255, 255, 255)"
          gridLineColor="rgb(0, 0, 0)"
          lightColor="rgb(255, 255, 255)"
        />
      );
    }

    return (
      <Image
        alt="example"
        style={{
          width: "100%",
          height: "250px",
        }}
        src={url}
      />
    );
  };

  return (
    <>
      <Upload
        className="clinic-file-upload"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={(e) => {
          setDeleteFileOpen(true);
          setDeleteFileData({
            patient_id: e.patient_id,
            resource_url: e.resource_url,
          });
          return false;
        }}
        {...properties}
        itemRender={(originNode, file) => {
          return (
            <div className="file-container" style={{ minWidth: "100px" }}>
              {originNode}
              {!file?.status && (
                <div
                  style={{
                    textAlign: "center",
                    textTransform: "capitalize",
                    overflowWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  <p title={file.name} className={"clinical-file-name"}>
                    {file.name}
                  </p>
                  <p style={{ margin: "0px", fontSize: "12px" }}>
                    {dayjs(file.upload_date).format("DD-MM-YYYY")}
                  </p>
                </div>
              )}
            </div>
          );
        }}
      >
        <div>
          <div style={{ margin: "14px 0 16px 0" }}>
            <UploadOutlined />
          </div>

          <div className="scan-upload-text">Click or drag file to this area to upload</div>
        </div>
      </Upload>

      <Modal
        visible={deleteFileOpen}
        title={"Delete File"}
        onOk={handleFileOk}
        onCancel={handleFileCancel}
      >
        <p>Are you sure you want to delete?</p>
      </Modal>

      {previewOpen && (
        <Modal
          visible={previewOpen}
          title={perview()}
          footer={null}
          onCancel={handleCancel}
          width={550}
        >
          {fileViewer(type, file?.url)}
          <div className="file-name">
            <b style={{ margin: "0px", fontSize: "13px" }}>{extractFileName(file)}</b>
            <p style={{ margin: "0px", fontSize: "12px" }}>
              {dayjs(file.upload_date).format("DD-MM-YYYY")}
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};
export default UploadClinicalFiles;
