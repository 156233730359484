import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, DatePicker, Button,Modal,message } from "antd";
import cardData from "./index.json";
import { CalendarOutlined ,ExclamationCircleFilled} from "@ant-design/icons";
import dayjs from "dayjs";
import { updateAlignerDateAPI } from "api/aligner";
import { JOURNEY_STATUS } from "constants";
import ConfirmationModal from "components/shared/ConfirmationModal/ConfirmationModal";
import "../../../assets/styles/components/patient-journey/patientTreatmentProgressComponent.scss";
import IPRCard from "components/IPRCard/IPRCard";
import Appointment from "../../shared/modals/Appointment/Appointment";
import { completeIPRSession } from "api/clinic";
import { companyType, primaryColor } from "colors-config";
const { confirm } = Modal;

function PatientTreatmentProgressComponent({
  aligners,
  clinic,
  treatmentId,
  patientInfoState,
  _getAlignerDetails,
  status
}) {
  const alignerNumberRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [modalType, setModalType] = useState("select");
  const [isAlignerModalOpen, setIsAlignerModalOpen] = useState(false);
  const [selectedAlignerDate, setSelectedAlignerDate] = useState(dayjs());
  const [alignersData, setAlignersData] = useState();
  const [currentSelected, setCurrentSelected] = useState();
  const [showPopupAddAppointment, setShowPopupAddAppointment] = useState(false);
  const [showBookingIframe, setShowBookingIframe] = useState(false);
  const [iprSelected,setIprSelected]=useState(false);
  const [selectedIprSession, setSelectedIprSession] = useState(null)

  let [completdModalOpen, setCompletdModalOpen] = useState(false);
  let [completeSessionList, setCompleteSessionList] = useState([]);


  useEffect(() => {
    if (aligners) {
      setAlignersData(aligners.body.data);
      setCurrentSelected(aligners.body.current_aligner);
    }
  }, [aligners]);

  const openAlignerDateModal = (date, count, type) => {
    const selectedAligner  = alignersData.find((obj) => obj.aligner_count == count)
    setSelectedAlignerDate(dayjs(date).startOf("day"));
    alignerNumberRef.current = { aligner: count, type };
    setModalType(type);
   
    if (checkSessionExists(selectedAligner)) {
      getAllSessionUnCompleted(selectedAligner);
     return showConfirm()
    }
    setIsAlignerModalOpen(true);
  };

  const showConfirm = () => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleFilled />,
      content: "Confirm all the  IPR/Attachment session or sessions has been completed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
        },
      },
      onOk() {
        setIsAlignerModalOpen(true);
      },
      onCancel() {
        setCompleteSessionList([])
      },
    });
  };


  const handleOk = async () => {
    let date = {
      start: "",
      end: "",
    };
    if (modalType === "start" || modalType === "end") {
      const d =
        dayjs(selectedAlignerDate).format("YYYY-MM-DD") +
        " " +
        dayjs().format("HH:mm:ss");
      date = {
        start: alignerNumberRef.current.type === "start" ? d : "",
        end: alignerNumberRef.current.type === "end" ? d : "",
      };
    }
    setLoader(true);
    await completeAllPreviousSessions();
    await updateAlignerDate(date);

    handleCancel();
  };
  const handleCancel = () => {
    alignerNumberRef.current = null;
    setCompleteSessionList([])
    setIsAlignerModalOpen(false);
  };

  const updateAlignerDate = async (date) => {
    try {
     
      await updateAlignerDateAPI({
        patient_id: patientInfoState.patient_id,
        lab_treatment_id: treatmentId,
        clinic_id: clinic.clinicId,
        aligner_number: alignerNumberRef.current.aligner,
        end_date: date.end,
        start_date: date.start,
        sendNotification:true
      });
      _getAlignerDetails();
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const onSelectAligner = (selectedAligner) => {
  const selectAligner  = { aligner: selectedAligner.aligner_count };
    alignerNumberRef.current = selectAligner
    // console.log("selectAligner", selectAligner)
    setModalType("select");

    
    
    if (checkSessionExists(selectedAligner)) {
      getAllSessionUnCompleted(selectedAligner);
      showConfirm()
    }
    else{
      setIsAlignerModalOpen(true);
    }
  
  };

  const onAlignerNewDateChange = (e) => {
    setSelectedAlignerDate(e);
  };

  const handleFileOk = async () => {
    try {
      const apiData = {
        clinicId: clinic.clinicId,
        aligner_count: selectedIprSession.aligner_count,
        patientId: patientInfoState.patient_id,
        caseId: selectedIprSession.caseId,
        type:selectedIprSession.type
      }
      setCompletdModalOpen(false);
      setSelectedIprSession(null);
      await completeIPRSession(apiData);
      message.success(selectedIprSession?.type === "BOTH" ? "IPR and Attachment session has been completed" : selectedIprSession?.type === "IPR" ? "IPR session has been completed" : "Attachment session has been completed")
      _getAlignerDetails();
    } catch (error) {
      message.error("Error occured")
    }
  };

  const callCompleteIPRSessionApi = (alignerData) => {
    return new Promise(async (resolve, reject) => {
      const apiData = {
        clinicId: clinic.clinicId,
        aligner_count: alignerData.aligner_count,
        patientId: alignerData.patientId,
        caseId: alignerData.caseId,
        type: alignerData.type
      }
      await completeIPRSession(apiData);
      return resolve();
    });
  }

  const completeAllPreviousSessions = async () => {
    if (completeSessionList.length) {
      await Promise.all(completeSessionList.map(async (alignerData) => {
        await callCompleteIPRSessionApi(alignerData)
      }));

    }
  }

  const handleFileCancel = () => {
    setCompletdModalOpen(false);
    setSelectedIprSession(null);
  };



  const createTreatmentData = (calendar) => {
    return (
      <div className="treatment-progress-card-body-container" style={{marginTop:"15px"}}>
        <Row  gutter={24}>
          <Col className="patient-start-date-title" span={12}>
            <Row>
            <span style={{fontWeight:"600", color: "#77888B"}}>Start date</span>
           </Row>
            <Row>
              <CalendarOutlined
                onClick={() =>{
                  openAlignerDateModal(
                    calendar.start_date,
                    calendar.aligner_count,
                    "start"
                  )
                }
          
                }
                className="calendar aligner-date"
                style={{
                  fontSize: "20px",
                  color: primaryColor,
                }}
              />
              <div
                style={{
                fontWeight:"700",
                paddingLeft:"5px",

                }}
              >
                {dayjs(calendar.start_date).format("DD/MM/YYYY")}
              </div>
            </Row>
          </Col>
          <Col className="patient-end-date-title" span={12}>
          <span style={{fontWeight:"600", color: "#77888B"}}>End date</span>
            <Row>
              <CalendarOutlined
                onClick={() =>{
                  openAlignerDateModal(
                    calendar.end_date,
                    calendar.aligner_count,
                    "end"
                  )
                }
     
                }
                className="aligner-date"
                style={{
                  fontSize: "20px",
                  color: primaryColor,
                }}
              />
              <div
                style={{
                  fontWeight:"700",
                paddingLeft:"5px",
                }}
              >
                {dayjs(calendar.end_date).format("DD/MM/YYYY")}
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const conformModalMakeTitle = (type) => {
    let title = "Change Aligner ";
    if (type === "select") {
      title = title + "Stage";
    } else {
      const text = type === "start" ? "Start" : "End";
      title = title + text + " Date";
    }
    return title;
  };

const onCompleteClick=(iprData)=>{
  setSelectedIprSession(iprData);
  setCompletdModalOpen(true)
}
  let iprSessionCount=0;

  const checkSessionExists = (alignerData) => {
    const findSession = alignersData.find(obj => {
      const type = obj.type;
      if (obj.aligner_count <= alignerData.aligner_count && (type == "IPR" || type == "ATTACHMENT" || type == "BOTH")) {
        return true;
      }
      return false;
    });
    return findSession ? true : false;
  }

  const getAllSessionUnCompleted = (alignerData) => {
    const findSession = alignersData.filter(obj => {
      const type = obj.type;
      if (obj.aligner_count <= alignerData.aligner_count && !obj.completed && (type == "IPR" || type == "ATTACHMENT" || type == "BOTH")) {
        return true;
      }
      return false;
    });
    console.log(findSession);
    setCompleteSessionList(findSession)
  }

  return (
    <div>
      {isAlignerModalOpen && (
        <ConfirmationModal
          loader={loader}
          handleCancel={handleCancel}
          handleOk={handleOk}
          title={conformModalMakeTitle(modalType)}
          confirmDisable={
            !selectedAlignerDate &&
            (modalType === "start" || modalType === "end")
          }
          open={isAlignerModalOpen}
        >
          {modalType === "select" ? (
            <div>Confirm you’d like to select this stage?</div>
          ) : (
            <DatePicker
              format="DD/MM/YYYY"
              value={selectedAlignerDate}
              onChange={onAlignerNewDateChange}
            />
          )}
        </ConfirmationModal>
      )}

      <Card
        className="treatmemt-in-progress-card"
        style={{
          width: "100%",
          minHeight: "50rem",
          maxHeight: "50rem",
          textAlign: "left",
          boxShadow: "0px 10px 16px rgba(0, 0, 0, 0.02)",
          borderRadius: "8px",
          overflow: "auto",
        }}
        title="Timeline"
      >
      <Row gutter={[8,8]} style={{ marginTop: "15px" }}>
          {alignersData?.map((calendar, index) => {
            if(calendar.jawType){
              iprSessionCount++;
              return (
                <Col lg={12} key={index}>
                <IPRCard
                  count={iprSessionCount}
                  subTitle={`Before ${calendar.stage_name}`}
                  checked={calendar.completed}
                  setShowPopupAddAppointment={setShowPopupAddAppointment}
                  setIprSelected={setIprSelected}
                  onCompleteClick={onCompleteClick}
                  iprData={calendar}
                />
                </Col>
          
              );
            }
            else{
              // const patientResObj = cardData[0];
              return (
                <Col lg={12} key={index}>
                <Card
                hoverable
                  className={`${currentSelected == calendar.aligner_id
                    ? "treatment-progress-sub-cards-selected"
                    : "treatment-progress-sub-cards"} ${companyType==5?"treatment-progress-sub-cards-smilealign":""}`}
                  style={{
                    height:"100%",
                    background:"#F9F9F9",
                  }}
                  // style={{
                  //   width: "100%",
                  //   maxWidth: "230px",
                  //   height: "140px",
                  //   background: "#fafafa",
                  //   borderRadius: "4px",
                  // }}
                  title={
                    <div className="header-container">
                      <div>{calendar.stage_name}</div>
                      {status.clinic_patient_journey_status_name == JOURNEY_STATUS.TREATMENT_COMPLETED ? null :     <Button
                        shape="round"
                        type="primary"
                        className="select-button"
                        onClick={() => onSelectAligner(calendar)}
                      >
                        Select
                      </Button>}
                  
                    </div>
                  }
                >
                  {createTreatmentData(calendar)}
                </Card>
                </Col>
            
              );
            }
           
          })}
      </Row>
    
  
        {alignersData?.length == 0 && (
          <Row className="empty-component ">
            No Treatments have been added yet.
          </Row>
        )}
      </Card>
      {showPopupAddAppointment && (
        <Appointment
          setShowAppointment={setShowPopupAddAppointment}
          showAppointment={showPopupAddAppointment}
          setShowBookingIframe={setShowBookingIframe}
          patientInfoState={patientInfoState}
          iprSelected={iprSelected}
          setIprSelected={setIprSelected}
        />
      )}

      <Modal
        visible={completdModalOpen}
        title={"Complete Session"}
        onOk={handleFileOk}
        onCancel={handleFileCancel}
      >
        <p>Are you sure you want to complete {selectedIprSession?.type === "BOTH" ? "IPR and Attachment" : selectedIprSession?.type === "IPR" ? "IPR" : "Attachment"} Session?</p>
      </Modal>
    </div>
  );
}

export default PatientTreatmentProgressComponent;
