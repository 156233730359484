import { TWO_WEEKS_DURATION_ID } from "./constants";


export const notificationFormMapper = (payload) =>{
    return {
        iprNotification: payload.iprNotification,
        iprEmail: payload.iprEmail,
        chatNotification: payload.chatNotification,
        chatEmail: payload.chatEmail,
        ordersNotification: payload.ordersNotification,
        ordersEmail: payload.ordersEmail,
        summaryNotification: payload.summaryNotification,
        summaryEmail: payload.summaryEmail,
        iprNotificationDuration: payload.iprNotificationDuration
          ? Number(payload.iprNotificationDuration)
          : TWO_WEEKS_DURATION_ID,
          treatmentReadyApp: payload.treatmentReadyApp,
          treatmentReadyEmail: payload.treatmentReadyEmail,
          prescriptionEmail: payload.prescriptionEmail
      };
}

export const notificationToolTip = "Emails are sent multiple times a day in your local timezone to help you close your action items."

export const ORDERS_TOOLTIP ="Emails will be sent when the order is In Printing, Printing is Paused, or Shipped."

export const TREATMENT_READY_TOOLTIP ="Emails will be sent for both Treatment plan and Refinement Plan"