import { useRef, useState } from "react";
import { Modal, message } from "antd";
import PropTypes from "prop-types";
import TextEditor from "components/shared/TextEditor/TextEditor";
import ScanFiles from "components/private/SanFiles/ScanFiles";
import ClinicalFiles from "components/private/ClinicalFiles/ClinicalFiles";
import { refinementAdditionalDetailsService, uploadRefinementFileService } from "api/treatment";
import styles from "./styles.module.scss";
import { addClinicComment, getCommentTextTemplate } from "api/chat";
import { createConfirmConfig } from "components/shared/ConfirmationModal/config";
import { checkTextEditorContent } from "utilities/IPR.js";
import { sendMessage } from "api/chat";

const RefinementAdditionalDetails = ({
  refinementAdditionalDetailModalVisible,
  setRefinementAdditionalDetailModalVisible,
  labId,
  clinicId,
  patientId,
  treatmentPlanId,
  setShowTreatmentSetupModal,
  treatment,
  setRefinementRequested,
  getCurrentTreatmentStatus,
  setShowRefinementButton,
  getRefinementDetails,
  getPatientInformation,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const [editorInput, setEditorInput] = useState("");
  const [loader, setLoader] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const clinicalFilesRef = useRef(null);

  const scanFilesRef = useRef(null);

  const closeModal = () => setRefinementAdditionalDetailModalVisible(false);

  const getConfirmOrderConfig = () => {
    const cancelConfirm = createConfirmConfig({
      title: "Are you sure you want to continue?",
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      onOk: closeModal,
      onCancel: () => {},
      okText: "Ok",
      zIndex: 1001, // Ensure this is higher than the default zIndex of modals
    });

    return cancelConfirm;
  };

  const onCancel = () => {
    if ((editorInput && editorInput !== "<p><br></p>") || isChanged) {
      return modal.confirm(getConfirmOrderConfig());
    }
    closeModal();
  };

  const makeFileData = (file, treatmentRefinementId, fileName) => {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("patientId", treatment?.patient_id);
    formData.append("treatmentRefinementId", treatmentRefinementId);
    formData.append("treatmentPlanId", treatment?.lab_treatment_id);
    formData.append("clinicId", treatment?.clinic_id);

    if (fileName) {
      formData.append("scanName", fileName);
    }
    return formData;
  };

  const onOk = async () => {
    const isContent = checkTextEditorContent(editorInput.toString());
    try {
      setLoader(true);
      const response = await refinementAdditionalDetailsService({
        labId: treatment?.lab_id,
        clinicId: treatment?.clinic_id,
        patientId: treatment?.patient_id,
        treatmentPlanId: treatment?.lab_treatment_id,
        details: isContent ? editorInput.toString() : "",
      });

      const { id: treatmentRefinementId } = response.data;

      const promises = [];

      if (clinicalFilesRef.current?.clinicalFiles?.length) {
        clinicalFilesRef.current.clinicalFiles.forEach((file) => {
          const formData = makeFileData(file.originFileObj, treatmentRefinementId);
          const promise = uploadRefinementFileService(formData);
          promises.push(promise);
        });
      }

      if (scanFilesRef.current?.scanFiles) {
        const keys = Object.keys(scanFilesRef.current.scanFiles);
        keys.forEach((key) => {
          const fileData = scanFilesRef.current.scanFiles[key];
          if (fileData.length) {
            const formData = makeFileData(fileData[0].originFileObj, treatmentRefinementId, key);
            const promise = uploadRefinementFileService(formData);
            promises.push(promise);
          }
        });
      }
      const mediaResponse = await Promise.all(promises);

      let mediaIds = [];
      if (mediaResponse.length) {
        mediaResponse.forEach((mObj) => {
          if (mObj?.data?.data?.patientMediumId) {
            mediaIds.push(mObj.data.data.patientMediumId);
          }
        });
      }

      const baseApiPayload = {
        module: "clinic",
        patient_id: treatment.patient_id,
        receiver_id: treatment.lab_id,
        sender_id: treatment?.clinic_id,
      };

      // const payload = {
      //   module: "clinic",
      //   patient_id: treatment.patient_id,
      //   receiver_id: treatment.lab_id,
      //   sender_id: treatment?.clinic_id,
      //   comment_description: getCommentTextTemplate(editorInput.toString()),
      //   mediaIds,
      // };

      const payload = {
        ...baseApiPayload,
        comment_description: getCommentTextTemplate(editorInput.toString()),
        mediaIds,
      };

      let imagesData = null;

      if (mediaIds && mediaIds.length) {
        imagesData = await addClinicComment(payload);
      }

      sendMessage({
        meta: baseApiPayload,
        message: getCommentTextTemplate(editorInput.toString()),
      });

      if (mediaIds && mediaIds.length) {
        imagesData.data?.body?.result?.forEach((el) => {
          sendMessage({
            meta: baseApiPayload,
            message: el.comment_description,
            fileContent: el.metaData,
          });
        });
      }
      closeModal();

      // setShowTreatmentSetupModal(true);
      // await addClinicComment(payload);
      await getCurrentTreatmentStatus();
      await getRefinementDetails(treatment.patient_id);
      await getPatientInformation();
      setRefinementRequested(true);
      message.success(`Refinement has been uploaded successfully`);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const setModalOkText = () => {
    return (editorInput && editorInput !== "<p><br></p>") || isChanged
      ? "Save & Next"
      : "Skip & Next";
  };

  return (
    <Modal
      title="Refinement Details (Optional)"
      visible={refinementAdditionalDetailModalVisible}
      width={600}
      onOk={onOk}
      confirmLoading={loader}
      onCancel={() => onCancel("cancel")}
      okText={setModalOkText()}
      okButtonProps={{ style: { borderRadius: "30px" } }}
      cancelButtonProps={{ style: { borderRadius: "30px" } }}
    >
      {contextHolder}
      <div>
        <div className={styles.subText}>Additional Details</div>
        <TextEditor
          editoInput={editorInput}
          setEditorInput={setEditorInput}
          hideToolbar={true}
          placeholder=""
          image={false}
        />
      </div>
      <div style={{ marginTop: "1rem" }}>
        <ClinicalFiles setIsChanged={setIsChanged} ref={clinicalFilesRef} />
      </div>
      <div style={{ marginTop: "1rem" }}>
        <ScanFiles setIsChanged={setIsChanged} ref={scanFilesRef} />
      </div>
    </Modal>
  );
};

// RefinementAdditionalDetails.propTypes = {
//   refinementAdditionalDetailModalVisible: PropTypes.bool.isRequired,
//   setRefinementAdditionalDetailModalVisible: PropTypes.func.isRequired,
//   labId: PropTypes.string.isRequired,
//   clinicId: PropTypes.string.isRequired,
//   patientId: PropTypes.string.isRequired,
//   treatmentPlanId: PropTypes.string.isRequired,
//   setShowTreatmentSetupModal: PropTypes.func.isRequired,
// };

export default RefinementAdditionalDetails;
