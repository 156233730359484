import { useEffect } from "react";
import ProductsAndServicesFormComponent from "./productsAndServicesFormComponent";
import { v4 as uuidv4 } from "uuid";
import { Button, Row, Col, Typography } from "antd";

import { InfoIcon } from "components/shared/InfoIcon/index";

import "../../../assets/styles/components/productsAndServicesContainerComponent.scss";
import { appCurrencyName } from "colors-config";
const productOrServiceDetailsStructure = {
  productOrServiceType: JSON.stringify({"product_type_id": 1,"product_type": "Treatment"}),
  productOrServiceName: "",
  price: "0",

  description: "--",
  currency: JSON.stringify(appCurrencyName),
  id: null,
  free: false,
  hidePatientPrice: true
};

const { Title } = Typography;

const ProductsAndServicesContainerComponent = ({
  shouldSubmit,
  productOrServiceTypes,
  setProductOrServiceTypes,
  productOrServiceTypeOptions,
  setProductOrServiceTypeOptions,
  productOrServiceNameOptions,
  setProductOrServiceNameOptions,
  productOrServiceCurrencyOptions,
  removeFromDB,
  mode,
  clinicId,
  paymentList,
}) => {
  const defaultProductOrServiceType = () => {
    const idOne = uuidv4();
    const idTwo = uuidv4();
    const defaultPaymentIds = [];
    paymentList.forEach((el) => {
      if (el.default) {
        defaultPaymentIds.push(el.id);
      }
    });
    
    const newProductOrServiceType = {
      ...productOrServiceDetailsStructure,
      id: idOne,
      productOrServiceName: JSON.stringify({"product_name_id": 1,"product_name": "Light"}),
      paymentIds: defaultPaymentIds,
    };

    const newProductOrServiceType2 = {
      ...productOrServiceDetailsStructure,
      id: idTwo,
      productOrServiceName: JSON.stringify({"product_name_id":2,"product_name":"Extended"}),
      paymentIds: defaultPaymentIds,
    };

    setProductOrServiceTypes({
      ...productOrServiceTypes,
      [idOne]: newProductOrServiceType,
      [idTwo]: newProductOrServiceType2,
    });
  }
  const createProductOrServiceType = () => {
    const id = uuidv4();
    const defaultPaymentIds = [];
    paymentList.forEach((el) => {
      if (el.default) {
        defaultPaymentIds.push(el.id);
      }
    });
    const newProductOrServiceType = {
      ...productOrServiceDetailsStructure,
      id,
      paymentIds: defaultPaymentIds,
    };

    setProductOrServiceTypes({
      ...productOrServiceTypes,
      [id]: newProductOrServiceType,
    });
  };
  useEffect(() => {
    if (!Object.keys(productOrServiceTypes).length) {
      defaultProductOrServiceType();
    }
  }, []);

  useEffect(() => {
    if (shouldSubmit) {
    }
  }, [shouldSubmit]);

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "6px",
        width: "100%",
      }}
    >
      <div>
        <Row justify="space-between" style={{ paddingBottom: '30px'}}>
          <Col>
            <Title className="product-or-service-title" level={5}>
              Treatment
              <InfoIcon
                info={
                  "Some default products and services have been defined to help you place orders to your lab."
                }
              />
            </Title>
          </Col>
        </Row>
      </div>

      <div>
        {Object.keys(productOrServiceTypes).map((productOrServiceType) => {
          return (
            <ProductsAndServicesFormComponent
              paymentList={paymentList}
              productOrServiceFields={
                productOrServiceTypes[productOrServiceType]
              }
              setProductOrServiceTypes={setProductOrServiceTypes}
              productOrServiceTypes={productOrServiceTypes}
              productOrServiceTypeOptions={productOrServiceTypeOptions}
              productOrServiceNameOptions={productOrServiceNameOptions}
              productOrServiceCurrencyOptions={productOrServiceCurrencyOptions}
              setProductOrServiceTypeOptions={setProductOrServiceTypeOptions}
              setProductOrServiceNameOptions={setProductOrServiceNameOptions}
              removeFromDB={removeFromDB}
              mode={mode}
            />
          );
        })}
      </div>
      <div>
        <Row justify="space-between">
          <Col></Col>
          <Col
            style={{
              marginBottom: "0.95rem",
              marginRight: "2rem",
            }}
          >
            <Button
              type="primary"
              size="middle"
              style={{
                borderRadius: "20px",
                width: "10rem",
                display: mode === "view" ? "none" : "",
              }}
              onClick={createProductOrServiceType}
            >
              Add Product/services
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProductsAndServicesContainerComponent;
