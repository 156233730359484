export const stlFilesOrder = (file) => {
    switch (file.name.toLowerCase()) {
      case "upperarch.stl":
        return 1;
      case "lowerarch.stl":
        return 2;
      case "bitescan1.stl":
        return 3;
      case "bitescan2.stl":
        return 4;
      default:
        return 5;
    }
  };
  
  
  export const getSTLUrl = (file, fileUrl) => {
    const withOutAccessKeyImg = fileUrl.indexOf("?");
    const processedURl = fileUrl.slice(0, withOutAccessKeyImg);
    return `${processedURl}`;
  };