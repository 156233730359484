import { useState, useEffect } from "react";
import { Table, Button, Row, Col, Empty, Tag, message, Tooltip } from "antd";
import { DownloadOutlined , EyeOutlined} from "@ant-design/icons";
import { downloadButton } from "../../shared/downloadButton";
import axios from "axios";
import moment from "moment";
import "../../../assets/styles/components/patient-journey/patientDetailTableComponent.scss";
import { CalenderIcon } from "components/shared/Icon/index";
import { downloadPatientInvoiceAPI, updatePatientOrderPaymentStatus } from "api/patient";
import { getLabOrderList } from "api/lab";
import UpdatePaymentStatusModal from "./updatePaymentStatusModal";
import dayjs from "dayjs";
import { companyType, primaryColor } from "colors-config";
import styles from "assets/styles/components/patient-journey/patientTable.module.scss";
import LabInvoiceModal from "../LabInvoiceModal/components/LabInvoiceModal";

export default function PatientDetailTableComponent({ mode, data, getOrders, modal }) {
  const [tableData, setTableData] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [paymentStatusList, setPaymentStatusList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showInvoiceModal,setShowInvoiceModal] = useState(false)
  const [orderInfo,setOrderInfo] = useState(null)

  const date = (text) => {
    return (
      <Row>
        <Col style={{ marginTop: "0.7rem" }}>
          {/* <CalendarOutlined style={{ fontSize: "18px" }} /> */}
          <CalenderIcon />
        </Col>

        <Col style={{ marginLeft: "0.5rem" }}>
          <p> {text}</p>
        </Col>
      </Row>
    );
  };

  const appointmentColumns = [
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      render: (text) => date(text),
    },
    {
      title: "APPOINTMENT STATUS",
      dataIndex: "appointmentStatus",
      key: "appointmentStatus",
    },
    {
      title: "APPOINTMENT TYPE",
      dataIndex: "appointmentType",
      key: "appointmentType",
    },
    {
      title: "APPOINTMENT NAME",
      dataIndex: "appointmentName",
      key: "appointmentName",
    },
    {
      title: "DENTIST NAME",
      dataIndex: "dentistName",
      key: "dentistName",
    },
  ];

  const patientOrderColumns = [
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      // fixed: "left",
      render: (text) => date(text),
    },
    {
      title: "ORDER ID",
      dataIndex: "orderId",
      key: "orderId",
      // fixed: "left",
    },
    {
      title: "ORDER TYPE",
      dataIndex: "orderType",
      key: "orderType",
    },
    {
      title: "PRICE",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "ARCH TREATMENT",
      dataIndex: "archTreatment",
      key: "archTreatment",
    },
    {
      title: "PAYMENT STATUS",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (paymentStatus, record) => {
        // console.log("record", record);
        return (
          <>
            {/* <div
              style={{
                borderRadius: "10px",
                paddingTop: "3px",
                paddingBottom: "3px",
                width: "130px",
                position: "relative",
              }}
              key={paymentStatus}
              onClick={() => showPaymentModal(record)}
            > */}
            {paymentStatus}
            {/* {record.orderType !== "Treatment" && <DownOutlined style={{ float: "right", marginTop: "0.25rem", position: "relative", pointerEvents: 'none' }} />} */}
            {/* </div> */}
          </>
        );
      },
    },
    {
      title: "ORDER INVOICE",
      dataIndex: "orderInvoice",
      key: "orderInvoice",
      // fixed: "right",
      render: (invoice, record) => {
        return (
          <Row style={{ marginTop: "1rem",  }}>
          <Col
            style={{
              marginTop: "0.05rem",
              marginBottom: "11%",
              marginLeft: "30%",
              cursor:"pointer",
            }}
          >
             <Tooltip title="Download">
             <DownloadOutlined style={{ fontSize: "17px", marginRight:"20px" }} onClick={() => _getPatientPdf(record.orderId)} />
             </Tooltip>
         
          </Col>
        </Row>
        )
    
      },
    },
  ];


  const showOderCreationDetails = (rowData) => {
    const { orderVia, createdFrom, othersDescription } = rowData;
    let orderDetails = "";

    if (orderVia !== "others") {
      orderDetails = `Confirmed via ${orderVia}.`;
    } else if (orderVia === "others" && othersDescription) {
      orderDetails = `Confirmed via ${othersDescription}.`;
    }

    modal.confirm({
      title: "Order Details",
      content: (
        <div>
          <div>
            <span>
              <b>Order placed by</b>
            </span>
            <span style={{ color: primaryColor, marginLeft: "5px" }}>{createdFrom}.</span>
          </div>
          {createdFrom === "lab" && orderVia && <div> {orderDetails}</div>}
        </div>
      ),
      footer: null,
      closable: true,
      icon: null,
    });
  };

  const onDownloadAllClick = (shippingFiles) => {
    shippingFiles.forEach((file) => {
      downloadHandler(file);
    });
  };

  const downloadHandler = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.download = file.name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let labOrderColumns = [
    {
      title: "ORDER ID",
      dataIndex: "orderId",
      key: "orderId",
      // fixed: "left",
      // width: 150,
    },
    {
      title: "LAB PRODUCT/SERVICE NAME",
      dataIndex: "productService",
      key: "productService",
      // fixed: "left",
      // width: 220,
    },
    {
      title: "QUANTITY",
      dataIndex: "quantity",
      key: "quantity",
      // width: 100,
    },
    {
      title: "LAB PRICE PER UNIT",
      dataIndex: "price",
      key: "price",
      // width: 160,
    },
    {
      title: "LAB TOTAL PRICE",
      dataIndex: "totalPrice",
      key: "totalPrice",
      // width: 150,
    },
    {
      title: "DELIVERY STATUS",
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      // width: 180,
    },
    {
      title: "TRACKING LINK",
      dataIndex: "trackingLink",
      key: "trackingLink",
      width: 180,
    },
    {
      title: "PAYMENT STATUS",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      // width: 180,
    },
    {
      title: "ORDER INITIATED BY",
      dataIndex: "createdFrom",
      key: "createdFrom",
      // width: 160,
      render: (value, colData) => {
        return (
          <div>
            <span className={styles["created-from"]}>{value}</span>
            {value === "lab" && colData?.orderVia && (
              <span
                onClick={() => showOderCreationDetails(colData)}
                className={styles["order-detail-clickable"]}
                style={{ color: primaryColor }}
              >
                See details
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "INVOICE" ,
      dataIndex: "orderInvoice",
      key: "orderInvoice",
      // fixed: "right",
      // width: 150,
      render: (invoice, record) => {
        return (
          <Row style={{ marginTop: "1rem" }}>
            <Col>
            {
             record.shippingFiles.length ?   <div style={{display:"flex", alignItems:"center"}}>
             <Tooltip title="View" >
               <Button
               onClick={() =>{
                 setOrderInfo(record)
                 setShowInvoiceModal(true)
               }}
                className="invoice-viewBtn"
                 icon={<EyeOutlined style={{ fontSize: "17px", cursor:"pointer" }} />}
               ></Button>
             </Tooltip>
             <Tooltip title="Download" >
               <DownloadOutlined style={{ fontSize: "17px", cursor:"pointer" }}  onClick={() => onDownloadAllClick(record?.shippingFiles)}/>
             </Tooltip>
             </div> : "--"
            }
            
             
            </Col>
          </Row>
        );
   
      },
    },
  ];
  

  const showPaymentModal = (record) => {
    if (record.orderType !== "Treatment") {
      setSelectedOrder(record);
      setIsModalVisible(true);
    }
  };

  const _getPatientPdf = (patientOrderId) => {
    setLoadingPdf(true);
    // console.log("in fun", patientOrderId);
    //debugger
    // axios
    //   .get(
    //     `${process.env.REACT_APP_BASE_URL}/patient/pdf?patientOrderId=${patientOrderId}`
    //   )
    downloadPatientInvoiceAPI("patient", patientOrderId)
      .then(async (res) => {
        setLoadingPdf(false);
        console.log(res.body);
        window.open(res.body.url);
      })
      .catch(async (err) => {
        setLoadingPdf(false);
        alert(err);
        console.log("data---", err);
      });
  };
  const _getClinicPdf = (patientOrderId) => {
    setLoadingPdf(true);
    downloadPatientInvoiceAPI("labInvoice", patientOrderId)
      .then(async (res) => {
        setLoadingPdf(false);
        window.open(res.body.url);
      })
      .catch(async (err) => {
        setLoadingPdf(false);
        alert(err);
      });
  };

  // console.log(data, "liberate".repeat(10), mode);
  useEffect(() => {
    if (data?.length) {
      setTableData(createTableData());
    }
  }, [data]);

  useEffect(() => {
    getLabOrderStatusListApi();
  }, []);

  const getLabOrderStatusListApi = async () => {
    try {
      const labOrderStatusListResponse = await getLabOrderList();
      if (labOrderStatusListResponse?.data?.body?.order_Status) {
        setPaymentStatusList(labOrderStatusListResponse.data.body.order_Status);
      }
    } catch (error) {}
  };

  let locale = {
    emptyText: (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Orders to Show</span>} />
    ),
  };
  const pickColumns = (mode) => {
    if (mode === "appointments") {
      return appointmentColumns;
    } else if (mode === "patientOrders") {
      return patientOrderColumns;
    } else if (mode === "labOrders") {
      return labOrderColumns;
    }
  };

  const onTrackOrderClicked = (link) => {
    if (link.includes("https://")) {
      window.open(link, "_blank");
    } else {
      const updatedLink = "https://" + link;
      window.open(updatedLink, "_blank");
    }
  };

  const confirmUpdateStatus = ({ paymentStatus }) => {
    const [{ payment_status_id: paymentStatusId }] = paymentStatusList.filter(
      (paymentStatusDetails) => paymentStatusDetails.payment_status_name === paymentStatus
    );
    const orderId = selectedOrder.orderId;
    confirmUpdatePaymentStatus(orderId, paymentStatusId);
  };

  const confirmUpdatePaymentStatus = async (orderId, paymentStatusId) => {
    const hide = message.loading("Updating payment status", 0);

    const model = {
      patient_order_id: orderId,
      payment_status_id: paymentStatusId,
    };

    try {
      await updatePatientOrderPaymentStatus(model);
      getOrders();
      setTimeout(hide, 0);

      message.success("Order Status updated successfully");
    } catch (error) {
      setTimeout(hide, 0);

      message.error("Failed to update status. Please try again!");
    }
  };


  const createTableData = () => {
    return data.map((dataObj, index) => {
      if (mode === "appointments") {
        return {
          key: index,
          date: dataObj.appointment_date
            ? moment(dataObj.appointment_date).format("DD/MM/YYYY")
            : "Not Available",
          appointmentStatus: dataObj.status_name ? dataObj.status_name : "Not Available",
          appointmentType: dataObj.appointment_type ? dataObj.appointment_type : "Not Available",
          appointmentName: dataObj.appointment_name ? dataObj.appointment_name : "Not Available",
          dentistName: dataObj.clinician_name ? dataObj.clinician_name : "Unassigned",
        };
      } else if (mode === "patientOrders") {
      
        return {
          key: index,
          date: dataObj.product_order_date
            ? moment(dataObj.product_order_date).format("DD/MM/YYYY")
            : "Not Available",
          orderId: dataObj.patient_order_id ? dataObj.patient_order_id : "Not Available",
          orderType: dataObj.product_type ? dataObj.product_type : "Not Available",
          price:
            typeof dataObj.product_price === "number"
              ? dataObj.currency_name + " " + dataObj.product_price
              : "Not Available",
          archTreatment: dataObj.product_name ? dataObj.product_name : "Not Available",

          paymentStatus: dataObj.payment_status_name
            ? dataObj.payment_status_name
            : "Awaiting Payment",
        };
      } else if (mode === "labOrders") {
        let unitPrice = dataObj.free
          ? dataObj.currency_name + " " + "0 (Free)"
          : typeof dataObj.product_price === "number"
          ? dataObj.currency_name + " " + dataObj.product_price
          : "Not Available";
        let totalPrice =
        dataObj.free
          ? dataObj.currency_name + " " + "0 (Free)"
          : typeof dataObj.product_price === "number"
          ? dataObj.currency_name + " " + (dataObj.quantity?dataObj.quantity:1) * dataObj.product_price
          : "Not Available";
          
        if (companyType == 5 && !dataObj.labProductId) {
          unitPrice = "Not Available";
          totalPrice = "Not Available";
        }

        return {
          key: index,
          orderId: dataObj.patient_order_id ? dataObj.patient_order_id : "Not Available",
          orderType: dataObj.product_type ? dataObj.product_type : "Not Available",
          // price: typeof dataObj.product_price === "number"
          //   ? dataObj.free ? dataObj.currency_name + " " + "0 (Free)" : dataObj.currency_name + " " + dataObj.product_price
          price: unitPrice,
          totalPrice: totalPrice,
          quantity: typeof dataObj.quantity === "number" ? dataObj.quantity : !dataObj.quantity ? "--" : "Not Available",
          free: dataObj.free ? "Free" : dataObj.free == 0 ? "Paid" : "--",

          archTreatment: dataObj.product_name ? dataObj.product_name : "Not Available",
          productService: dataObj.product_service ? dataObj.product_service : "Not Available",
          deliveryStatus: dataObj.delivery_status ? dataObj.delivery_status : "Not Available",
          createdFrom: dataObj.createdFrom,
          orderVia: dataObj.orderVia,
          orderNote: dataObj.orderNote,
          othersDescription: dataObj.othersDescription,
          trackingLink: 
          (dataObj.order_tracking_link ||dataObj.tracking_no || dataObj.arrival_date ) ? (
            <div className="tracking-container">
              <div>
                <div title={dataObj.order_tracking_link} className="tracking-url">
                  <a
                    style={{ color: primaryColor,
                  textDecoration:"underline"
                  }}
                    href={dataObj.order_tracking_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {dataObj.order_tracking_link}
                  </a>
                </div>
                {/* {dataObj.tracking_no ? (
                  <div style={{ fontWeight: "bold" }}>{dataObj.tracking_no}</div>
                ) : null} */}
                {dataObj.arrival_date ? (
                  <div className="arrival-date">
                    <span>Est. Arrival date: </span>
                    <b>{dayjs(dataObj.arrival_date).format("DD-MM-YYYY")}</b>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* <Button
                // size="small"
                type="primary"
                style={{
                  borderRadius: "5rem",
                  // width: "7rem",
                  fontFamily: "Mulish",
                  // marginLeft: "10px",
                }}
                onClick={() => onTrackOrderClicked(dataObj.order_tracking_link)}
              >
                Track Order
              </Button> */}
            </div>
          ) : (
            "N/A"
          )
          ,
          paymentStatus: dataObj.payment_status_name ? dataObj.payment_status_name : "Awaiting",
          shippingFiles: dataObj.shippingFiles
        };
      }
    });
  };
  // console.log("tableData", tableData);

  return (
    <>
      <Table
        style={{ width: "100%" }}
        columns={pickColumns(mode)}
        // scroll={{
        //   x: 1300,
        // }}
        dataSource={tableData}
        locale={locale}
        loading={loadingPdf}
        // onRow={(record, recordIndex) => ({
        //   onClick: async (event) => {
        //     getInvoiceHandler(event ,record, mode)
        //   },
        // })}
      />
      {isModalVisible && (
        <UpdatePaymentStatusModal
          title="Update Payment status"
          paymentStatusList={paymentStatusList}
          selectedOrder={selectedOrder}
          showModal={isModalVisible}
          onConfirmation={confirmUpdateStatus}
          setShow={setIsModalVisible}
        ></UpdatePaymentStatusModal>
      )}
      {
        showInvoiceModal && (
          <LabInvoiceModal
          showInvoiceModal={showInvoiceModal}
          setShowInvoiceModal={setShowInvoiceModal}
          orderInfo={orderInfo}
          setOrderInfo={setOrderInfo}
          />
        )
      }
    </>
  );
}
