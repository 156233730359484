import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, message, Select, Modal, InputNumber, Input, Form, Checkbox, Divider } from "antd";
import TextEditor from "components/shared/TextEditor/TextEditor";
import { primaryColor, companyType } from "colors-config";
// import { PATIENTCONSTANTS } from "utilities/patientDetail";
import { updatePatientTreatmentData } from "api/patient";
// import { ReactComponent as BulbIcon } from "../../../../assets/images/BulbIcon.svg";
import AppAlert from "components/shared/Alert/Alert";
import styles from "./approveSentToPatient.module.scss";
import { SupportToolTip } from "utilities/settings";
import { PATIENTCONSTANTS } from "utilities/patientDetail";
import { approvalInfoMessage, detailInfoMessage, } from "messages/messages";
import { checkClinicIntercomTourStatus, updateClinicIntercomStatus } from "api/clinic";

const { Option } = Select;

const ApproveSentToPatient = ({
  isModalApproveSendPlanVisible,
  handleApproveSendPlanCancel,
  treatment,
  productList,
  treatmentProgressDet,
  productOrServiceCurrencyOptions,
  setIsModalApproveSendPlanVisible,
  confirmTreatmentStatus,
  patientId,
  clinic,
  wholeTreatmentPlan,
  fromShareWithPatient,
  setFromShareWithPatient,
  setSendOrderWithoutPatientApproval
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [netPrice, setNetPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [productCurrency, setProductCurrency] = useState("");
  const [approveSendPlanPopLoading, setApproveSendPlanPopLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [clinicProductFree, setClinicProductFree] = useState(false);
  const [patientApproval, setPatientApproval] = useState(false);
  const [isModalPatientApprovePlanVisible, setIsModalPatientApprovePlanVisible] = useState(false);

  const getTotalPrice = () => {
    if (treatment?.labProductData?.quantityType == "Per aligner") {
      return (treatment?.labProductData?.price * treatment?.quantity).toFixed(2);
    }
    return treatment?.labProductData?.price.toFixed(2);
  };

  useEffect(() => {
    if (productList.length && treatmentProgressDet) {
      let productCurrency = {};
      const product = productList.find(
        (el) => el.product_name === treatmentProgressDet.treatment_name
      );
      if (product) {
        productCurrency = productOrServiceCurrencyOptions.find(
          (el) => el.currency_id === product.product_currency_id
        );
        const hideTreatmentInfo = Boolean(product.hidePatientPrice);
      form.setFieldValue("hideTreatmentInfo", hideTreatmentInfo);

      setDisableTPInfo(hideTreatmentInfo);
      }
      

      if (treatmentProgressDet?.treatment_name) {
        form.setFieldValue("treatmentName", product ? product.product_id : null);
      }
      // if (treatmentProgressDet?.arches_treated) {
      //   form.setFieldValue("arches", treatmentProgressDet?.arches_treated);
      // }

      if (typeof treatmentProgressDet?.treatment_price === "number") {
        let discountPercentage = 0;
        let dAmount = 0;
        let tAmount = 0;

        if (treatmentProgressDet.treatment_price !== 0 && treatmentProgressDet?.discount) {
          dAmount = treatmentProgressDet.discount;
          discountPercentage = getDiscountPercentage(
            treatmentProgressDet.treatment_price,
            treatmentProgressDet.discount
          );
          tAmount = treatmentProgressDet.treatment_price - treatmentProgressDet.discount;
        } else {
          discountPercentage = 0;
          dAmount = 0;
          tAmount = treatmentProgressDet.treatment_price;
        }
        setNetPrice(treatmentProgressDet.treatment_price);
        setDiscount(discountPercentage);
        setDiscountAmount(dAmount);
        setTotalAmount(tAmount);
        if (treatmentProgressDet.currency_name || productCurrency.currency_name) {
          form.setFieldValue(
            "price",
            treatmentProgressDet.currency_name
              ? `${treatmentProgressDet.currency_name} ${tAmount}`
              : `${productCurrency.currency_name} ${tAmount}`
          );
        }
        form.setFieldValue("free", tAmount === 0);
        setClinicProductFree(tAmount === 0);
        setProductCurrency(treatmentProgressDet.currency_name);
      }
      if (treatmentProgressDet?.treatment_price == "") {
        setNetPrice(0);
        setDiscount(0);
        setDiscountAmount(0);
        setTotalAmount(0);
        if(treatmentProgressDet.currency_name || productCurrency.currency_name){
          form.setFieldValue(
            "price",
            treatmentProgressDet.currency_name
              ? `${treatmentProgressDet.currency_name} 0`
              : `${productCurrency.currency_name} 0`
          );
        }
      
      }

      const isRefinement = treatment?.revision_plan_version;

      //first refinement case
      if (isRefinement) {
        let isRefinementFree = true;
        // let price = 0;
        // const refinementProduct = productList.find(
        //   (el) => el.product_name.toLowerCase() === "refinement"
        // );
        // set refinement prodcut in case of refinement
        // if (refinementProduct) {
        form.setFieldValue("treatmentName", null);
        // isRefinementFree = refinementProduct.price === 0;
        // price = refinementProduct.price;
        // } else {
        setNetPrice(0);
        setTotalAmount(0);
        setDiscount(0);
        setDiscountAmount(0);
        // }
        if (treatmentProgressDet.currency_name || productCurrency.currency_name) {
          form.setFieldValue(
            "price",
            `${treatmentProgressDet.currency_name
              ? treatmentProgressDet.currency_name
              : productCurrency.currency_name
            } ${0} Free`
          );
        }

        form.setFieldValue("free", isRefinementFree);

        setClinicProductFree(isRefinementFree);
      }
    }
  }, [productList, treatmentProgressDet]);

  const calculateTotalPrice = (price, discount) => {
    var numVal1 = Number(price);
    var numVal2 = Number(discount);

    const totalValue = numVal1 * ((100 - numVal2) / 100);
    return Math.ceil(totalValue);
  };

  const getDiscountPercentage = (netPrice, value) => {
    const total = Number(netPrice) - Number(value);
    const diffPercentage = (total / netPrice) * 100;
    const actualPercentage = Number(100 - isNaN(diffPercentage) ? 0 : diffPercentage).toFixed(1);
    return actualPercentage;
  };

  const onTreatmentNameChange = async (value) => {
    let currency = [];
    console.log("value", value);
    const product = productList.find((el) => el.product_id === value);
    console.log("product", product);
    if (product) {
      const { discount, price, product_currency_id } = product;

      if (product_currency_id) {
        const currencyOptions = [...productOrServiceCurrencyOptions];
        currency = currencyOptions.filter((obj) => obj.currency_id === product_currency_id);
        if (currency) {
          setProductCurrency(currency[0].currency_name);
        }
      }

      let discountPercentage = 0;
      let dAmount = 0;
      let tAmount = 0;

      if (discount) {
        dAmount = discount;
        discountPercentage = getDiscountPercentage(price, discount);
        tAmount = price - discount;
      } else {
        discountPercentage = 0;
        dAmount = 0;
        tAmount = price;
      }
      setNetPrice(price);
      setDiscount(discountPercentage);
      setDiscountAmount(dAmount);
      setTotalAmount(tAmount);

      form.setFieldValue(
        "price",
        currency.length ? currency[0].currency_name + " " + tAmount : tAmount
      );
      form.setFieldValue("free", product.free);
      setClinicProductFree(product.free);
      handleFreeProduct(product.free);
      setDisableTPInfo(product.hidePatientPrice?true:false);
      form.setFieldValue("hideTreatmentInfo", product.hidePatientPrice?true:false);
      // form.setFieldValue("price", tAmount);

      if(!treatmentProgressDet?.treatment_name){
        const formData = await form.validateFields();
        await savePatientTreatmentData(formData.treatmentName);
      }

    }
  };

  const handleFreeProduct = (checked) => {
    const selectedTreatmentName = form.getFieldValue("treatmentName");
    if (selectedTreatmentName) {
      let productCurrency = "";
      const product = productList.find((el) => el.product_id === selectedTreatmentName);
      if (product) {
        productCurrency = productOrServiceCurrencyOptions.find(
          (el) => el.currency_id === product.product_currency_id
        )?.currency_name;
        if (!checked) {
          const totalPriceForShow = product.discount
            ? product.price - product.discount
            : product.price;
          setTotalAmount(totalPriceForShow);
          form.setFieldValue("price", `${productCurrency} ${totalPriceForShow}`);

          setDiscountAmount(product.discount);
          setNetPrice(product.price);
          const discountPercentage = getDiscountPercentage(product.price, product.discount);
          setDiscount(discountPercentage);
        } else {
          form.setFieldValue("price", `${productCurrency} ${0}`);
          setTotalAmount(0);
          setDiscountAmount(product.price);
          setNetPrice(0);
          setDiscount(100);
        }
      }
    }
  };

  const onAmountChange = async (value, key) => {
    let discountPercentage = 0;
    let dAmount = 0;
    let tAmount = 0;
    if (key === "discount") {
      tAmount = calculateTotalPrice(netPrice, value);
      discountPercentage = value;
      dAmount = netPrice - tAmount;
    }
    if (key === "discountAmount") {
      if (value > netPrice) {
        return message.error(`Discount amount cannot be more than ${productCurrency} ${netPrice}`);
      }
      tAmount = netPrice - value;
      discountPercentage = getDiscountPercentage(netPrice, value);
      dAmount = value;
    }

    setDiscount(discountPercentage);
    setDiscountAmount(dAmount);
    setTotalAmount(tAmount);

    form.setFieldValue("price", productCurrency + " " + tAmount);
  };

  const onFinish = (values) => {
    approveSendPlanOk();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const savePatientTreatmentData = async (treatmentName) => {
    if (treatmentProgressDet?.prescriptionId) {
      const product = productList.find((el) => el.product_id === treatmentName);
      let arches;
      if (treatment?.num_of_upper_aligners > 0 && treatment?.num_of_lower_aligners > 0) {
        arches = PATIENTCONSTANTS.UPPER_AND_LOWER;
      }
      else if (treatment?.num_of_upper_aligners > 0 && treatment?.num_of_lower_aligners <= 0) {
        arches = PATIENTCONSTANTS.UPPER;
      }
      else if (treatment?.num_of_upper_aligners <= 0 && treatment?.num_of_lower_aligners > 0) {
        arches = PATIENTCONSTANTS.LOWER;
      }
      else {
        arches = treatmentProgressDet?.arches_treated;
      }
      if (product) {
        const inputData = {
          clinicId: clinic.clinicId,
          patientId: patientId,
          netPrice: clinicProductFree ? 0 : netPrice,
          discount: discountAmount,
          archesTreated: arches,
          treatmentName: product.product_name,
          labTreatmentId: treatment?.lab_treatment_id,
          clinicProductFree,
          fromApproveTab: true,
        };
        await updatePatientTreatmentData(treatmentProgressDet.prescriptionId, inputData);
      }

    }
  };

  const approveSendPlanOk = async () => {
   
    try {
      const formData = await form.validateFields();
      setApproveSendPlanPopLoading(true);
      if (!formData.hideTreatmentInfo) {
        if (treatmentProgressDet?.prescriptionId) {
          await savePatientTreatmentData(formData.treatmentName);
        } else {
          message.error("Prescription is not Added yet.");
          return;
        }
      }
      const isRefinement = treatment?.revision_plan_version ? true : false;

      let afterApproval = false;
      let sendToPatient = false;

      if(fromShareWithPatient){
        afterApproval=true;
        sendToPatient=false;
      }
      else {
        afterApproval=false;
        sendToPatient=true;
      }


      if(treatment.sendToPatient!=null){
        afterApproval=true;
        sendToPatient=false;
      }

      await confirmTreatmentStatus(
        treatment?.lab_treatment_id,
        "approved",
        "",
        inputText,
        isRefinement,
        clinicProductFree,
        sendToPatient,
        formData.hideTreatmentInfo,
        afterApproval
      );
      // if (process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT && process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT !== "") {
      //   const clinicIntercomTourStats = await checkClinicIntercomTourStatus(clinic.clinicId);
      //   if (!fromShareWithPatient) {
      //     console.log("clinicIntercomTourStats", clinicIntercomTourStats);
      //     if (!clinicIntercomTourStats?.data?.data?.intercomData || clinicIntercomTourStats?.data?.data?.intercomData?.sendToPatient === false) {
      //       setTimeout(async () => {
      //         console.log("clinicIntercomTourStats tour 2");
      //         window.Intercom('startTour', process.env.REACT_APP_INTERCOM_TOUR_SEND_TO_PATIENT);
      //         await updateClinicIntercomStatus(clinic.clinicId, {
      //           sendToPatient: true
      //         })
      //       }, 3000)
      //     }
      //   }
      // }
      // if (!fromShareWithPatient && clinicIntercomTourStats?.data?.data?.intercomData?.sendToPatient === false) {
      //   console.log("clinicIntercomTourStats tour 1",);
      //   setTimeout(async () => {
      //     console.log("clinicIntercomTourStats tour 2",);
      //     window.Intercom('startTour', "544844");
      //     await updateClinicIntercomStatus(clinic.clinicId,{
      //       sendToPatient:true
      //     })
      //   }, 3000)
      // }

      setSendOrderWithoutPatientApproval(false)
      
    } catch (err) {
      console.log("err", err);
      message.error("Error occured in saving Treatment Data");
    } finally {
      setApproveSendPlanPopLoading(false);
      setIsModalApproveSendPlanVisible(false);
      setFromShareWithPatient(false);
    }
  };

  const onFreeChange = (e) => {
    const { checked } = e.target;
    setClinicProductFree(checked);
    // form.setFieldValue(
    //   "price",
    //   checked ? `${productCurrency} 0 Free` : `${productCurrency} ${totalAmount}`
    // );

    handleFreeProduct(checked);
  };

  const onPriceChange = (value) => {
    setNetPrice(!value ? 0 : value);

    let tAmount = 0;
    tAmount = calculateTotalPrice(value, discount);

    setTotalAmount(tAmount);

    form.setFieldValue("price", productCurrency + " " + tAmount);
  };

  const setHeading = () => {
    const isRefinement = treatment?.revision_plan_version;

    if (isRefinement) {
      return `Refinement Plan Version ${treatment.revision_plan_version}.0`;
    }

    return `Treatment Plan Version ${treatment.treatment_plan_version}.0`;
  };

  const handlePatientApprovePlanCancel = () => {
    setIsModalPatientApprovePlanVisible(false);
  };

  const [disableTPInfo, setDisableTPInfo] = useState(false);
  const onTPhideChange =  async (e) => {
    try {
      const formData = await form.validateFields();
      
      console.log("validateFields",formData);
      setDisableTPInfo(e.target.checked);

    } catch (error) {
      console.log("error",error);
      form.resetFields(['hideTreatmentInfo']);
    }
    
   
  };

  const goToSettings = () => {
    history.push("/clinic/settings/services");
  };

  return (
    <>
      {isModalApproveSendPlanVisible && (
        <Modal
          title="Send to patient for approval"
          confirmLoading={approveSendPlanPopLoading}
          okText="Send"
          cancelText="Cancel"
          visible={isModalApproveSendPlanVisible}
          onOk={() => form.submit()}
          onCancel={handleApproveSendPlanCancel}
          okButtonProps={{
            style: { width: "7rem", borderRadius: "30px" },
          }}
          cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
          width={950}
        >
          <div className="treatment-container">
            <Row>
              <Col span={8}>
                <div style={{ marginBottom: "8px" }}>
                  <label>{"Treatment Plan Version"}</label>
                  <Input size={"large"} value={setHeading()}></Input>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={18} style={{ marginBottom: "20px" }}>
                <AppAlert
                  message={
                    <span>
                      Treatment plan will be sent to{" "}
                      <strong>patient app </strong> for approval
                      <strong> but you can still proceed to order. </strong>
                    </span>
                  }
                />
                {/* <Alert
                  className="new-alert-type1"
                  message={
                    <span>
                      Treatment plan will be sent to <strong>patient app </strong> for approval
                    </span>
                  }
                  type="info"
                  showIcon
                  icon={<BulbIcon />}
                /> */}
              </Col>
            </Row>
            <Divider />

            <>
              <Row justify="space-between">
                <Col lg={12}>
                  {" "}
                  <p className="treatment-header">Lab Order Details</p>
                </Col>
                <Col lg={12}>
                  {" "}
                  <div className="treatment-colTwo">
                  <p className="treatment-header">
                    Visible to Clinic only
                  </p>{" "}
                  <SupportToolTip
                    title={detailInfoMessage}
                    label=""
                  />
                  </div>
              
                </Col>
              </Row>
          
              <div className="treatment-section">
                <Row gutter={[16, 16]}>
                  <Col lg={8}>
                    <p className="treatment-label">No, of Upper Aligners</p>
                    <p className="treatment-info">
                      {treatment?.num_of_upper_aligners}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <p className="treatment-label">No, of Lower Aligners</p>
                    <p className="treatment-info">
                      {treatment?.num_of_lower_aligners}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <p className="treatment-label">Attachments</p>
                    <p className="treatment-info">
                      {treatment?.attachments === 1 ? "Yes" : "No"}
                    </p>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col lg={8}>
                    <p className="treatment-label">Lab Product/Service Name</p>
                    <p className="treatment-info">
                      {treatment?.labProductData
                        ? treatment?.labProductData?.productName
                        : "--"}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <p className="treatment-label">Quantity</p>
                    <p className="treatment-info">
                      {" "}
                      {/* {treatment?.quantity &&
                        treatment?.labProductData?.productName
                          ? treatment?.quantity
                          : "--"} */}
                      {treatment?.quantity &&
                      treatment?.labProductData?.productName
                        ? treatment?.quantity
                        : !treatment.quantity &&
                          treatment?.labProductData?.quantityType === "Package"
                        ? "1"
                        : "--"}
                    </p>
                  </Col>
                  {/* <Col lg={4}>
                      <p className="treatment-label">Payment status</p>
                      <p className="treatment-info" style={{color: primaryColor}}>
                        {treatment?.labProductData?.free
                    ? "Free" : treatment?.labProductData?.free == 0 ? "Paid" 
                    : "--"}
                      </p>
                    </Col> */}
                  {treatment?.labProductData?.quantityType == "Per aligner" ? (
                    <Col lg={4}>
                      <p className="treatment-label">Lab Price per Unit</p>
                      <p className="treatment-info">
                        {/* {treatment?.labProductData
                            ? treatment?.labProductData?.currency +
                              " " +
                              treatment?.labProductData?.price
                            : "--"} */}

                        {treatment?.labProductData?.free
                          ? `${treatment?.labProductData?.currency} 0 (Free)`
                          : treatment?.labProductData
                          ? treatment?.labProductData?.currency +
                            " " +
                            treatment?.labProductData?.price.toFixed(2)
                          : "--"}
                      </p>
                    </Col>
                  ) : null}
                  <Col lg={4}>
                    <p className="treatment-label">Lab Total Cost</p>
                    <p className="treatment-info">
                      {treatment?.labProductData?.free
                        ? `${productCurrency} 0 (Free)`
                        : treatment?.labProductData
                        ? `${
                            treatment?.labProductData?.currency
                          } ${getTotalPrice()}`
                        : "--"}
                      {/* {treatment?.labProductData
                          ? treatment?.labProductData?.currency +
                            " " +
                            getTotalPrice()
                          : "--"} */}
                    </p>
                  </Col>
                </Row>
              </div>
              <Divider />
              <Row justify="space-between">
                <Col lg={12}>
                  {" "}
                  <p className="treatment-header">Clinic Section</p>
                </Col>
                <Col lg={12}>
                  {" "}
                  <div className="treatment-colTwo">
                  <p className="treatment-header">
                  Visible on Patient App
                  </p>{" "}
                  <SupportToolTip
                    title={approvalInfoMessage}
                    label=""
                  />
                  </div>
              
                </Col>
              </Row>

              <Form
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
                className="treatment-form"
              >
                <Row>
                  <Col md={24}>
                    <Form.Item valuePropName="checked" name="hideTreatmentInfo">
                      <Checkbox onChange={onTPhideChange}>
                        Hide Treatment Name & Price from Patient
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                {!disableTPInfo ? (
                  <>
                    <Row gutter={[16, 16]}>
                      <Col md={12}>
                        <Form.Item
                          label="Treatment Name"
                          name="treatmentName"
                          rules={[
                            {
                              required: true,
                              message: "Please select a treatment",
                            },
                          ]}
                        >
                          <Select
                            disabled={disableTPInfo}
                            size="large"
                            style={{ width: "100%" }}
                            placeholder="Select Treatment"
                            onChange={onTreatmentNameChange}
                          >
                            {productList?.map((item) => {
                              return (
                                <Option
                                  value={item.product_id}
                                  key={item.product_id}
                                >
                                  {item.product_name} - {item.product_type}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      {/* <Col lg={8}>
                  <Form.Item
                    label="Arches Treated"
                    name="arches"
                    rules={[
                      {
                        required: true,
                        message: "Please select an option",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      // value={treatmentData.archesTreated}
                      name="archesTreated"
                      // onChange={(value) => {
                      //   onTreatmentDataChange(value, "archesTreated");
                      // }}
                      style={{ width: "100%" }}
                      placeholder="Select Arches"
                    >
                      <Option value={PATIENTCONSTANTS.UPPER_AND_LOWER}>Upper and Lower</Option>
                      <Option value={PATIENTCONSTANTS.UPPER}>Upper</Option>
                      <Option value={PATIENTCONSTANTS.LOWER}>Lower</Option>
                    </Select>
                  </Form.Item>
                </Col> */}
                      <Col md={12} style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            top: "-0.4rem",
                            right: "0",
                          }}
                        >
                          <Form.Item
                            name="free"
                            label={""}
                            style={{
                              textAlign: "left",
                            }}
                            valuePropName="checked"
                          >
                            <Checkbox
                              disabled={disableTPInfo}
                              onChange={onFreeChange}
                            >
                              Free
                            </Checkbox>
                          </Form.Item>
                        </div>
                        <Form.Item
                          label="Price"
                          name="price"
                          rules={[
                            {
                              required: true,
                              message: "Please input your price!",
                            },
                          ]}
                        >
                          <Select
                            size="large"
                            style={{ width: "100%" }}
                            placeholder="Price"
                            disabled={clinicProductFree || disableTPInfo}
                            // suffixIcon={suffix}
                            popupClassName="netPrice-dropdown"
                            dropdownRender={(menu) => (
                              <div className="price-dropDown">
                                <div className="row-one">
                                  <p>Price</p>
                                  <InputNumber
                                    size="small"
                                    type="number"
                                    style={{ width: "100%" }}
                                    min={0}
                                    placeholder="Price"
                                    value={netPrice}
                                    controls={false}
                                    disabled={clinicProductFree}
                                    // onChange={(event)=>{onTreatmentDataChange(event.target.value,"netPrice")}}
                                    // onChange={onNameChange}
                                    onChange={onPriceChange}
                                    onKeyDown={(e) => e.stopPropagation()}
                                    suffix={
                                      <b style={{ color: primaryColor }}>
                                        {productCurrency}
                                      </b>
                                    }
                                  />
                                </div>

                                <div className="row-one">
                                  <p>Discount</p>
                                  <div className="row-two">
                                    <InputNumber
                                      size="small"
                                      type="number"
                                      //  max={100}
                                      min={0}
                                      placeholder="Discount"
                                      className="discount-amount-input"
                                      suffix="%"
                                      disabled={clinicProductFree}
                                      controls={false}
                                      value={discount}
                                      onChange={(value) => {
                                        if (value > 100) {
                                          message.error(
                                            `Discount cannot be more than 100%`
                                          );
                                        } else {
                                          onAmountChange(value, "discount");
                                        }
                                      }}
                                      onKeyDown={(e) => e.stopPropagation()}
                                    />
                                    <InputNumber
                                      size="small"
                                      type="number"
                                      className="discount-amount-input"
                                      controls={false}
                                      // max={treatmentData.netPrice}
                                      disabled={clinicProductFree}
                                      min={0}
                                      placeholder="Amount"
                                      suffix={
                                        <b style={{ color: primaryColor }}>
                                          {/* {treatmentData.currencyName} */}
                                        </b>
                                      }
                                      value={discountAmount}
                                      onChange={(value) => {
                                        onAmountChange(value, "discountAmount");
                                      }}
                                      onKeyDown={(e) => e.stopPropagation()}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="price-footer"
                                  style={{ background: primaryColor }}
                                >
                                  <p>Total</p>
                                  <b>{`${productCurrency} ${
                                    totalAmount !== 0 ? totalAmount : "0 Free"
                                  }`}</b>
                                </div>
                              </div>
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Form>
              {!disableTPInfo ? (
                <Row>
                  <Col md={24}>
                    <AppAlert
                       message={
                        <span>
                        Name and price will be shared with the patient. They'll see duration, arches, and notes. Name incorrect? Update in{" "}
                          <span
                            onClick={goToSettings}
                            style={{ color: primaryColor }}
                            className={styles["hightlight"]}
                          >
                             settings
                          </span>{" "}
                         .
                        </span>
                      }
                    />
                  </Col>
                </Row>
              ) : <Row>
                  <Col md={24}>
                    <AppAlert
                       message={
                        <span>
                       When marked as 'Hide', the Treatment name and price will not be shared with the patient on the patient app. Instead the patient will only see the 3D treatment plan, arches treated and the duration of the treatment as information only.
                        </span>
                      }
                  
                    />
                  </Col>
                </Row>}
            </>
            <Row>
              <div style={{ width: "100%" }}>
                <label>Treatment Details</label>
                <div className="treatment-details-notes">
                  <TextEditor
                    // disabled={disableTPInfo}
                    editoInput={inputText}
                    setEditorInput={setInputText}
                    hideToolbar={true}
                    placeholder="Write here"
                    image={false}
                  />
                </div>
              </div>
            </Row>
          </div>
        </Modal>
      )}

      <Modal
        title="Are you sure?"
        confirmLoading={approveSendPlanPopLoading}
        okText="Confirm"
        cancelText="Cancel"
        visible={isModalPatientApprovePlanVisible}
        onOk={approveSendPlanOk}
        onCancel={handlePatientApprovePlanCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        <p style={{ fontWeight: "600", marginBottom: "0" }}>
          Approve Treatment Plan without sending to patient.
        </p>
        <p style={{ marginTop: "0px" }}>
          Make sure you have consent offline with patient.
        </p>
      </Modal>
    </>
  );
};

export default ApproveSentToPatient;
