import { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Modal,
  Form,
  Input,
  Select,
  Radio,
  Spin,
  message,
  Row,
  Col,
  InputNumber,
  Checkbox,
} from "antd";
import { clinicAddress, clinicDefaultAddress } from "services/redux/selectors/clinicSelectors";
import styles from "../../../assets/styles/modals/customSelect.module.scss";
import { CheckOutlined } from "@ant-design/icons";
import { getClinicLabs, updatePatientApprovalAPI } from "api/clinic";
import { getLabProductList } from "api/lab";
import { FORM_CONSTANTS } from "components/private/newPrescriptionForm/constants";
import { getOrderMessage } from "utilities/patientDetail";
import { companyType } from "colors-config";
import { createConfirmConfig } from "components/shared/ConfirmationModal/config";
import CustomEditIcon from "components/shared/Icon/EditIcon";
import ConfirmationModal from "components/shared/modals/confirmationModal";
const { Option } = Select;
const { TextArea } = Input;

const LabOrderModal = ({
  title,
  onConfirmation,
  showModal,
  setShow,
  clinicProducts,
  partneredLabs,
  patientId,
  clinic,
  treatmentProgressDet,
  patientAddressRef,
  orderRetainerClick,
  orderRefinementClick,
  wholeTreatmentPlan,
  prescriptionForm,
  fromPatientJourneyButton,
  setFromPatientJourneyButton,
  setSendOrderWithoutPatientApproval,
  setShowAddPatientAddress,
  patientInfoState,
  modal,
  form,
  confirmPatientAddressModal,
  sendOrderWithoutPatientApproval,
  dropdownLabTreatmentId,
  paymentStatus,
  setIsModalUpdateOrderVisible,
  setDropdownLabTreatmentId,
  status
}) => {
  const clinicMultipleAddresses = useSelector(clinicAddress);

  const defaultAddress = useSelector(clinicDefaultAddress);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedProductType, setSelectedProductType] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [selectedLab, setSelectedLab] = useState(null);
  const [partneredLabsByProduct, setPartneredLabsByProduct] = useState([]);
  const [isLabInputSpinning, setIsLabInputSpinning] = useState(true);
  const [addressId, setAddressId] = useState();
  const [sendTo, setSendTo] = useState("clinic");
  const [productTypeList, setProductTypeList] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  // const [productNameList, setProductNameList] = useState([]);
  // const [form] = Form.useForm();

  const [labProducts, setLabProducts] = useState([]);
  const [selectedLabProduct, setSelectedLabProduct] = useState([]);
  const [labProductLoading, setLabProductLoading] = useState(false);
  const [treatmentVersionList, setTreatmentVersionList] = useState([]);
  const [selectedLabProductName, setSelectedLabProductName] = useState();
  const [confirmLabApproveLoading, setConfirmLabApproveLoading] = useState(false);
  const [showAgreeModal, setShowAgreeModal] = useState(false);
  const [otherDisable, setOtherDisable] = useState(false);

  useEffect(() => {
    setAddressId(defaultAddress?.id || 0);
  }, [defaultAddress]);

  useEffect(() => {
    if (selectedLab) {
      getAllLabProducts(selectedLab);
    }
  }, [selectedLab]);

  useEffect(() => {
    if (wholeTreatmentPlan.length) {
      const generateVersionList = wholeTreatmentPlan.map((i) => ({
        ...i,
        label: i.revision_plan_version
          ? `Refinement Plan Version ${i.revision_plan_version}.0`
          : `Treatment Plan Version ${i.treatment_plan_version}.0`,
        value: i.treatment_plan_version,
      }));

      setTreatmentVersionList(generateVersionList);
    }
  }, [wholeTreatmentPlan]);

  const getApprovedTreatment = (treatmentVersionList) => {
    if (fromPatientJourneyButton) {
      const approvedTreatment = treatmentVersionList.find(
        (obj) => obj.lab_treatment_id == dropdownLabTreatmentId
      );
      return approvedTreatment;
    } else {
      const approvedTreatment = treatmentVersionList.find((obj) => obj.approved);
      if (!approvedTreatment) {
        return treatmentVersionList[0];
      }
      return approvedTreatment;
    }
  };

  useEffect(() => {
    if (treatmentVersionList.length) {
      const getDefaultVersion = getApprovedTreatment(treatmentVersionList);
      if(getDefaultVersion){
        setDropdownLabTreatmentId(getDefaultVersion.lab_treatment_id);
      }
      form.setFieldValue("version", getDefaultVersion.lab_treatment_id);
      form.setFieldValue("labProductId", getDefaultVersion?.labProductId);
      let quantity = getDefaultVersion?.quantity ? getDefaultVersion?.quantity : null;
      if (!fromPatientJourneyButton) {
        quantity = 1;
      }
      quantity = quantity
        ? quantity
        : getDefaultVersion?.labProductData?.quantityType === "Package"
        ? 1
        : null;
      form.setFieldValue("quantity", quantity);

      if (getDefaultVersion?.labProductData) {
        form.setFieldValue(
          "price",
          getDefaultVersion?.labProductData?.free
            ? getDefaultVersion?.labProductData?.currency + " " + "0.00"
            : getDefaultVersion?.labProductData?.currency +
                " " +
                getDefaultVersion?.labProductData?.price.toFixed(2)
        );
        form.setFieldValue(
          "totalprice",
          getDefaultVersion?.labProductData?.free
            ? getDefaultVersion?.labProductData?.currency + " " + "0.00"
            : getDefaultVersion?.labProductData?.currency +
                " " +
                (quantity * getDefaultVersion?.labProductData?.price).toFixed(2)
        );
        form.setFieldValue("arches", getDefaultVersion?.labProductData?.arches);
        form.setFieldValue("free", getDefaultVersion?.labProductData?.free);
        setSelectedLabProductName(getDefaultVersion.labProductData);
      }
    }
  }, [treatmentVersionList]);

  const getAllLabProducts = async (labId) => {
    try {
      setLabProductLoading(true);
      const labProductsRes = await getLabProductList(labId);
      if (labProductsRes?.data?.labProducts?.length) {
        const labProductList = labProductsRes.data.labProducts;
        setLabProducts(labProductList);
        const selectedLabProductId = form.getFieldValue("labProductId");
        const findProduct = labProductList.find((obj) => obj.id == selectedLabProductId);
        if (!findProduct) {
          form.setFieldValue("labProductId", null);
        }
      }
      form.setFieldValue("phasing", prescriptionForm.getFieldValue("phasing"));
    } catch (error) {
      // message.error("Some Error occured!");
      console.error(error);
    } finally {
      setLabProductLoading(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  useEffect(() => {
    if (clinicProducts?.length) {
      const uniqueProductTypeList = getAllProductTypes(clinicProducts);
      setProductTypeList(uniqueProductTypeList);
      if (uniqueProductTypeList?.length) {
        let defaultSelect = "Treatment";
        if (orderRetainerClick) defaultSelect = "Retainers";
        if (orderRefinementClick) defaultSelect = "Refinement";
        let defaultProduct = uniqueProductTypeList.find((obj) => obj.product_type == defaultSelect);
        if (!defaultProduct) {
          defaultProduct = uniqueProductTypeList[0];
        }
        const product_type_id = defaultProduct.product_type_id.toString();
        setSelectedProductType(product_type_id);
        _getPartneredLabsByProduct(product_type_id);

        form.resetFields();
      }
    }
  }, [clinicProducts]);

  useEffect(() => {
    if (selectedProductType) {
      const getAllProductNameList = clinicProducts.filter(
        (obj) => obj.product_type_id == selectedProductType
      );
      // setProductNameList(getAllProductNameList);
      let defaultProductName = getAllProductNameList[0].product_name_id.toString();
      if (treatmentProgressDet?.treatment_name) {
        const getSelectedProductName = getAllProductNameList.find(
          (obj) => obj.product_name == treatmentProgressDet?.treatment_name
        );
        if (getSelectedProductName) {
          defaultProductName = getSelectedProductName?.product_name_id.toString();
        }
      }

      setSelectedProductName(defaultProductName);
    }
  }, [selectedProductType]);

  useEffect(() => {
    if (partneredLabsByProduct.length) {
      const labId = partneredLabsByProduct[0].lab_id;
      setSelectedLab(labId);

      form.setFieldsValue({ lab: labId });
    }
  }, [partneredLabsByProduct]);

  const _getPartneredLabsByProduct = async (productId) => {
    setIsLabInputSpinning(true);
    try {
      const partneredLabsRes = await getClinicLabs({
        clinicId: clinic.clinicId,
        productTypeId: productId,
      });
      setPartneredLabsByProduct(partneredLabsRes.body.partnerLabDetails);
      setIsLabInputSpinning(false);
    } catch (error) {
      console.log(error);
      setIsLabInputSpinning(false);
    }
  };

  const getAllProductTypes = (products) => {
    const uniqueElements = Object.values(
      products.reduce((acc, obj) => {
        acc[obj.product_type] = obj;
        return acc;
      }, {})
    );
    return uniqueElements;
  };

  const labOrderSubmit = () => {
    if (paymentStatus === "-") {
      setIsModalUpdateOrderVisible(true);
      return message.info("Order cannot be placed without payment");
    }
    const versionNo = form.getFieldValue("version");
    const treatmentPlanDetails = treatmentVersionList.find(
      (obj) => obj.lab_treatment_id == versionNo
    );

    if (
      treatmentPlanDetails &&
      ((treatmentPlanDetails.sendToPatient == 1 && treatmentPlanDetails.patientApproval == 0) ||
        sendOrderWithoutPatientApproval)
    ) {
      confirmPatientConsentModal(treatmentPlanDetails);
    } else {
      continueOrder();
    }
  };

  const handleOk = () => {
    if (companyType === 7) {
      setShowAgreeModal(true);
    } else {
      labOrderSubmit();
    }
  };

  const continueOrder = () => {
    if (sendTo == "patient") {
      if (
        patientInfoState &&
        (!patientInfoState.patient_address ||
          !patientInfoState.patient_address ||
          !patientInfoState.patient_city ||
          !patientInfoState.patient_country ||
          !patientInfoState.patient_county ||
          !patientInfoState.patient_postal_code)
      ) {
        setShowAddPatientAddress(true);
      } else {
        confirmPatientAddressModal();
      }
    } else {
      form.submit();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShow(false);
    setFromPatientJourneyButton(false);
    setSendOrderWithoutPatientApproval(false);
  };

  const shouldFormRender = () => {
    return (
      productTypeList?.length > 0 &&
      selectedProductName &&
      selectedProductType &&
      partneredLabs.length > 0
    );
  };

  const productNameSelectChanged = (val) => {
    setSelectedProductName(val);
  };

  const productTypeSelectChanged = (val) => {
    setSelectedProductType(val);
    _getPartneredLabsByProduct(val);
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  // useEffect(() => {
  //   if (treatmentProgressDet?.labProductId && labProducts.length) {
  //     const findProduct = labProducts.find(
  //       (obj) => obj.id == treatmentProgressDet.labProductId
  //     );
  //     if (findProduct) {
  //       form.setFieldsValue({
  //         labProductId: treatmentProgressDet.labProductId,
  //         labProductPrice: `${findProduct.price} ${findProduct.currency}`,
  //       });
  //     }
  //   }
  // }, [treatmentProgressDet, labProducts]);

  const onTreatmentVersionChange = (value) => {
    if(value==='other'){
      setOtherDisable(true);
      form.setFieldValue("labProductId", null);
      form.setFieldValue("quantity", 1);
      form.setFieldValue("price", null);
      form.setFieldValue("arches", null);
      form.setFieldValue("totalprice", null);
      form.setFieldValue("free", null);
      return;
    }
    setOtherDisable(false);
    const getSelectedTreatment = treatmentVersionList.find((obj) => obj.lab_treatment_id == value);
    if (getSelectedTreatment && getSelectedTreatment?.labProductId) {
      form.setFieldValue("labProductId", getSelectedTreatment?.labProductId);
      const quantity = getSelectedTreatment?.quantity ? getSelectedTreatment?.quantity : null;
      form.setFieldValue(
        "quantity",
        quantity
          ? quantity
          : getSelectedTreatment?.labProductData?.quantityType === "Package"
          ? 1
          : null
      );
      form.setFieldValue(
        "price",
        getSelectedTreatment?.labProductData?.free
          ? getSelectedTreatment?.labProductData?.currency + " " + "0"
          : getSelectedTreatment?.labProductData?.currency +
              " " +
              getSelectedTreatment?.labProductData?.price
      );
      form.setFieldValue("arches", getSelectedTreatment?.labProductData?.arches);
      form.setFieldValue("free", getSelectedTreatment?.labProductData?.free);
      form.setFieldValue(
        "totalprice",
        getSelectedTreatment?.labProductData?.free
          ? getSelectedTreatment?.labProductData?.currency + " " + "0"
          : getSelectedTreatment?.labProductData?.currency +
              " " +
              quantity * getSelectedTreatment?.labProductData?.price
      );
      setSelectedLabProductName(getSelectedTreatment?.labProductData);
    } else {
      form.setFieldValue("labProductId", null);
      form.setFieldValue("quantity", null);
      form.setFieldValue("price", null);
      form.setFieldValue("arches", null);
      form.setFieldValue("totalprice", null);
      form.setFieldValue("free", null);
    }
  };

  const labProductSelectChanged = (value) => {
    const getLabProductDetails = labProducts.find((obj) => obj.id == value);
    if (getLabProductDetails) {
      const { quantityType, price, currency, free } = getLabProductDetails;
      if (quantityType === "Package") {
        form.setFieldValue("quantity", 1);
        form.setFieldValue(
          "totalprice",
          getLabProductDetails?.free ? currency + " " + "0" : currency + " " + 1 * price
        );
      } else {
        const getQuantity = form.getFieldValue("quantity");
        form.setFieldValue(
          "totalprice",
          getLabProductDetails?.free
            ? getLabProductDetails?.currency + " " + "0"
            : getLabProductDetails?.currency + " " + getQuantity * getLabProductDetails?.price
        );
      }
      form.setFieldValue(
        "price",
        getLabProductDetails?.free
          ? getLabProductDetails?.currency + " " + "0"
          : getLabProductDetails?.currency + " " + getLabProductDetails?.price
      );
      form.setFieldValue("arches", getLabProductDetails?.arches);
      form.setFieldValue("free", getLabProductDetails?.free ? true : false);

      setSelectedLabProductName(getLabProductDetails);
    }
  };

  const onFinish = async (val) => {
    try {
      const { quantity, labProductId } = val;
      // const errorMessage = getOrderMessage(quantity, labProductId);
      //   if (errorMessage) {
      //   return message.error(errorMessage);
      //   }

      // treatmentVersionList
      const versionNo = form.getFieldValue("version");

      const treatmentPlanDetails = treatmentVersionList.find(
        (obj) => obj.lab_treatment_id == versionNo
      );
      val.refinement = false;
      if (treatmentPlanDetails && treatmentPlanDetails.revision_plan_version) {
        val.refinement = true;
      }

      const findProduct = clinicProducts.find(
        (obj) => obj.product_type_id == (val.refinement ? 6 : 1)
      );
      if (findProduct) {
        val.productType = findProduct.product_type_id;
        val.productName = findProduct.product_name_id;

        val.productCurrency = findProduct.product_currency_id;
        val.productId = findProduct.product_id;
        val.productPrice = findProduct.free ? 0 : findProduct.price;
        val.isProductFree = findProduct.free ? true : false;
        val.patientId = patientId;
        if(treatmentPlanDetails?.sendToPatient){
          val.sendToPatient = treatmentPlanDetails.sendToPatient
        }
        setConfirmLoading(true);
        await onConfirmation(val);
        setIsModalVisible(false);
        setShow(false);
      } else {
        message.error("Kindly select correct product");
        setIsModalVisible(false);
        setShow(false);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const onSendToChange = (e) => {
    setSendTo(e.target.value);
  };

  const onQuantityChange = (value) => {
    const labProductId = form.getFieldValue("labProductId");
    const getProductDetails = labProducts.find((obj) => obj.id == labProductId);
    if (getProductDetails) {
      form.setFieldValue(
        "totalprice",
        getProductDetails?.free
          ? getProductDetails?.currency + " " + "0"
          : getProductDetails?.currency + " " + value * getProductDetails?.price
      );
    }
  };

  const confirmPatientConsentModal = (treatmentPlanDetails) => {
    const confirmPatientConsent = createConfirmConfig({
      title: "Patient Consent",
      width: 520,
      icon: null,
      centered: true,
      okButtonProps: {
        loading: confirmLabApproveLoading,
      },
      content: (
        <div style={{ margin: "20px 0" }}>
          <div>
            <p>Have you taken your patient's approval to place this order?</p>
          </div>
        </div>
      ),
      onOk: async () => {
        try {
          setConfirmLabApproveLoading(true);
          if (!sendOrderWithoutPatientApproval) {
            await updatePatientApprovalAPI(treatmentPlanDetails.lab_treatment_id);
          }
        } catch (error) {
          console.log("error occured", error);
        } finally {
          setConfirmLabApproveLoading(false);
          continueOrder();
        }
      },
      onCancel: () => {},
      okText: "Confirm & Order",
    });
    modal.confirm(confirmPatientConsent);
  };

  const handleEdit = () => {
    setShowAddPatientAddress(true);
  };

  const onOtherChange = (e) => {
    setOtherDisable(e.target.checked);
  };

  return (
    <>
      <Modal
        okText={shouldFormRender() ? "Place Orders" : "Ok"}
        width={700}
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: { borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { borderRadius: "30px" } }}
      >
        {shouldFormRender() && (
          <Spin spinning={isLabInputSpinning}>
            {" "}
            <Form
              form={form}
              style={{
                width: "100%",
              }}
              name="complex-form"
              layout={"vertical"}
              onFinish={onFinish}
              initialValues={{
                clinicAddress: defaultAddress?.id,
                patientId: patientId,
                productType: selectedProductType,
                productName: selectedProductName,
                sendTo: "clinic",
                lab: selectedLab,
                patient_name:
                  patientInfoState?.patient_first_name + " " + patientInfoState.patient_last_name,
              }}
              className="labOrderModal-container"
            >
              <div>
                {/* {companyType == 1 || companyType == 5 ? (
                  <> */}
                <Row gutter={[16, 16]}>
                  <Col lg={12}>
                    <Form.Item
                      label="Patient Name"
                      name="patient_name"
                      className="inline-form-left"
                    >
                      <Input size="large" disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col lg={12} style={{ position: "relative" }}>
                    {/* {!fromPatientJourneyButton && (
                      <div style={{ position: "absolute", top: "-5px", left: "177px" }}>
                        <Form.Item name="other" label="" valuePropName="checked">
                          <Checkbox onChange={onOtherChange}>Other</Checkbox>
                        </Form.Item>
                      </div>
                    )} */}
                    {status.patient_journey_status_id !== 9 ?
                      <Form.Item
                        label="Treatment Plan Version"
                        name="version"
                        className="inline-form-right"
                      >
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          onChange={onTreatmentVersionChange}
                        >
                          {treatmentVersionList.map((item, index) => {
                            return (
                              <Option key={index} value={item.lab_treatment_id}>
                                {item.label}
                              </Option>
                            );
                          })}
                          {!fromPatientJourneyButton && (
                            <Option value="other">
                              Other
                            </Option>
                          )}
                        </Select>
                      </Form.Item> : null
                    }
                   
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col lg={12}>
                    <Form.Item label="Lab Name" name="lab">
                      <Select
                        size="large"
                        disabled
                        onChange={(labId) => {
                          setSelectedLab(labId);
                        }}
                        style={{ width: "100%" }}
                        dropdownRender={(menu) => <div>{menu}</div>}
                      >
                        {partneredLabsByProduct.map((lab) => (
                          <Option key={lab.lab_id} value={lab.lab_id}>
                            {lab.lab_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item label="Arches Treated" name="arches" className="inline-form-right">
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        placeholder="Select Option"
                        disabled
                      >
                        <Option value="Upper and Lower">Upper and Lower</Option>
                        <Option value="Upper">Upper</Option>
                        <Option value="Lower">Lower</Option>
                        {/* <Radio value={FORM_CONSTANTS.UPPER}>Upper</Radio>
            <Radio value={FORM_CONSTANTS.LOWER}>Lower</Radio> */}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col lg={12}>
                    <Form.Item
                      label="Lab Product/Service Name"
                      name="labProductId"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Product/Service Name is required",
                      //   },
                      // ]}
                    >
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        disabled={status.patient_journey_status_id === 9 ? false : (fromPatientJourneyButton || !otherDisable)}
                        onChange={labProductSelectChanged}
                        placeholder="Select Option"
                      >
                        {labProducts?.map((labProduct, index) => {
                          return (
                            <Option value={labProduct.id} key={index}>
                              {labProduct.productName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    {selectedLabProductName?.quantityType != "Package" ? (
                      <Form.Item
                        name="quantity"
                        label="Quantity"
                        rules={
                          [
                            // {
                            //   required: true,
                            //   message: "Quantity is required",
                            // },
                            // {
                            //   required: true,
                            //   message: "A valid number must be entered",
                            //   pattern: new RegExp(/^[0-9]+$/),
                            // },
                          ]
                        }
                      >
                        <InputNumber
                          disabled={
                            !fromPatientJourneyButton
                              ? selectedLabProductName?.quantityType != "Package"
                                ? false
                                : true
                              : true
                          }
                          size="large"
                          style={{ width: "100%" }}
                          placeholder="Quantity"
                          max={1000}
                          min={0}
                          onChange={onQuantityChange}
                        />
                      </Form.Item>
                    ) : null}
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col lg={12}>
                    <Form.Item name="price" label="Lab Price per Unit">
                      <Input size="large" placeholder="Price" disabled />
                    </Form.Item>
                  </Col>
                  <Col lg={12} style={{ position: "relative" }}>
                    <div style={{ position: "absolute", top: "-5px", left: "107px" }}>
                      <Form.Item name="free" label="" valuePropName="checked">
                        <Checkbox disabled>Free</Checkbox>
                      </Form.Item>
                    </div>
                    <Form.Item name="totalprice" label="Lab Total Price">
                      <Input size="large" placeholder="Total Price" disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="orderNote" style={{ textAlign: "left" }} label="Order note">
                  <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                </Form.Item>

                <Form.Item
                  name="sendTo"
                  style={{ textAlign: "left", marginTop: "2rem" }}
                  label="Shipping Address"
                >
                  <Radio.Group onChange={onSendToChange}>
                    <Radio value="clinic">Same as clinic address</Radio>
                    <Radio style={{ marginLeft: "3rem" }} value="patient">
                      Same as patient address
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                {sendTo === "patient" ? (
                  <div style={{ display: "flex", gap: "6px" }}>
                    <div>Patient Address:</div>
                    {patientAddressRef.current?.trim() ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          textTransform: "capitalize",
                        }}
                        onClick={handleEdit}
                      >
                        <span>{patientAddressRef.current?.trim()}</span>
                        <CustomEditIcon />
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </div>
                ) : (
                  <div className="custom-select">
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please choose a location",
                        },
                      ]}
                      label="Choose Location"
                      name="clinicAddress"
                    >
                      <Select
                        onChange={(value) => {
                          setAddressId(value);
                        }}
                      >
                        {clinicMultipleAddresses?.map((el) => {
                          return (
                            <Option value={el.id}>
                              <div className={styles["option-grid"]}>
                                <span className={styles["option-label"]}>{el.label}</span>
                                <span className={styles["option-description"]}>
                                  {` ${el.address ? el.address : ""} ${
                                    el.county ? el.county : ""
                                  } ${el.country ? el.country : ""}${el.zipCode ? ", " : ""}${
                                    el.zipCode ? el.zipCode : ""
                                  } `}
                                </span>
                                <span className={styles["option-checkmark"]}>
                                  {addressId === el.id ? <CheckOutlined /> : null}
                                </span>
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                )}
              </div>
            </Form>
          </Spin>
        )}
        {!shouldFormRender() && (
          <div>
            {productTypeList?.length <= 0 && <p>No products available</p>}
            {partneredLabs.length <= 0 && <p> No partnered labs available!</p>}
          </div>
        )}
      </Modal>

      {showAgreeModal && (
        <ConfirmationModal
          title="Are you sure?"
          content={
            "Do you want to continue submitting this order to the lab? Please note that you are confirming to the lab cost associated to this order."
          }
          showModal={showAgreeModal}
          setShow={setShowAgreeModal}
          onConfirmation={labOrderSubmit}
          okText="Confirm"
        ></ConfirmationModal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(LabOrderModal);
