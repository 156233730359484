import { useEffect, useState } from "react";

import { Row, Col } from "antd";
import PasswordHandleComponent from "../../components/shared/passwordHandleComponent";
// import logo from "../../assets/images/Smile-Genius-Logo-copy-2.png";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { message } from "antd";
import { headerColor,headerLogo } from "colors-config";

// const { Title } = Typography;

function ForgotPassword() {
  const history = useHistory();
  const location = useLocation();

  const [email] = useState(location.state.message);

  useEffect(() => {
    document.body.style = "background: #f3f6fa !important;";

    return () => {};
  }, []);

  const forgotPassword = (otp, newPassword) => {
    Auth.forgotPasswordSubmit(email, otp, newPassword)
      .then((data) => { 
        history.push("/login");
        message.success("Password successfully changed");
      })
      .catch((err) => message.error(err.message));
    console.log(email);
  };
  return (
    <div>
      <div
        style={{
          backgroundColor: headerColor,
          height: "4.7rem",
          position: "relative",

          boxShadow: "3px 6px 8px rgba(0, 0, 0, 0.03)",
        }}
      >
        <Row justify="start">
          <Col
            xxl={{ span: 6, offset: 1 }}
            xl={{ span: 6, offset: 1 }}
            lg={{ span: 6, offset: 1 }}
            md={24}
          >
            <Row justify="start">
              <img
                style={{
                  width: "160px",
                  height: "65px",
                  objectFit:"contain",
                  marginRight: "2.1rem",
                  marginTop: "5px",
                }}
                src={headerLogo}
                alt="logo"
              />
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ width: "75rem", margin: "10rem " }}>
        <PasswordHandleComponent
          forgotPassword={forgotPassword}
        ></PasswordHandleComponent>
      </div>
    </div>
  );
}
export default ForgotPassword;
