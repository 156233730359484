import { useState, useEffect, useMemo } from "react";
import {
  Menu,
  Radio,
  Typography,
  Button,
  message,
  Select,
  Tag,
  Input,
  InputNumber,
  Form,
} from "antd";
import { DownCircleTwoTone } from "@ant-design/icons";
import AddOrEditPatient from "../../shared/modals/AddOrEditPatient";
import "../../../assets/styles/components/patientDetailsStyle.scss";
import { ReactComponent as CalendarIcon } from "../../../assets/images/calendar_icon.svg";
import { ReactComponent as SmartphoneIcon } from "../../../assets/images/smartphone_icon.svg";
import { ReactComponent as AddressIcon } from "../../../assets/images/address_icon.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/user_icon.svg";
import { ReactComponent as EmailIcon } from "../../../assets/images/envelope.svg";
import ConfirmationModal from "../../../components/shared/modals/confirmationModal";
import moment from "moment";
import {
  updatePatientTreatmentData,
  updatePaymentStatusApi,
  updatePatEmailApi,
  checkPatientEmail,
  resendRegistrationEmail,
} from "api/patient";
import { PATIENTCONSTANTS, getTreatmentPlanStatus } from "../../../utilities/patientDetail";
import InsuranceModal from "components/shared/modals/InsuranceModal";
import { primaryColor } from "colors-config";
import { JOURNEY_STATUS } from "../../../constants";
import { getClinicProductPrice } from "api/clinic";
import { ReactComponent as EditIcon } from "assets/images/edit_icon.svg";
import dayjs from "dayjs";
import SendAppLinkModal from "components/shared/modals/sendAppLinkModal";

const { Title } = Typography;
const { Option } = Select;

const ImpressionDecision = {
  Rejected: 0,
  Approved: 1,
  Received: 2,
};

const ImpressionDecisionText = {
  Rejected: "Impressions rejected",
  Approved: "Impressions Approved",
  Received: "Impression image received",
};

const PatientDetailsComponent = ({
  patientInfo,
  getPatientInfo,
  dropdownValues,
  status,
  updateTreatmentOrigin,
  treatmentProgressDet,
  clinic,
  getOrders,
  setPaymentStatus,
  paymentStatus,
  paidStatus,
  setPaidStatus,
  productList,
  patientId,
  patientOrders,
  wholeTreatmentPlan,
  sendAppStage,
  setPatientInfoState,
  labOrders,
}) => {
  const [buttonValue, setButtonValue] = useState("a");
  const [activeTreatmentOrigin, setActiveTreatmentOrigin] = useState("HIK Impressions");
  const [showPopup, setShowPopup] = useState(false);
  const [activeAlignerOrigin, setactiveAlignerOrigin] = useState("Aligner 1/6");
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [showPaymentLoading, setShowPaymentLoading] = useState(false);

  const [insuranceModal, setInsuranceModal] = useState(false);
  const [checkCompleteTreatment, setCheckCompleteTreatment] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [treatmentEditLoading, setTreatmentEditLoading] = useState(false);
  // const [firstTimeName, setFirstTimeName] = useState(false);
  const [approvedTreatment, setApprovedTreatment] = useState();
  const [labOrderDetails, setLabOrderDetails] = useState();

  const [treatmentData, setTreatmentData] = useState({
    treatmentName: "-",
    archesTreated: "-",
    netPrice: 0,
    discount: 0,
    total: 0,
    currencyName: "-",
    discountAmount: 0,
    greyText: "",
  });

  const [isModalSendVisible, setIsModalSendVisible] = useState(false);

  const onIconCLick = () => {
    setShowPatientEmailModal(true);
    setIsModalSendVisible(true);
  };

  useEffect(() => {
    if (wholeTreatmentPlan?.length) {
      const approvedTreatment = wholeTreatmentPlan?.find((obj) => obj.approved) || false;

      setApprovedTreatment(approvedTreatment ? approvedTreatment : wholeTreatmentPlan[0]);
    }
  }, [wholeTreatmentPlan]);

  useEffect(() => {
    if (treatmentProgressDet?.prescriptionId && !patientOrders.length) {
      setShowEditButton(true);
    } else {
      setShowEditButton(false);
    }
  }, [patientOrders]);
  useEffect(() => {
    if (labOrders && labOrders.length) {
      setLabOrderDetails(labOrders[0]);
    }
  }, [labOrders]);

  useEffect(() => {
    if (treatmentProgressDet) {
      let paymentStatus = "-";
      if (treatmentProgressDet?.payment_status) {
        paymentStatus = treatmentProgressDet.payment_status;
      } else if (patientInfo?.payment_status) {
        paymentStatus = patientInfo.payment_status;
      } else {
        paymentStatus = "-";
      }
      if (
        patientInfo?.payment_status == "Free" ||
        patientInfo?.payment_status == "Partially Paid"
      ) {
        paymentStatus = patientInfo.payment_status;
      }

      setPaidStatus(paymentStatus);
      setPaymentStatus(paymentStatus);

      const tempTreatmentData = {};
      if (treatmentProgressDet?.treatment_name) {
        tempTreatmentData.treatmentName = treatmentProgressDet?.treatment_name;
      }
      if (treatmentProgressDet?.arches_treated) {
        tempTreatmentData.archesTreated = treatmentProgressDet?.arches_treated;
      }
      if (typeof Number(treatmentProgressDet?.treatment_price)) {
        tempTreatmentData.netPrice = Number(treatmentProgressDet.treatment_price);
        tempTreatmentData.total = Number(treatmentProgressDet?.treatment_price);
        tempTreatmentData.discount = 0;
        tempTreatmentData.discountAmount = 0;
      }
      if (treatmentProgressDet?.currency_name) {
        tempTreatmentData.currencyName = treatmentProgressDet?.currency_name;
      }

      if (typeof Number(treatmentProgressDet?.discount)) {
        tempTreatmentData.discount = getDiscountPercentage(
          treatmentProgressDet.treatment_price,
          treatmentProgressDet.discount
        );
        tempTreatmentData.discountAmount = treatmentProgressDet.discount;
        tempTreatmentData.total =
          Number(treatmentProgressDet.treatment_price) !== 0
            ? Number(treatmentProgressDet.treatment_price) - treatmentProgressDet.discount
            : 0;
      }

      setTreatmentData(tempTreatmentData);
    }
  }, [patientInfo, treatmentProgressDet]);

  const calculateTotalPrice = (price, discount) => {
    const numVal1 = Number(price);
    const numVal2 = Number(discount);

    const totalValue = numVal1 * ((100 - numVal2) / 100);
    return Math.ceil(totalValue);
  };
  useEffect(() => {
    if (status) {
      if (status.patient_treatment_origin_name) {
        setActiveTreatmentOrigin(status.patient_treatment_origin_name);
      }
      if (status.clinic_patient_journey_status_name == JOURNEY_STATUS.TREATMENT_COMPLETED) {
        setCheckCompleteTreatment(true);
      } else {
        setCheckCompleteTreatment(false);
      }
    }
  }, [status]);

  const companyType = clinic?.companyType ? clinic.companyType : 1;

  const onButtonClick = (e) => {
    setButtonValue(e.target.value);
  };
  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          if (dropdownValues?.data[0].treatment_origin_name) {
            setActiveTreatmentOrigin(dropdownValues?.data[0].treatment_origin_name);
            updateTreatmentOrigin(dropdownValues?.data[0].treatment_origin_id);
          } else {
          }
        }}
        className="noselect"
      >
        {dropdownValues?.data[0].treatment_origin_name
          ? dropdownValues.data[0].treatment_origin_name
          : "-"}
      </Menu.Item>
      <Menu.Item
        key="1"
        className="noselect"
        onClick={() => {
          if (dropdownValues?.data[1].treatment_origin_name) {
            setActiveTreatmentOrigin(dropdownValues?.data[1].treatment_origin_name);
            updateTreatmentOrigin(dropdownValues?.data[1].treatment_origin_id);
          } else {
          }
        }}
      >
        {dropdownValues?.data[1].treatment_origin_name
          ? dropdownValues.data[1].treatment_origin_name
          : "-"}
      </Menu.Item>
      <Menu.Item
        key="3"
        className="noselect"
        onClick={() => {
          if (dropdownValues?.data[2].treatment_origin_name) {
            setActiveTreatmentOrigin(dropdownValues?.data[2].treatment_origin_name);
            updateTreatmentOrigin(dropdownValues?.data[2].treatment_origin_id);
          }
        }}
      >
        {dropdownValues?.data[2].treatment_origin_name
          ? dropdownValues.data[2].treatment_origin_name
          : "-"}
      </Menu.Item>
    </Menu>
  );

  const patientAddress = useMemo(() => {
    if (patientInfo) {
      const address = `${patientInfo?.patient_address || ""}${
        patientInfo?.patient_address && patientInfo?.patient_city ? "," : ""
      } ${patientInfo?.patient_city || ""}${
        patientInfo?.patient_county && patientInfo?.patient_city ? "," : ""
      } ${patientInfo?.patient_county || ""}${
        patientInfo?.patient_county && patientInfo?.patient_postal_code ? "," : ""
      } ${patientInfo?.patient_postal_code || ""}${
        patientInfo?.patient_country && patientInfo?.patient_postal_code ? "," : ""
      } ${patientInfo?.patient_country || ""}`;
      if (address === "" || !address.replace(/\s/g, "").length) return "-";
      return address;
    }
    return "-";
  }, [patientInfo]);

  const alignerMenu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setactiveAlignerOrigin("Aligner 2/6");
        }}
        className="noselect"
      >
        Aligner 2/6
      </Menu.Item>
      <Menu.Item
        key="1"
        className="noselect"
        onClick={() => {
          setactiveAlignerOrigin("Aligner 3/6");
        }}
      >
        Aligner 3/6
      </Menu.Item>
      <Menu.Item
        key="3"
        className="noselect"
        onClick={() => {
          setactiveAlignerOrigin("Aligner 4/6");
        }}
      >
        Aligner 4/6
      </Menu.Item>
    </Menu>
  );

  const handlePaymentStatusChange = (value) => {
    if (value == "-" || value == "Refunded") {
      value = "";
    }
    setPaymentStatus(value);
    setShowPaymentModal(true);
  };

  const cancelPaymentStatus = () => {
    setPaymentStatus(paidStatus);
  };

  const updatePaymentStatus = async () => {
    try {
      setShowPaymentLoading(true);

      let value = paymentStatus;

      const data = {
        patientId: patientInfo.patient_id,
        status: value,
      };

      await updatePaymentStatusApi(data);
      if (value) {
        getOrders();
      }
      message.success("Payment status updated");
      if (value == "") {
        setPaidStatus("-");
        setPaymentStatus("-");
      }
      setPaidStatus(value);
    } catch (err) {
      message.error("Some error occured!");
    } finally {
      setShowPaymentLoading(false);
    }
  };

  const onInsuranceModalOpen = () => {
    setInsuranceModal(true);
  };
  const onInsuranceModalClose = () => {
    setInsuranceModal(false);
  };
  const onInsuranceModalOk = () => {
    setInsuranceModal(false);
  };

  const onPatientTreatmentOpen = () => {
    setEditOpen(true);
  };

  const onPatientTreatmentClose = () => {
    setEditOpen(false);
  };

  const onPatientTreatmentSaveClick = async () => {
    try {
      setTreatmentEditLoading(true);
      if (treatmentProgressDet?.prescriptionId) {
        const inputData = {
          clinicId: clinic.clinicId,
          patientId: patientId,
          netPrice: treatmentData.netPrice,
          discount: treatmentData.discountAmount,
          archesTreated: treatmentData.archesTreated,
          treatmentName: treatmentData.treatmentName,
        };
        await updatePatientTreatmentData(treatmentProgressDet?.prescriptionId, inputData);
        message.success("Treatment updated successfully");
        onPatientTreatmentClose();
      }
    } catch (error) {
      message.error("Error occured");
    } finally {
      setTreatmentEditLoading(false);
    }
  };

  const onTreatmentDataChange = async (value, key) => {
    setTreatmentData({
      ...treatmentData,
      [key]: value,
    });
  };

  const onAmountChange = async (value, key) => {
    if (key == "discount") {
      const totalValue = calculateTotalPrice(treatmentData.netPrice, value);
      const tempTreatmentData = { ...treatmentData };
      tempTreatmentData.total = totalValue;
      tempTreatmentData.discount = value;
      tempTreatmentData.discountAmount = treatmentData.netPrice - totalValue;
      setTreatmentData(tempTreatmentData);
    }
    if (key == "discountAmount") {
      const tempTreatmentData = { ...treatmentData };
      tempTreatmentData.total = treatmentData.netPrice - value;
      tempTreatmentData.discount = getDiscountPercentage(treatmentData.netPrice, value);
      tempTreatmentData.discountAmount = value;
      setTreatmentData(tempTreatmentData);
    }
  };

  const getDiscountPercentage = (netPrice, value) => {
    const total = Number(netPrice) - Number(value);
    const diffPercentage = (total / netPrice) * 100;
    const actualPercentage = Number(100 - diffPercentage).toFixed(1);
    return actualPercentage;
  };

  const onTreatmentNameChange = async (value) => {
    const productPriceRes = await getClinicProductPrice({
      productName: value,
      clinicId: clinic.clinicId,
    });
    if (productPriceRes?.data) {

      const netPrice = productPriceRes.data.price;
      const discountAmount = productPriceRes?.data.free
        ? productPriceRes.data.price
        : productPriceRes.data.discount;
      const totalValue = netPrice - discountAmount;

      const tempTreatmentData = { ...treatmentData };
      tempTreatmentData.total = totalValue;
      tempTreatmentData.netPrice = netPrice;
      tempTreatmentData.discount = getDiscountPercentage(
        productPriceRes.data.price,
        discountAmount
      );
      tempTreatmentData.discountAmount = discountAmount;
      tempTreatmentData.treatmentName = value;

      setTreatmentData(tempTreatmentData);
    }
  };
  const [editPatientEmailForm] = Form.useForm();
  const [showPatientEmailModal, setShowPatientEmailModal] = useState(false);

  const onEditEmailPatient = async () => {
    try {
      const res = await editPatientEmailForm.validateFields();
      const obj = {
        patient_id: patientId,
      };

      obj.email = res.email;
      const checkPatientDetails = {
        email: res.email,
      };

      if (patientInfo.patient_email != res.email) {
        const checkPatientResponse = await checkPatientEmail(checkPatientDetails);
        if (checkPatientResponse?.data?.data?.emailExists) {
          throw new Error("Patient Email already Exists. Assign new Email");
        }
      }

      await resendRegistrationEmail(obj);
      message.success("Email has been sent successfully.");

      setShowPatientEmailModal(false);
      setIsModalSendVisible(false);
      setPatientInfoState({
        ...patientInfo,
        patient_email: res.email,
      });
    } catch (error) {
      message.error(error?.values ? "Please enter patient email" : error.message);
    }
  };

  const handleAppLinkOk = () => {
    onEditEmailPatient();
  };
  const handleAppLinkCancel = () => {
    setIsModalSendVisible(false);
    setShowPatientEmailModal(false);
  };
  return (
    <div
      style={{ width: "100%" }}
      className={`${companyType == 3 ? "smiyle-nation-container" : ""}`}
    >
      {showPatientEmailModal && (
        <div className="send-app-confirmation-modal">
          <SendAppLinkModal
            title="Send App Registration"
            isModalVisible={isModalSendVisible}
            setIsModalSendVisible={setIsModalSendVisible}
            handleOk={handleAppLinkOk}
            handleCancel={handleAppLinkCancel}
            content={
              <Form
                form={editPatientEmailForm}
                name="sendPatientEmail"
                initialValues={{
                  email: patientInfo.patient_email,
                }}
                layout="vertical"
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please enter patient email",
                    },
                  ]}
                  name="email"
                  label="Email"
                >
                  <Input />
                </Form.Item>
              </Form>
            }
            showModal={showPatientEmailModal}
            onConfirmation={onEditEmailPatient}
            setShow={setShowPatientEmailModal}
            onCancel={() => setShowPatientEmailModal(false)}
          />
        </div>
      )}
      {showPopup && (
        <AddOrEditPatient
          title={
            <div className="titleSpan">
              <img
                src={require("../../../assets/images/editUser.png")}
                style={{ width: "30px" }}
                alt=""
              />
              <Title level={5} style={{ margin: "0px" }}>
                EDIT PATIENT
              </Title>
            </div>
          }
          IsEdit={true}
          content={patientInfo}
          showModal={showPopup}
          onConfirmation={getPatientInfo}
          setShow={setShowPopup}
        />
      )}
      {showPaymentModal && (
        <ConfirmationModal
          title="Payment"
          content={"Are you sure you want to change the payment status?"}
          showModal={showPaymentModal}
          onConfirmation={updatePaymentStatus}
          setShow={setShowPaymentModal}
          onCancel={cancelPaymentStatus}
        ></ConfirmationModal>
      )}
      {insuranceModal && (
        <InsuranceModal
          insuranceModal={insuranceModal}
          onInsuranceModalOpen={onInsuranceModalOpen}
          onInsuranceModalClose={onInsuranceModalClose}
          onInsuranceModalOk={onInsuranceModalOk}
          patientInfo={patientInfo}
        />
      )}
      {/* {showRefundModal && (
            <ConfirmationModal
              title="Refund"
              content={"Are you sure you want to refund?"}
              showModal={showRefundModal}
              onConfirmation={onRefundPaymentConfirm}
              setShow={setShowRefundModal}
            ></ConfirmationModal>
          )}
           {showShowAwaitingModal && (
            <ConfirmationModal
              title="Awaiting Payment"
              content={"Are you sure you want to update the status?"}
              showModal={showShowAwaitingModal}
              onConfirmation={onAwaitingPaymentConfirm}
              setShow={setShowAwaitingModal}
            ></ConfirmationModal>
          )} */}
      <div
        className={
          buttonValue == "a"
            ? "patient-detail-container"
            : "patient-detail-container-patientInfo"
        }
      >
        <div className="patient-detail-topRow">
          <div className="patient-detail-topRow-profile">
            <div className="profile-icon">
              <UserIcon className="user-icon-image" alt="Edit" />
            </div>
            <div className="patient-name">
              <div
                className={`subheading ${
                  process.env.REACT_APP_COMPANY_TYPE == 5
                    ? "subheading-smilealign"
                    : ""
                }`}
                onClick={() => {
                  setShowPopup(!showPopup);
                }}
              >
                {patientInfo?.patient_first_name
                  ? patientInfo.patient_first_name
                  : "-"}{" "}
                {patientInfo?.patient_last_name
                  ? patientInfo.patient_last_name
                  : "-"}
                {
                  <EditIcon
                    className="hoverIcon"
                    style={{
                      width: "14px",
                      marginLeft: "5px",
                      paddingTop: "3px",
                      cursor: "pointer",
                      marginTop: "-15px",
                      fill: primaryColor,
                    }}
                  />
                }
                {/* <img
                    src={require("../../../assets/images/edit.png")}
                    className="hoverIcon"
                    style={{
                      width: "14px",
                      marginLeft: "5px",
                      paddingTop: "3px",
                      cursor: "pointer",
                      marginTop: "-15px",
                    }}
                  /> */}
              </div>
              <div className="caption">
                {patientInfo?.patient_id ? patientInfo.patient_id : "-"}
              </div>
            </div>
          </div>
          <div className="patient-detail-topRow-switcher">
            <Radio.Group
              onChange={onButtonClick}
              defaultValue="a"
              buttonStyle="solid"
            >
              <Radio.Button className="noselect" value="a">
                Treatment Progress & Statuses
              </Radio.Button>
              <Radio.Button className="noselect" value="b">
                Patient info{" "}
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div className="patient-detail-bottomRow">
          <div
            className={
              buttonValue == "a" ? "patient-detail-bottomRow-itemOne" : "hidden"
            }
          >
            <div className="status-container">
              {showEditButton ? (
                <>
                  {" "}
                  {!editOpen ? (
                    <Button
                      type="link"
                      onClick={onPatientTreatmentOpen}
                      className="edit-icon"
                    >
                      <EditIcon
                        style={{
                          width: "14px",
                          marginLeft: "5px",
                          paddingTop: "3px",
                          cursor: "pointer",
                          marginTop: "-15px",
                          fill: primaryColor,
                        }}
                      />
                      {/* <img
                        src={require("../../../assets/images/edit.png")}
                        // className="hoverIcon"
                        style={{
                          width: "14px",
                          marginLeft: "5px",
                          paddingTop: "3px",
                          cursor: "pointer",
                          marginTop: "-15px",
                        }}
                      /> */}
                    </Button>
                  ) : (
                    <Button
                      onClick={onPatientTreatmentSaveClick}
                      loading={treatmentEditLoading}
                      type="primary"
                      shape="round"
                      className="edit-icon"
                    >
                      Save
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}

              <div
                className={"patient-detail-heading-container"}
                style={{ display: "flex" }}
              >
                <div className="status-heading">
                  Treatment Progress
                  <DownCircleTwoTone
                    twoToneColor={primaryColor}
                    style={{
                      marginLeft: "1rem",
                      background: "#DDFAFF",
                      marginRight: "10px",
                    }}
                  />
                </div>
                {/* <Dropdown overlay={alignerMenu} trigger={["click"]}> */}

                <Tag
                  className={`aligner ${
                    clinic.companyType == 4 ? "boost-aligner" : ""
                  }`}
                  style={{ display: "flex" }}
                >
                  <div style={{ paddingRight: "5%" }}>
                    {process.env.REACT_APP_COMPANY_TYPE == 4
                      ? "Stage"
                      : "Aligner"}
                  </div>

                  <div>
                    {treatmentProgressDet?.current_aligner_number
                      ? treatmentProgressDet.current_aligner_number
                      : "-"}
                  </div>
                  <div>/</div>
                  <div>
                    {treatmentProgressDet?.total_aligners
                      ? treatmentProgressDet.total_aligners
                      : "-"}
                  </div>
                  {clinic.companyType == 4 ? (
                    <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                      {clinic.companyType == 4 &&
                      treatmentProgressDet?.total_aligners &&
                      treatmentProgressDet?.current_aligner_number % 2 == 0
                        ? "Boost"
                        : ""}
                    </div>
                  ) : null}
                </Tag>
                {/* <a
                    className="aligner noselect"
                    onClick={(e) => e.preventDefault()}
                    style={{ color: "color:#DDFAFF" }}
                  >
                    {activeAlignerOrigin}
                  </a> */}
                {/* </Dropdown> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "15px",
                  height: "30%",
                }}
              >
                <div
                  className="patient-info-cells"
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   borderRight: "2px solid #e9e9e9",
                  //   width: "25%",
                  // }}
                >
                  <div className="caption">Treatment Name</div>
                  <div className="content">
                    {editOpen ? (
                      <Select
                        value={treatmentData.treatmentName}
                        onChange={(value) => {
                          onTreatmentNameChange(value);
                        }}
                        name="treatmentName"
                        placeholder="Select Treatment"
                      >
                        {productList?.map((item) => {
                          return (
                            <Option value={item.product_name}>
                              {item.product_name}
                            </Option>
                          );
                        })}
                      </Select>
                    ) : treatmentData.treatmentName ? (
                      treatmentData.treatmentName
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div
                  className="patient-info-cells"
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   borderRight: "2px solid #e9e9e9",
                  //   width: "25%",
                  // }}
                >
                  <div className="caption">Arches Treated</div>
                  <div className="content">
                    {editOpen ? (
                      <Select
                        value={treatmentData.archesTreated}
                        name="archesTreated"
                        onChange={(value) => {
                          onTreatmentDataChange(value, "archesTreated");
                        }}
                        placeholder="Select Arches"
                      >
                        <Option value={PATIENTCONSTANTS.UPPER_AND_LOWER}>
                          Upper and Lower
                        </Option>
                        <Option value={PATIENTCONSTANTS.UPPER}>Upper</Option>
                        <Option value={PATIENTCONSTANTS.LOWER}>Lower</Option>
                      </Select>
                    ) : treatmentData.archesTreated ? (
                      treatmentData.archesTreated
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
                <div
                  className="patient-info-cells"
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   borderRight: "2px solid #e9e9e9",
                  //   width: "25%",
                  // }}
                >
                  <div className="caption">Price</div>
                  <div className="content">
                    {/* {console.log("treatmentData", treatmentData)} */}
                    {editOpen ? (
                      <Select
                        value={treatmentData.netPrice}
                        placeholder="Price"
                        popupClassName="netPrice-dropdown"
                        dropdownRender={(menu) => (
                          <div className="price-dropDown">
                            <div className="row-one">
                              <p>Price</p>
                              <Input
                                size="small"
                                type="number"
                                placeholder="Price"
                                value={treatmentData.netPrice}
                                // onChange={(event)=>{onTreatmentDataChange(event.target.value,"netPrice")}}
                                // onChange={onNameChange}
                                onKeyDown={(e) => e.stopPropagation()}
                                suffix={
                                  <b style={{ color: primaryColor }}>
                                    {treatmentData.currencyName}
                                  </b>
                                }
                              />
                            </div>

                            <div className="row-one">
                              <p>Discount</p>
                              <div className="row-two">
                                <InputNumber
                                  size="small"
                                  type="number"
                                  max={100}
                                  min={0}
                                  placeholder="Discount"
                                  className="discount-amount-input"
                                  suffix="%"
                                  controls={false}
                                  value={treatmentData.discount}
                                  onChange={(value) => {
                                    onAmountChange(value, "discount");
                                  }}
                                  // value={name}
                                  // onChange={onNameChange}
                                  onKeyDown={(e) => e.stopPropagation()}
                                />
                                <InputNumber
                                  size="small"
                                  type="number"
                                  className="discount-amount-input"
                                  controls={false}
                                  max={treatmentData.netPrice}
                                  min={0}
                                  placeholder="Amount"
                                  suffix={
                                    <b style={{ color: primaryColor }}>
                                      {treatmentData.currencyName}
                                    </b>
                                  }
                                  value={treatmentData.discountAmount}
                                  onChange={(value) => {
                                    onAmountChange(value, "discountAmount");
                                  }}
                                  // onChange={onNameChange}
                                  onKeyDown={(e) => e.stopPropagation()}
                                />
                              </div>
                            </div>
                            <div
                              className="price-footer"
                              style={{ background: primaryColor }}
                            >
                              <p>Total</p>
                              <b>
                                {treatmentData.currencyName}{" "}
                                {parseInt(treatmentData.netPrice) -
                                  parseInt(treatmentData.discountAmount)}
                              </b>
                            </div>
                          </div>
                        )}
                      />
                    ) : (
                      <>
                        <div style={{ paddingRight: "2%" }}>
                          {treatmentData?.currencyName
                            ? treatmentData.currencyName
                            : ""}{" "}
                          {/* {treatmentData?.total ? treatmentData.total : "-"} */}
                          {treatmentData?.total !== undefined
                            ? treatmentData.total
                            : "-"}
                        </div>
                        <div>
                          <p style={{ fontSize: "11px", color: "#798c96" }}>
                            {treatmentData.discount ? (
                              <span>
                                NET {treatmentData?.currencyName}{" "}
                                {treatmentData.netPrice}
                                <span
                                  style={{
                                    paddingLeft: 5,
                                    marginLeft: 5,
                                    borderLeft: "1px solid #798c96",
                                  }}
                                >
                                  Discount{" "}
                                  {parseInt(treatmentData.netPrice) -
                                    parseInt(treatmentData?.total)}
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="patient-info-cells"
                  style={{
                    borderRight: "none",
                  }}
                >
                  <div className="caption">Payment Status</div>
                  <div
                    className="content button-content"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      position: "relative",
                      marginBottom: "-10px",
                    }}
                  >
                    {treatmentProgressDet?.payment_status &&
                      treatmentProgressDet?.payment_status == "Paid" &&
                      paymentStatus != "Partially Paid" && (
                        <span
                          style={{
                            marginRight:
                              (treatmentProgressDet?.payment_status == "Paid" &&
                                paymentStatus != "Partially Paid") == "Paid"
                                ? "25px"
                                : "",
                          }}
                        >
                          {treatmentProgressDet.payment_status}
                        </span>
                      )}
                    {(!treatmentProgressDet?.payment_status ||
                      treatmentProgressDet.payment_status != "Paid" ||
                      paymentStatus == "Partially Paid") && (
                      <Select
                        size="large"
                        defaultValue="-"
                        value={paymentStatus}
                        loading={showPaymentLoading}
                        className="patient-payment-status-select"
                        onChange={handlePaymentStatusChange}
                        options={[
                          { value: "-", label: "-" },
                          {
                            value: "Awaiting Payment",
                            label: "Awaiting Payment",
                          },
                          { value: "Paid", label: "Paid" },
                          { value: "Cancelled", label: "Cancelled" },
                          { value: "Partially Paid", label: "Partially Paid" },
                          { value: "Refunded", label: "Refunded" },
                          { value: "Free", label: "Free" },
                        ]}
                      />
                    )}

                    {process.env.REACT_APP_COMPANY_TYPE != 4 &&
                      process.env.REACT_APP_COMPANY_TYPE != 5 && (
                        <Button
                          onClick={onInsuranceModalOpen}
                          type="primary"
                          className={`insurance-btn ${
                            (treatmentProgressDet?.payment_status == "Paid" &&
                              paymentStatus != "Partially Paid") == "Paid"
                              ? "paid-insurance"
                              : ""
                          }`}
                        >
                          Insurance
                        </Button>
                      )}
                  </div>
                </div>
              </div>
              <div
                className="bottom-bar"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "20%",
                  marginTop: "45px",
                }}
              >
                <div
                  className="patient-info-cells"
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   borderRight: "2px solid #e9e9e9",
                  //   width: "25%",
                  // }}
                >
                  <div className="caption">Time elapsed on current stage</div>
                  <div className="content">
                    <div>
                      {checkCompleteTreatment
                        ? "-"
                        : treatmentProgressDet?.time_elapsed_on_current_stage
                        ? treatmentProgressDet.time_elapsed_on_current_stage
                        : "-"}
                    </div>
                  </div>
                </div>
                <div
                  className="patient-info-cells"
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   borderRight: "2px solid #e9e9e9",
                  //   width: "25%",
                  // }}
                >
                  <div className="caption">Next Aligner change date</div>
                  <div className="content">
                    <div>
                      {checkCompleteTreatment
                        ? "-"
                        : treatmentProgressDet?.next_aligner_change_date
                        ? moment(
                            treatmentProgressDet.next_aligner_change_date
                          ).format("DD, MMM, YYYY")
                        : "-"}
                    </div>
                  </div>
                </div>
                <div
                  className="patient-info-cells"
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   borderRight: "2px solid #e9e9e9",
                  //   width: "25%",
                  // }}
                >
                  <div className="caption">Time elapsed on treatment</div>
                  <div className="content">
                    <div>
                      {treatmentProgressDet?.time_elapsed_on_treatment
                        ? treatmentProgressDet.time_elapsed_on_treatment
                        : "-"}
                    </div>
                  </div>
                </div>
                <div
                  className="patient-info-cells"
                  style={{
                    borderRight: "none",
                  }}
                >
                  <div className="caption">
                    {checkCompleteTreatment
                      ? "Date Completed"
                      : "Estimated completion date"}
                  </div>
                  <div className="content">
                    <div>
                      {treatmentProgressDet?.estimated_completion_date
                        ? moment(
                            treatmentProgressDet.estimated_completion_date
                          ).format("DD, MMM, YYYY")
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              buttonValue == "a" ? "patient-detail-bottomRow-itemTwo" : "hidden"
            }
          >
            <div className="status-container">
              {companyType == 3 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "35%",
                    marginTop: "25px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //  borderRight: "2px solid #e9e9e9",
                      width: "50%",
                    }}
                  >
                    <div className="caption">Impression Status</div>
                    <div className="content">
                      <div>
                        {patientInfo?.impression_decision ===
                          ImpressionDecision.Received &&
                          ImpressionDecisionText.Received}
                        {patientInfo?.impression_decision ===
                          ImpressionDecision.Approved &&
                          ImpressionDecisionText.Approved}
                        {patientInfo?.impression_decision ===
                          ImpressionDecision.Rejected &&
                          ImpressionDecisionText.Rejected}
                        {!patientInfo?.impression_decision &&
                          patientInfo?.impression_decision !== 0 &&
                          "Not Uploaded"}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: companyType != 3 ? "60px" : "0",
                  height: "35%",
                }}
              >
                <div
                  className="patient-info-cells"
                  style={{
                    width: "50%",
                  }}
                >
                  {" "}
                  <div className="caption">HIK Status</div>
                  <div className="content">
                    {activeTreatmentOrigin?.toLowerCase() === "pro impressions"
                      ? "-"
                      : status?.HIK_status
                      ? status.HIK_status
                      : "-"}
                  </div>
                </div>
                <div
                  className="patient-info-cells"
                  style={{
                    width: "50%",
                    borderRight: "none",
                  }}
                >
                  <div className="caption">Treatment Plan Status</div>
                  <div className="content">
                    {getTreatmentPlanStatus(
                      status?.patient_treatment_plan_status
                    )}
                  </div>

                  {approvedTreatment?.updatedAt ? (
                    <div className="treatment-date">
                      <span className="label">Status Updated on:</span>{" "}
                      <b>
                        {dayjs(approvedTreatment.updatedAt).format(
                          "DD-MM-YYYY"
                        )}
                      </b>
                    </div>
                  ) : null}

                  {labOrderDetails && labOrderDetails?.arrival_date ? (
                    <div className="treatment-date">
                      <span className="label">Est. Arrival Date:</span>{" "}
                      <b>
                        {dayjs(labOrderDetails.arrival_date).format(
                          "DD-MM-YYYY"
                        )}
                      </b>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "35%",
                  marginTop: "25px",
                }}
              >
                <div
                  className="patient-info-cells"
                  style={{
                    width: "50%",
                  }}
                >
                  <div className="caption">Journey Status</div>
                  <div className="content">
                    <div className="journey-status-value">
                      {status?.clinic_patient_journey_status_name
                        ? status.clinic_patient_journey_status_name
                        : "-"}
                    </div>
                  </div>

                  <div className="content">
                    <div className="create-date">
                      <span className="label">Treatment Requested Date: </span>
                      <span>{status?.tpRequestedData}</span>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div className={buttonValue == "b" ? "patient-info" : "hidden"}>
            <div
              style={{
                height: "80%",
                width: "200px",
                marginTop: "10px",
                marginLeft: "10px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginLeft: "-12px" }} className="caption">
                DOB
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "-16px",
                  marginTop: "5px",
                  gap: "5px",
                }}
              >
                <CalendarIcon stroke={primaryColor} />
                <div className="content" style={{ marginLeft: "-20px" }}>
                  {patientInfo?.patient_date_of_birth
                    ? moment(patientInfo.patient_date_of_birth).format(
                        "DD/MM/YYYY"
                      )
                    : "-"}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "80%",
                width: "200px",
                marginTop: "10px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginLeft: "-4px" }} className="caption">
                Mobile No
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "-9px",
                  marginTop: "5px",
                  gap: "5px",
                }}
              >
                <SmartphoneIcon stroke={primaryColor} />
                <div className="content" style={{ marginLeft: "-28px" }}>
                {patientInfo?.patient_country_code_name
                      ? `${patientInfo?.patient_country_code_name}-`
                      : ""
                    }
                  {patientInfo?.patient_phone_number
                    ? patientInfo.patient_phone_number
                    : "-"}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "80%",
                width: "350px",
                marginTop: "10px",
                borderRight: "2px solid #e9e9e9",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ marginLeft: "-4px" }} className="caption">
                Email Id
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "4px",
                  marginTop: "5px",
                  gap: "5px",
                }}
                className="editIconHover"
              >
                <EmailIcon
                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                  alt="email"
                  fill={primaryColor}
                />
                <div className="content" style={{ marginLeft: "-12px" }}>
                  {patientInfo?.patient_email ? patientInfo.patient_email : "-"}
                </div>
                <div>
                  {" "}
                  {patientInfo?.patient_email && sendAppStage == 0 ? (
                    <EditIcon
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                      fill={primaryColor}
                      className="editIcon "
                      onClick={onIconCLick}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "80%",
                width: "655px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="caption" style={{ textAlign: "left" }}>
                Address
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "-7px",
                  marginTop: "5px",
                  gap: "5px",
                }}
              >
                <AddressIcon fill={primaryColor} />
                <div
                  className="content"
                  style={{ marginLeft: "-25px", width: "100%" }}
                >
                  {patientAddress ? patientAddress : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetailsComponent;
