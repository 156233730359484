import { useState } from "react";
// import "antd/dist/antd.css";
// import "../../../../node_modules/antd/dist/antd.css";
import { Upload, message, Image } from "antd";
import { LoadingOutlined, InboxOutlined } from "@ant-design/icons";
import "../../../assets/styles/components/patient-journey/uploadImage.scss";
import { connect } from "react-redux";
import { dentistClinicApiTasks } from "../../../models/dentistClinic";
import axios from "axios";

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener("load", callback(reader.result));
//   reader.readAsDataURL(img);
// }

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG, JPEG and PNG files!");
  }
  const isLt2M = file.size / 1024 / 1024 < 20;
  if (!isLt2M) {
    message.error("Image must smaller than 20MB!");
  }
  return isJpgOrPng && isLt2M;
}

const UploadImage = ({
  patientDet,
  viewType,
  getAlignerUrls,
  confirmAlignerData,
  setConfirmAlignerData,
  caseId,
  selectedCaseId,
  treatmentId,
  stageId,
}) => {
  const [imageUrl, setImageUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [requestBody, setRequestBody] = useState({
    patient_id: patientDet,
    patient_upload_stage: "preliminary",
    aligner_id: "100",
    lab_treatment_id: "",
  });
  const [uploadFileList, setUploadFileList] = useState();
  const [fileList, setFileList] = useState();
  const [status, setStatus] = useState(caseId && caseId !== 1 ? true : false);
  const [uploadFileDataObject, setUploadFileDataObject] = useState({});
  const [uploadMessage, setUploadMessage] = useState();
  async function uploadFiles(options) {
    const { onSuccess, onError, file, onProgress } = options;
    const fileNameArray = file.name.split(".");
    fileNameArray[0] = viewType + "_" + new Date().valueOf();

    const fileName = fileNameArray.join(".");

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": file.type,
      },

      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);

        if (percent === 100) {
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    try {
      const preSignRequestBody = {
        patient_id: patientDet,
        file_name: fileName,
        resource_type: "file",
        file_type:
          file.type.split("/")[0] === "image" && file.type.split("/")[1],
      };
      const preSignedResponse = await dentistClinicApiTasks.post(
        "patient/resource/add",
        preSignRequestBody
      );

      const accessUrl = preSignedResponse.body.success.accessUrl;
      const uploadUrl = preSignedResponse.body.success.uploadUrl;
      const uploadResponse = await axios.put(uploadUrl, file, config);
      const requestAlignerBody = {
        ...confirmAlignerData,
        file_url: accessUrl,
        caseId,
        notify:false
      };
      // console.log("Request Aligner Data", requestAlignerBody);

      const successFullUpload = await dentistClinicApiTasks.post(
        "clinic/patient/resource/upload/confirm",

        requestAlignerBody
      );
      if (successFullUpload.success) {
        setImageUrl(uploadUrl);

        // window.location.reload();
      }
      setUploadFileDataObject({
        ...uploadFileDataObject,
        [file.uid]: { name: fileName, fileUrl: accessUrl },
      });
      onSuccess("Ok");
    } catch (error) {
      console.log(error);

      onError({ error });
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  }
  const props = {
    name: "file",
    multiple: false,

    customRequest: uploadFiles,

    onChange(info) {
      let fileList = [...info.fileList];

      setUploadFileList([...info.fileList]);
      setFileList([...info.fileList]);
      const { status } = info.file;
      if (status !== "done") {
        setLoading(true);
        setUploadMessage("Uploading");
      }
      if (status === "done") {
        setLoading(false);
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploadMessage("");
        getAlignerUrls(stageId);
      } else if (status === "error") {
        setLoading(false);
        setUploadMessage("");
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const handleCancel = () => setPreviewVisible(false);
  const handleChange = (info) => {
    if (info.file.status !== "done") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      // Get this url from response in real world.
      //   getBase64(info.file.originFileObj, imageUrl);
    }
  };
  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <InboxOutlined style={{ fontSize: "1rem" }} />
      )}
      <div style={{ marginTop: 8, width: 120 }}>
        {uploadMessage
          ? uploadMessage
          : "Click or drag file to this area to upload"}
      </div>
    </div>
  );
  return (
    <div className="uploadImage">
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={beforeUpload}
        onChange={handleChange}
        // fileList={uploadFileList}
        disabled={selectedCaseId === caseId ? false : true}
        {...props}
      >
        {imageUrl ? (
          <Image src={imageUrl} alt="avatar" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
};
const mapStateToProps = (state) => ({
  clinic: state.clinic,
});
export default connect(mapStateToProps)(UploadImage);
