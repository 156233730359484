import { Input, AutoComplete, Divider } from "antd";
import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { searchPatientsApi } from "components/shared/modals/Appointment/api";

const SearchBar = ({ clinicId, onSelectCallback, enterButton }) => {
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSearchData = useCallback(() => {
    setLoading(true);
    searchPatientsApi(searchValue, clinicId)
      .then((res) => {
        setOptions((prev) => {
          return res.data.body.map((el) => {
            return {
              ...el,
              label: (
                <>
                  <div>
                    <h3 style={{ margin: "0", textTransform: "capitalize" }}>
                      {el.patient_first_name + " " + el.patient_last_name}
                    </h3>
                    <div>{el.patient_email}</div>
                    <div>{el.patient_phone_number}</div>
                  </div>
                  <Divider style={{ margin: "0" }} />
                </>
              ),
              value: el.patient_id,
            };
          });
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  },[searchValue,clinicId]);

  useEffect(() => {
    let delay = setTimeout(() => {
      if (searchValue) {
        getSearchData();
      }
    }, 1100);

    return () => clearTimeout(delay);
  }, [searchValue,getSearchData]);

  const onSelect = (value, option) => {
    onSelectCallback && onSelectCallback(option);
  };

  const onSearch = (value) => {
    setSearchValue(value.trim());
  };

  return (
    <AutoComplete
      filterOption={false}
      dropdownMatchSelectWidth={252}
      options={options}
      style={{ width: "100%" }}
      autoFocus
      onSelect={onSelect}
      onSearch={onSearch}
    >
      <Input.Search
        // size="large"
        loading={loading}
        placeholder="Search Patient ID, email, name, phone, DOB and address"
        enterButton={enterButton}
      />
    </AutoComplete>
  );
};
export default SearchBar;

SearchBar.propTypes = {
  setSelectedPatient: PropTypes.func.isRequired,
  clinicId: PropTypes.string.isRequired,
  enterButton: PropTypes.bool.isRequired,
};
