import { ReactComponent as CalendarOff } from "assets/images/CalendarOff.svg";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

import styles from "./off.module.scss";

const OffAvailability = ({ info, showCallback,callback }) => {
  const history = useHistory();
  return (
    <div className={styles["empty-container"]}>
      <CalendarOff />

      <h2>{info}</h2>
      {showCallback ?
        <Button
          type="primary"
          shape="round"
          size="large"

          onClick={() => {
            history.push("/clinic/profile");
            callback();
          }}
        >Turn on from settings</Button> : null
      }

    </div>
  );
};

export default OffAvailability;
