import { useState, useEffect } from "react";
import { dentistClinicApiTasks } from "../../models/dentistClinic";
import InvoiceListComponent from "../../components/private/invoiceListComponent";
import TitleComponent from "../../components/shared/titleComponent";
import { Row, Radio, message, Spin } from "antd";

import { connect } from "react-redux";
import { getAllLabOrders } from "api/clinic";
import { getPatientOrders } from "api/billing";

// const { Title } = Typography;

const ClinicInvoiceList = ({ clinic }) => {
  const [currentMenuSelected, setCurrentMenuSelected] = useState("patients");
  const [labInvoices, setLabInvoices] = useState([]);
  const [patientInvoices, setPatientInvoices] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(10);
  const [currentInvoiceList, setCurrentInvoiceList] = useState([]);
  const [totalPatientsOrders, setTotalPatientsOrders] = useState(0);
  const [totalLabOrders, setTotalLabOrders] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (clinic.clinicId) _getinvoiceList();
  }, [clinic.clinicId]);

  useEffect(() => {
    if (currentMenuSelected === "patients") {
      // _getPatientOrders();
      setCurrentInvoiceList(patientInvoices);
    } else {
      // _getLabOrders();
      setCurrentInvoiceList(labInvoices);
    }
  }, [patientInvoices, labInvoices]);

  useEffect(() => {
    if (clinic.clinicId && (searchKey.length >= 3 || searchKey === "")) {
      if (currentMenuSelected === "patients") {
        _getPatientOrders();
        // setCurrentInvoiceList(patientInvoices);
      } else {
        _getLabOrders();
        // setCurrentInvoiceList(labInvoices);
      }
    }
  }, [searchKey, paginationPage, paginationLimit, fromDate, endDate]);

  const _getinvoiceList = () => {
    _getPatientOrders();
    _getLabOrders();
  };

  const _getPatientOrders = async () => {
    setIsLoading(true);
    try {
      const patientOrderRes = await getPatientOrders(
        paginationLimit,
        paginationPage,
        clinic.clinicId,
        searchKey,
        fromDate,
        endDate
      );
      // const patientOrderRes = await dentistClinicApiTasks.getAll(
      //   "clinic/billing/patientOrders",
      //   {
      //     limit: paginationLimit,
      //     page: paginationPage,
      //     clinicId: clinic.clinicId,
      //     search: searchKey,
      //     startDate: fromDate,
      //     endDate: endDate,
      //   }
      // );

      const { body } = patientOrderRes.data;

      setPatientInvoices(body.data);
      setTotalPatientsOrders(body.matching_records);
    } catch (error) {
      message.error("Failed to load patients list");
    } finally {
      setIsLoading(false);
    }
  };
  const _getLabOrders = async () => {
    setIsLoading(true);
    try {
      // const labOrderRes = await dentistClinicApiTasks.getAll(
      //   "clinic/billing/labOrders",
      //   {
      //     limit: paginationLimit,
      //     page: paginationPage,
      //     clinicId: clinic.clinicId,
      //     search: searchKey,
      //     startDate: fromDate,
      //     endDate: endDate,
      //   }
      // );
      const labOrderRes = await getAllLabOrders({
        limit: paginationLimit,
        page: paginationPage,
        clinicId: clinic.clinicId,
        search: searchKey,
        startDate: fromDate,
        endDate: endDate,
      });
      console.log("labOrderRes, ", labOrderRes.body)
      setLabInvoices(labOrderRes.body.data);
      setTotalLabOrders(labOrderRes.body.total_invoices);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to load patients list");
    }
  };

  const onMenuChanged = (event) => {
    const selectedMenu = event.target.value;
    if (selectedMenu === "patients") {
      setCurrentInvoiceList(patientInvoices);
    } else if (selectedMenu === "labs") {
      setCurrentInvoiceList(labInvoices);
    }
    setSearchKey("");
    setCurrentMenuSelected(selectedMenu);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationPage(pagination.current);
    setPaginationLimit(pagination.pageSize);
    // if (filters && filters.journeyStatus.length > 0)
    //   setJourneyStatus(filters.journeyStatus);
  };

  const handleSearch = (value) => {
    setSearchKey(value);
    setPaginationPage(1);
  };

  const handleDateFilter = (confirm, start = "", end = "") => {
    setFromDate(start);
    setEndDate(end);
    setPaginationPage(1);
    confirm({ closeDropdown: true });
  };

  return (
    <div style={{ width: "100%", marginTop: "1rem", marginLeft: "1rem" }}>
      <Row>
        <TitleComponent titleText={"Invoices List"}></TitleComponent>
      </Row>
      <Row>
        <Radio.Group
          defaultValue={currentMenuSelected}
          buttonStyle="solid"
          style={{ height: "100%" }}
          onChange={onMenuChanged}

          //   onChange={onMenuChanged}
        >
          <Radio.Button
            className="patient-details-radio-buttons"
            value="patients"
          >
            Patients
          </Radio.Button>
          <Radio.Button className="patient-details-radio-buttons" value="labs">
            Labs
          </Radio.Button>
        </Radio.Group>
      </Row>
      <Spin spinning={isLoading} style={{ width: "100%" }}>
        <Row>
          {" "}
          <InvoiceListComponent
            paginationPage={paginationPage}
            paginationLimit={paginationLimit}
            invoiceList={currentInvoiceList}
            onPagination={handleTableChange}
            onSearch={handleSearch}
            handleDateFilter={handleDateFilter}
            currentMenuSelected={currentMenuSelected}
            totalOrders={
              currentMenuSelected === "patients"
                ? totalPatientsOrders
                : totalLabOrders
            }
          ></InvoiceListComponent>
        </Row>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(ClinicInvoiceList);
