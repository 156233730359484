import { Modal,Button } from "antd";
import PropTypes from "prop-types";

const ConfirmationModal = ({
  handleCancel,
  handleOk,
  children,
  title,
  confirmDisable,
  canCancelDisable,
  open,
  loader
}) => {
  return (
    <Modal
      footer={[
        <Button
          disabled={canCancelDisable}
          shape="round"
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          loading={loader}
          type="primary"
          shape="round"
          onClick={handleOk}
          disabled={confirmDisable}
        >
          Confirm
        </Button>,
      ]}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      title={title}
    >
      {children}
    </Modal>
  );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  confirmDisable: PropTypes.bool,
  canCancelDisable: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  loader:PropTypes.bool.isRequired,
};
