import {Button} from "antd"
import { primaryColor } from "colors-config";
import { ReactComponent as Pdf } from "assets/images/Pdf.svg";
import { DownloadOutlined } from "@ant-design/icons";
import "./treatmentFrame.scss"

const NotSupportedMimeTypes = [
  "application/x-zip-compressed",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/zip",
  "application/octet-stream",
  "message/rfc822",
];

const TreatmentFrame = ({ treatment }) => {
    // console.log("treatment",treatment?.treatment_media)
    const isSupportedType = NotSupportedMimeTypes.includes(
      treatment?.treatment_media?.meta?.mimeType
    );
    const isImage =
      treatment?.treatment_media?.meta?.mimeType?.startsWith("image/");
    const isPDF =
      treatment?.treatment_media?.meta?.mimeType === "application/pdf";
    const isVideo =
      treatment?.treatment_media?.meta?.mimeType?.startsWith("video/");
  
    const downloadHandler = (file) => {
      const link = document.createElement("a");
      link.href = file.url;
      link.download = file.name;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
      
  return (
    <div className="iframe-container">
       {!isSupportedType && treatment?.planType === "FILE" && (
        <Button
        type="primary"
        size="large"
         shape="circle"
          onClick={(e) => downloadHandler(treatment?.treatment_media)}
          className="down-btn"
          icon={<DownloadOutlined />}
        >
        </Button>
      )}
        {treatment?.planType === "FILE" ? (
          <div className="not-supported">
         {isSupportedType  && (
           <div
           onClick={(e) => downloadHandler(treatment?.treatment_media)}
         >
           <div className="fileImage">
               <Pdf />
             <div className="fileMask">
               <DownloadOutlined />
               <span> Download</span>
             </div>
           </div>
           <div className="resourceName">
             <p>{treatment?.treatment_media?.name}</p>
           </div>
         </div>
        )}

       {isVideo && (
            <video src={treatment?.treatment_media?.url} alt={treatment?.treatment_media?.name} controls/>
   
        )}

        {isPDF && (
          <iframe
            title="PDF Viewer"
            frameBorder="0"
            style={{ width: "100%", minHeight: "45rem", border: "none", overflow: "hidden" }}
            src={treatment?.treatment_media?.url}
          ></iframe>
        )}

        {isImage && (
         <img src={treatment?.treatment_media?.url} alt={treatment?.treatment_media?.name} style={{ width: '100%', height: 'auto' }} />
   
        )}
          </div>
        ) : (
            <div>
           <iframe
            title="treatmentPlan"
            className="iframe-image"
            src={treatment?.treatment_plan_link}
            style={{ border: `0.5px solid ${primaryColor}` }}
          />
            </div>
      
        )}

    </div>
  );
};

export default TreatmentFrame;
