import { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Typography,
  Spin,
  message,
  Col,
  Radio,
  Checkbox,
  Popconfirm,
  Divider,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { connect, useSelector } from "react-redux";
import { dentistClinicApiTasks } from "../../../models/dentistClinic";
import "../../../assets/styles/components/accountSettingsFormComponent.scss";
import { SupportToolTip, accountSettingFormValuesNormalizer } from "utilities/settings";
import {
  getCountryCodes,
  getCountries,
  getPatientCountryCodesList,
} from "services/redux/selectors/clinicSelectors";
import { Space } from "../../../../node_modules/antd/lib/index";
import { companyType } from "colors-config";
import { supportEmailToolTip, supportPhoneToolTip } from "messages/messages";
import { country } from "colors-config";
import { clinicStaffInitialMapper, clinicStaffValuesMapper } from "utilities/patientDetail";
import styles from "./ClinicianForm/clinician.module.scss";
import { addClinic } from "api/clinic";
import { FORM_LABELS } from "helper/constants/globalConstant";

const { Option } = Select;
const { Title } = Typography;

function AccountSettingsFormComponent({
  onSubmit,
  clinicDetails,
  mode,
  shouldSubmit,
  setShouldSubmit,
  getShowBRN,
  clinic,
  forPage,
  onBoardClinic,
  clinicStaff,
  setClinicStaff,
}) {
  const [form] = Form.useForm();
  // const countryCodes = useSelector(getCountryCodes);
  const countries = useSelector(getCountries);
  const countryCodes = useSelector(getPatientCountryCodesList);
  const [allCountryCodes, setAllCountruCodes] = useState([]);
  const [clinicAddresses, setClinicAddresses] = useState([]);

  const [isSpinning, setIsSpinning] = useState(false);
  const [isStripeButtonSpinning, setIsStripeButtonSpinning] = useState(false);
  const [showBRN, setShowBRN] = useState(false);
  const [disableSupportPhone, setDisableSupportPhone] = useState(true);
  const [disableSupportEmail, setDisableSupportEmail] = useState(true);
  const stripeWindowInstance = useRef(null);
  const onFinish = (clinicDetailsForm) => {
    const accountSettingFormValues = accountSettingFormValuesNormalizer(
      clinicDetailsForm,
      clinicAddresses
    );
    onSubmit(accountSettingFormValues);
    onNextButtonSubmit(clinicDetailsForm);
  };

  useEffect(() => {
    setAllCountruCodes(countryCodes);
  }, [countryCodes]);

  useEffect(() => {
    if (mode === "view" && !clinicDetails.clinicName && !clinic.clinicId) {
      setIsSpinning(true);
    }
    if (mode === "view") {
      if (clinicDetails.clinicName && clinic.clinicId && allCountryCodes.length > 0) {
        setIsSpinning(false);
        form.resetFields();
      } else if (!clinicDetails.clinicName && !clinic.isOnboarded) {
        setIsSpinning(false);
        form.resetFields();
      }
    }
  }, [clinicDetails, clinic.clinicId, clinic.isOnboarded]);

  useEffect(() => {
    if (shouldSubmit) {
      form.submit();
      setShouldSubmit(false);
    }
  }, [shouldSubmit]);

  useEffect(() => {
    let addressIds = [];
    const uniqueId = `(New)${uuidv4()}`;
    if (clinicDetails?.multipleAddresses?.length) {
      addressIds = clinicDetails?.multipleAddresses?.map((el) => {
        return {
          addressId: el.id,
        };
      });
    } else {
      addressIds = [
        {
          addressId: uniqueId,
        },
      ];
    }
    setClinicAddresses(addressIds);

    const formObject = {
      clinicName: clinicDetails?.clinicName ? clinicDetails.clinicName : onBoardClinic?.clinicName,
      adminName: clinicDetails?.adminName,
      phone: {
        number: clinicDetails?.phone,
        prefix: clinicDetails?.prefix,
      },
      email: clinicDetails?.email,
      businessRegistrationNo: clinicDetails?.businessRegistrationNo,
      bankAccountNo: clinicDetails?.bankAccountNo,
      country: clinicDetails?.country ? clinicDetails?.country : onBoardClinic?.country,
      city: clinicDetails?.clinicCity,
      supportEmail: clinicDetails?.supportEmail
        ? clinicDetails?.supportEmail
        : clinicDetails?.email,
      supportPhone: {
        supportNumber: clinicDetails?.supportNumber
          ? clinicDetails?.supportNumber
          : clinicDetails?.phone,
        supportPrefix: clinicDetails?.supportPrefix
          ? clinicDetails?.supportPrefix
          : clinicDetails?.prefix,
      },
      supportSameEmail: Boolean(clinicDetails.supportSameEmail),
      supportSamePhone: Boolean(clinicDetails.supportSamePhone),
      clinicians: clinicDetails?.clinicians,
    };

    addressIds?.forEach((el, index) => {
      let addressObj = {
        address: "",
        city: "",
        county: "",
        country: "",
        zipCode: "",
        default: 1,
        label: "",
      };

      if (clinicDetails?.multipleAddresses?.length) {
        addressObj = clinicDetails.multipleAddresses[index];
      }
      formObject[`address-${el.addressId}`] = addressObj.address;
      formObject[`city-${el.addressId}`] = addressObj.city;
      formObject[`county-${el.addressId}`] = addressObj.county;
      formObject[`country-${el.addressId}`] = addressObj.country
        ? addressObj.country
        : companyType === 5
        ? "United Kingdom"
        : clinicDetails.country
        ? clinicDetails.country
        : onBoardClinic?.country
        ? onBoardClinic?.country
        : null;
      formObject[`zipCode-${el.addressId}`] = addressObj.zipCode;
      formObject[`default-${el.addressId}`] = addressObj.default;
      formObject[`label-${el.addressId}`] = addressObj.label;
    });

    form.setFieldsValue(formObject);
    setDisableSupportPhone(Boolean(clinicDetails.supportSamePhone));
    setDisableSupportEmail(Boolean(clinicDetails.supportSameEmail));
  }, [clinicDetails, allCountryCodes, onBoardClinic]);

  const eventListener = () => {
    window.addEventListener("storage", (event) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === "stripeOnboarded") {
        if (stripeWindowInstance.current) {
          setTimeout(() => {
            stripeWindowInstance.current.close();
          }, [4000]);
        }
      }
    });
  };

  useEffect(() => {
    if (mode !== "view") {
      setIsSpinning(true);

      if (clinic.clinicId) {
        setIsSpinning(false);
        // form.resetFields();
      }
    }
    // eventListener();
  }, [clinic.clinicId]);

  const connectToStripe = async () => {
    setIsStripeButtonSpinning(true);
    try {
      const res = await dentistClinicApiTasks.getAll("clinic/stripe/onboarding", {
        clinicId: clinic.clinicId,
      });
      const url = res.body.url;
      const windowInstance = window.open(
        url,
        "_blank",
        "location=yes,height=570,width=1000,scrollbars=yes,status=yes"
      );

      stripeWindowInstance.current = windowInstance;
      setIsStripeButtonSpinning(false);
    } catch (error) {
      setIsStripeButtonSpinning(false);
      message.error("Please try again");
      console.log(error);
    }
  };

  // const stripeButton = (
  //   <Spin spinning={isStripeButtonSpinning}>
  //     <Button
  //       type="primary"
  //       style={{ borderRadius: "100px", marginTop: "30px" }}
  //       onClick={connectToStripe}
  //     >
  //       Connect to Stripe
  //     </Button>
  //   </Spin>
  // );

  const onNextButtonSubmit = async (accountSettingFormValues) => {
    const formData = accountSettingFormValuesNormalizer(accountSettingFormValues, clinicAddresses);
    const handleAddress = (addressDetail) => {
      try {
        const address = JSON.parse(addressDetail).description;
        return address;
      } catch (error) {
        return addressDetail;
      }
    };

    const model = {
      address: handleAddress(formData.address),
      details: "Details",
      name: formData.clinicName,
      admin: formData.adminName,
      regno: formData.businessRegistrationNo,
      email: formData.email,
      bankAccNo: formData.bankAccountNo,
      city: formData.city,
      county: formData.county,
      zipcode: formData.zipcode,
      countryCode: formData.prefix,
      phoneNumber: formData.phone,
      country: formData.country,
      multipleAddresses: formData.multipleAddresses,
      supportEmail: formData.supportEmail,
      supportPhone: formData.supportPhone,
      supportSameEmail: formData.supportSameEmail,
      supportSamePhone: formData.supportSamePhone,
      clinicians: formData.clinicians,
    };
    //console.log('model', model)
    // setClinicStaff(formData.clinicians)
    try {
      // console.log(model, "Hello form api");
      await addClinic(model);
      const onBoardingModel = {
        clinicName: formData.clinicName,
        clinicEmail: formData.email,
        country: formData.country,
      };
      await dentistClinicApiTasks.post("clinic/update/labClinicOnboarding", onBoardingModel);
      setShowBRN(true);
      getShowBRN && getShowBRN(true);
    } catch (error) {
      console.log("error", error);
      message.error(error?.response?.data?.message);
    }
  };

  const onCancel = () => {
    setShowBRN(false);
  };

  const onAddressDelete = (id) => {
    const value = form.getFieldValue(`addressDefault-${id}`);
    if (value) {
      return message.error("Default Address cannot be deleted");
    }

    setClinicAddresses((prev) => prev.filter((el) => el.addressId !== id));
  };

  const onDeleteClinicians = (ind) => {
    const currentValues = form.getFieldsValue();
    const checkDefault = currentValues?.clinicians?.find((val, i) => i === ind).default;
    // console.log('onDeleteClinicians',checkDefault)
    if (checkDefault) {
      return message.error("Default Clinician cannot be deleted");
    }
    const updatedClinicians = currentValues?.clinicians?.filter((el, i) => i !== ind);
    form.setFieldsValue({ clinicians: updatedClinicians });
  };

  const handleDefaultChange = (index) => {
    // Update the form values to ensure only one clinician is marked as default
    const currentValues = form.getFieldsValue();
    const updatedClinicians = currentValues.clinicians.map((clinician, i) => ({
      ...clinician,
      default: i === index,
    }));
    form.setFieldsValue({ clinicians: updatedClinicians });
  };

  const onAddAddress = () => {
    const newAddressId = `(New)${uuidv4()}`;
    setClinicAddresses((prev) => [
      ...prev,
      {
        addressId: newAddressId,
      },
    ]);
  };

  const onAddClinician = () => {
    const newClinician = { id: `New-${uuidv4()}`, clinicianName: "", default: false };
    const formValues = form.getFieldsValue();
    const currentClinicians = formValues?.clinicians;
    form.setFieldsValue({ clinicians: [...currentClinicians, newClinician] });
  };

  useEffect(() => {
    if (clinicAddresses.length) {
      const lastClinicAddress = clinicAddresses[clinicAddresses.length - 1];
      const addressId = lastClinicAddress.addressId;
      if (typeof addressId === "string" && addressId.includes("(New)")) {
        form.setFieldValue(`country-${addressId}`, country);
      }
    }
  }, [clinicAddresses]);

  useEffect(() => {
    if (clinicStaff.length) {
      const formattedInitialValues = clinicStaffValuesMapper(clinicStaff);
      // console.log('clinicStaff',formattedInitialValues)
      form.setFieldsValue(formattedInitialValues);
      // cliniciansForm
    } else {
      const initialValues = clinicStaffInitialMapper();
      form.setFieldsValue(initialValues);
    }
  }, [clinicStaff, form]);

  const onDefaultAddressChange = (e, addressId) => {
    if (clinicAddresses.length > 1) {
      const defaultAddressFormValues = {};
      clinicAddresses.forEach((el) => {
        if (el.addressId !== addressId) {
          defaultAddressFormValues[`default-${el.addressId}`] = false;
        }
      });

      form.setFieldsValue(defaultAddressFormValues);
    }
  };

  const onChangeSupportEmail = (e) => {
    const value = e.target.checked;
    if (value) {
      const clinicEmail = clinicDetails?.email;
      form.setFieldsValue({ supportEmail: clinicEmail });
    }
    setDisableSupportEmail(value);
  };

  const onChangeSupportPhone = (e) => {
    const value = e.target.checked;
    if (value) {
      const supportPhoneObj = {
        supportNumber: clinicDetails?.phone,
        supportPrefix: clinicDetails?.prefix,
      };
      form.setFieldsValue({ supportPhone: supportPhoneObj });
    }
    setDisableSupportPhone(value);
  };

  return (
    <div className="booking-form-container">
      <Spin spinning={isSpinning}>
        <div className="booking-form-content">
          <div className="booking-form-title-container ">
            <Row>
              <Title className="booking-form-title" level={5}>
                Profile
              </Title>
            </Row>
          </div>
          <Row>
            <Form
              style={{
                width: "100%",
              }}
              // preserve={false}
              form={form}
              name="complex-form"
              layout={"vertical"}
              disabled={mode === "view"}
              onFinish={onFinish}
            >
              <div className="booking-form">
                <Row gutter={[35, 0]}>
                  <Col md={12}>
                    <Form.Item
                      label={"Clinic Name"}
                      name="clinicName"
                      rules={[
                        { required: true, message: "Clinic name is required" },
                        {
                          max: 100,
                          message: "Clinic name cannot be more than 100 characters",
                          whitespace: true,
                        },
                      ]}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Input size={"large"} disabled={mode === "view"} placeholder="Clinic Name" />
                    </Form.Item>
                  </Col>
                  {/* <Col md={12}>
                  <Form.Item
                    label="Admin Name"
                    name="adminName"
                    rules={[
                      { required: true, message: "Admin name is required" },
                      {
                        max: 50,
                        message: "name cannot be more than 50 characters",
                        whitespace: true,
                      },
                    ]}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Input
                      size={"large"}
                      disabled={mode === "view"}
                      placeholder="Admin Name"
                    />
                  </Form.Item>
                </Col> */}
                </Row>
                <Row justify="end" gutter={[35, 0]}>
                  <Col lg={12}></Col>
                  <Col lg={12}></Col>
                </Row>
                <Row gutter={[35, 0]}>
                  <Col md={12}>
                    <Form.Item label="Phone Number">
                      <Space.Compact style={{ width: "100%" }}>
                        <div className="compact-select">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please select country code",
                              },
                            ]}
                            name={["phone", "prefix"]}
                            noStyle
                          >
                            <Select
                              size="large"
                              disabled={mode === "view"}
                              style={{
                                width: "110px",
                                borderRadius: "4px 0 0 4px",
                              }}
                              placeholder="code"
                              dropdownClassName="country-dropdown"
                              className="country-select"
                              labelRender={(props) => {
                                return props.label;
                              }}
                              options={allCountryCodes?.map((code, index) => {
                                return {
                                  ...code,
                                  key: index,
                                  value: code.countryCallingCodes,
                                  title: `${code.emoji} ${code.countryCallingCodes}`,
                                  label: code.countryCallingCodes,
                                };
                              })}
                              optionRender={(option) => {
                                const { data } = option;
                                return (
                                  <div
                                    style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}
                                  >
                                    <span className="emoji-font">{data.emoji}</span>
                                    <span>{data.countryCallingCodes}</span>
                                    <span>| {data.name}</span>
                                  </div>
                                );
                              }}
                              showSearch
                              filterOption={(input, option) => {
                                const lowerCasedInput = input.toLowerCase();
                                return (
                                  option.value.toLowerCase().includes(lowerCasedInput) ||
                                  option.name.toLowerCase().includes(lowerCasedInput)
                                );
                              }}
                            />
                          </Form.Item>
                        </div>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your phone number!",
                            },
                            () => ({
                              validator(_, value) {
                                const convertedValue = Number(value);
                                if (!isNaN(convertedValue)) {
                                  return Promise.resolve();
                                }

                                return Promise.reject("Invalid phone number");
                              },
                            }),
                            {
                              max: 10,
                              message: "Number must be a maximum of 10 digits",
                            },
                            {
                              min: 8,
                              message: "Invalid phone number",
                            },
                          ]}
                          name={["phone", "number"]}
                          noStyle
                        >
                          <Input
                            size={"large"}
                            style={{
                              width: "100%",
                              borderRadius: "0 4px 4px 0",
                            }}
                            placeholder="Phone Number"
                            disabled={mode === "view"}
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      label="Email Address"
                      name="email"
                      style={{
                        textAlign: "left",
                      }}
                      rules={[
                        {
                          type: "email",
                          message: "Invalid email address",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                    >
                      <Input size={"large"} disabled placeholder="Email Address" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[35, 0]}>
                  <Col md={12}>
                    <div className="support-row">
                      <SupportToolTip
                        title={supportPhoneToolTip}
                        label="Help & Support Phone Number"
                      />
                      <Form.Item name="supportSamePhone" valuePropName="checked">
                        <Checkbox disabled={mode === "view"} onChange={onChangeSupportPhone}>
                          <b>Use Same Phone Number</b>
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <Form.Item label="">
                      <Space.Compact style={{ width: "100%" }}>
                        <div className="compact-select">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please select country code",
                              },
                            ]}
                            name={["supportPhone", "supportPrefix"]}
                            noStyle
                          >
                            <Select
                              size="large"
                              disabled={mode === "view" || disableSupportPhone}
                              style={{
                                width: "110px",
                                borderRadius: "4px 0 0 4px",
                              }}
                              placeholder="code"
                              dropdownClassName="country-dropdown"
                              className="country-select"
                              labelRender={(props) => {
                                return props.label;
                              }}
                              options={allCountryCodes?.map((code, index) => {
                                return {
                                  ...code,
                                  key: index,
                                  value: code.countryCallingCodes,
                                  title: `${code.emoji} ${code.countryCallingCodes}`,
                                  label: code.countryCallingCodes,
                                };
                              })}
                              optionRender={(option) => {
                                const { data } = option;
                                return (
                                  <div
                                    style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}
                                  >
                                    <span className="emoji-font">{data.emoji}</span>
                                    <span>{data.countryCallingCodes}</span>
                                    <span>| {data.name}</span>
                                  </div>
                                );
                              }}
                              showSearch
                              filterOption={(input, option) => {
                                const lowerCasedInput = input.toLowerCase();
                                return (
                                  option.value.toLowerCase().includes(lowerCasedInput) ||
                                  option.name.toLowerCase().includes(lowerCasedInput)
                                );
                              }}
                            />
                          </Form.Item>
                        </div>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your phone number!",
                            },
                            () => ({
                              validator(_, value) {
                                const convertedValue = Number(value);
                                if (!isNaN(convertedValue)) {
                                  return Promise.resolve();
                                }

                                return Promise.reject("Invalid phone number");
                              },
                            }),
                            {
                              max: 10,
                              message: "Number must be a maximum of 10 digits",
                            },
                            {
                              min: 8,
                              message: "Invalid phone number",
                            },
                          ]}
                          name={["supportPhone", "supportNumber"]}
                          noStyle
                        >
                          <Input
                            size={"large"}
                            style={{
                              width: "100%",
                              borderRadius: "0 4px 4px 0",
                            }}
                            placeholder="Phone Number"
                            disabled={mode === "view" || disableSupportPhone}
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <div className="support-row">
                      <SupportToolTip
                        title={supportEmailToolTip}
                        label="Help & Support Email Address"
                      />
                      <Form.Item name="supportSameEmail" valuePropName="checked">
                        <Checkbox disabled={mode === "view"} onChange={onChangeSupportEmail}>
                          <b>Use Same Email Address</b>
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <Form.Item
                      label=""
                      name="supportEmail"
                      style={{
                        textAlign: "left",
                      }}
                      rules={[
                        {
                          type: "email",
                          message: "Invalid email address",
                        },
                      ]}
                    >
                      <Input
                        size={"large"}
                        disabled={mode === "view" || disableSupportEmail}
                        placeholder="Email Address"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {clinicAddresses?.map(({ addressId }, index) => {
                  return (
                    <div key={addressId}>
                      {index != 0 ? (
                        <hr
                          style={{
                            marginBottom: "15px",
                            borderTop: "1px solid #d7d7d7",
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <Row justify="space-between" gutter={[16, 0]}>
                        <Col md="4">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please enter address label",
                              },
                            ]}
                            label="Address Label/Nickname"
                            name={`label-${addressId}`}
                          >
                            <Input placeholder="Enter address label here" size="large" />
                          </Form.Item>
                        </Col>
                        <Col md={16}>
                          <Form.Item
                            name={`address-${addressId}`}
                            label="Address"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Address!",
                                validator(_, value) {
                                  if (value && value.trim().length !== 0) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject("Address should be valid!");
                                },
                              },

                              // () => ({
                              //   validator(_, value) {
                              //     if (value.trim().length !== 0 || !value) {
                              //       return Promise.resolve();
                              //     }

                              //     return Promise.reject(
                              //       "Address should be valid!"
                              //     );
                              //   },
                              // }),
                            ]}
                          >
                            <Input
                              size={"large"}
                              disabled={mode === "view"}
                              placeholder="Enter address here"
                            />
                          </Form.Item>
                        </Col>

                        <Col md="3">
                          <Form.Item
                            label="Default"
                            name={`default-${addressId}`}
                            valuePropName="checked"
                          >
                            <Radio onChange={(e) => onDefaultAddressChange(e, addressId)} />
                          </Form.Item>
                        </Col>
                        <Col md="1">
                          {clinicAddresses.length > 1 ? (
                            <div onClick={() => onAddressDelete(addressId)}>
                              <DeleteOutlined className="delete-icon" />
                            </div>
                          ) : null}
                        </Col>
                      </Row>

                      <Row gutter={[35, 0]}>
                        <Col md={12}>
                          <Form.Item
                            label="City"
                            name={`city-${addressId}`}
                            rules={[
                              { required: true, message: "City is required" },
                              {
                                max: 30,
                                message: "City name cannot be more than 30 characters",
                              },
                            ]}
                          >
                            <Input size={"large"} disabled={mode === "view"} placeholder="City" />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            label="County/Province/State"
                            name={`county-${addressId}`}
                            rules={[
                              {
                                required: true,
                                max: 30,
                                validator(_, value) {
                                  const regex =
                                    /^([a-zA-Z0-9 \u0080-\u024F]+(?:. |-| |'))*[a-zA-Z0-9 \u0080-\u024F]*$/;
                                  if (value && value.length >= 30) {
                                    return Promise.reject(
                                      "County/Province/State name cannot be more than 30 characters"
                                    );
                                  }
                                  if (!value) {
                                    return Promise.reject("County/Province/State name is required");
                                  }
                                  if (regex.test(value)) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject("Invalid county name");
                                  }
                                },
                              },
                            ]}
                            // rules={[
                            //   {
                            //     required: companyType === 5,
                            //     max: 30,
                            //     message:
                            //       "County/Province/State name is required",
                            //   },
                            //   () => ({
                            //     validator(_, value) {
                            //       var regex =
                            //         /^([a-zA-Z0-9 \u0080-\u024F]+(?:. |-| |'))*[a-zA-Z0-9 \u0080-\u024F]*$/;
                            //         if(value.length >=30){
                            //           return Promise.reject(
                            //             "County/Province/State name cannot be more than 30 characters"
                            //           );
                            //         }
                            //       if (!value) {
                            //         return Promise.resolve();
                            //       } else {
                            //         if (regex.test(value)) {
                            //           return Promise.resolve();
                            //         } else {
                            //           return Promise.reject(
                            //             "Invalid county name"
                            //           );
                            //         }
                            //       }
                            //     },
                            //   }),
                            // ]}
                          >
                            <Input
                              size={"large"}
                              disabled={mode === "view"}
                              placeholder="County/Province/State"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={[35, 0]}>
                        <Col md={12}>
                          <Form.Item
                            label={FORM_LABELS.POST_CODE}
                            name={`zipCode-${addressId}`}
                            rules={[
                              {
                                required: companyType === 5,
                                message: `${FORM_LABELS.POST_CODE} is required`,
                              },
                            ]}
                          >
                            <Input size={"large"} disabled={mode === "view"} placeholder="Code" />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          {" "}
                          <Form.Item
                            label="Country"
                            name={`country-${addressId}`}
                            rules={[
                              { required: true, message: "Country is required" },
                              {
                                max: 30,
                                message: "Country name cannot be more than 30 characters",
                              },
                            ]}
                          >
                            <Select
                              disabled={mode === "view"}
                              showSearch
                              size="large"
                              // value={"India"}
                              placeholder="Country"
                              defaultActiveFirstOption={false}
                              // showArrow={false}
                              // optionFilterProp="children"
                              notFoundContent={null}
                              style={{ width: "100%", textAlign: "left" }}
                              options={allCountryCodes?.map((country, i) => {
                                return {
                                  ...country,
                                  value: country.name,
                                  title: country.name,
                                  label: (
                                    <>
                                      <span className="emoji-font">{country.emoji}</span>
                                      {` ${country.name}`}
                                    </>
                                  ),
                                  key: i,
                                };
                              })}
                              labelRender={(props) => {
                                return props.title;
                              }}
                              filterOption={(input, option) => {
                                const lowerCasedInput = input.toLowerCase();
                                return option.name.toLowerCase().includes(lowerCasedInput);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
                <Row>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    <Button shape="round" onClick={onAddAddress}>
                      Add Another Address
                    </Button>
                  </div>
                </Row>

                <div className={styles.clinicianFormContainer}>
                  <Divider />
                  <h2 className={styles.title}>Clinicians</h2>
                  <Row gutter={[16, 16]} align="middle">
                    <Col lg={2} offset={12}>
                      <p>Default</p>
                    </Col>
                  </Row>
                  <Form.List name="clinicians">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <div key={key}>
                            <Row gutter={[16, 16]} align="middle">
                              <Col lg={12}>
                                <Form.Item {...restField} name={[name, "id"]} hidden>
                                  <Input size="large" />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "clinicianName"]}
                                  label="Clinician's Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing Clinician name",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Clinician's Name" size="large" />
                                </Form.Item>
                              </Col>
                              <Col lg={1}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "default"]}
                                  valuePropName="checked"
                                  style={{
                                    marginTop: "25px",
                                  }}
                                >
                                  <Radio onChange={() => handleDefaultChange(index)} />
                                </Form.Item>
                              </Col>
                              {fields.length > 1 ? (
                                <Col lg={1}>
                                  <Popconfirm
                                    title="Delete Staff"
                                    description="Are you sure to remove this clinician?"
                                    onConfirm={() => {
                                      onDeleteClinicians(index);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Button
                                      className={styles.deleteBtn}
                                      // disabled={disabled}
                                      icon={<DeleteOutlined />}
                                    ></Button>
                                  </Popconfirm>
                                </Col>
                              ) : null}
                            </Row>
                          </div>
                        ))}
                        <Row gutter={[16, 16]} align="middle" justify="start">
                          <Col lg={12} align="end">
                            <Form.Item>
                              <Button
                                className={styles.addBtn}
                                onClick={() => {
                                  onAddClinician();
                                }}
                                shape="round"
                                // disabled={disabled}
                              >
                                Add another Clinician
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form.List>
                </div>
              </div>
            </Form>
          </Row>
        </div>
      </Spin>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  clinic: state.clinic,
});

export default connect(mapStateToProps)(AccountSettingsFormComponent);
