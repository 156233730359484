import api from "./api";
const baseUrl = process.env.REACT_APP_BASE_URL;

const getPatientResourceUploadStageAPI = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patient/getPatientResourceUploadStage`,
    params,
  }).then((res) => res.data);
};

const createStripePaymentIntentAPI = (data) => {
  return api({
    method: "POST",
    url: `/patient/stripe/createStripePaymentIntent`,
    data: data,
  }).then((res) => res.data);
};

const createClinicLabOrderAPI = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patientlist/patient/orders/placeOrder`,
    data: data,
  }).then((res) => res.data);
};

const createClinicPatientOrderAPI = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patientlist/requestOrder`,
    data: data,
  }).then((res) => res.data);
};

const getTreatmentPlanPrice = (patientId) => {
  return api({
    method: "GET",
    url: `/patient/treatmentSetup/price?patientId=${patientId}`,
  }).then((res) => res.data);
};

const downloadPatientInvoiceAPI = (type, id) => {
  return api({
    method: "GET",
    url: `/clinic/${type}/pdf?patientOrderId=${id}`,
  }).then((res) => res.data);
};

const getTreatmentOldAPI = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patientlist/treatmentSetup/getOld`,
    params: params,
  }).then((res) => res.data);
};

const setPatientTreatmentApproveAPI = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patient/treatment/approve`,
    data: data,
  }).then((res) => res.data);
};

const updatePaymentStatusApi = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patient/earlypayment`,
    data: data,
  }).then((res) => res.data);
};

const updatePatientOrderPaymentStatus = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patient/order/paymentStatusUpdate`,
    data: data,
  });
};

const downloadS3File = (fileName) => {
  return baseUrl + "/clinic/progress-image/download/" + fileName;
};

const addPatientNote = (data) => {
  return api({
    method: "POST",
    url: `/clinic/patientlist/note/add`,
    data: data,
  }).then((res) => res.data);
};

const getPatientNote = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patientlist/note/get`,
    params: params,
  }).then((res) => res.data);
};

const startTreatmentApi = (data) => {
  return api({
    method: "PUT",
    url: `/patient/updateAlignerOrderStatus`,
    data: data,
  });
};

const startRevisionTreatmentApi = (data) => {
  return api({
    method: "PUT",
    url: `/patient/startRevisionTreatment`,
    data: data,
  });
};

const completeTreatment = (data) => {
  return api({
    method: "POST",
    url: `/patient/treatment/complete`,
    data: data,
  });
};

const getPatientProgress = (params) => {
  return api({
    method: "GET",
    url: `/clinic/patientlist/treatmentProgress`,
    params: params,
  }).then((res) => res.data);
};

const updatePatientTreatmentData = (prescriptionId, data) => {
  return api({
    method: "POST",
    url: `/clinic/patient/updateTreatmentData/${prescriptionId}`,
    data: data,
  }).then((res) => res.data);
};

const resendRegistrationEmail = (data) => {
  return api({
    method: "POST",
    url: `/patient/resendRegistrationEmail`,
    data: data,
  });
};
const confirmAppointmentApi = (data) => {
  return api({
    method: "POST",
    url: `/patient/makeAppointmentClinic`,
    data: data,
  });
};

const getAppointmentDetailsApi = (params) => {
  return api({
    method: "GET",
    url: `/clinic/appointment/get/details`,
    params: params,
  });
};

const updatePatEmailApi = (data) => {
  return api({
    method: "POST",
    url: `/patient/updateEmail`,
    data: data,
  });
};

const checkPatientEmail = (params) => {
  return api({
    method: "GET",
    url: `/patient/checkPatientEmail`,
    params: params,
  });
};

const getPatientDetailsApi = (patientId) => {
  return api({
    method: "GET",
    url: `/clinic/patientlist/patientinfo?patientId=${patientId}`,
  });
};

const forgetPasswordPatientService = (data) => {
  return api({
    method: "POST",
    url: "/patient/auth/forgotPassword",
    data,
  })
}

const uploadScanFileAPI = (data) => {
  return api({
    method: "POST",
    url: "/patient/resource/add",
    data,
  });
};


const getPatientCountryCodesService = () => {
  return api({
    method: "GET",
    url: "/patient/getAllCountries",
  });
}

const confirmScanFileAPI = (data) => {
  return api({
    method: "POST",
    url: "/patient/resource/upload/confirm",
    data,
  });
};

const addPatientAddressApi = (data) => {
  return api({
    method: "PUT",
    url: "/patient/editPatientAddress",
    data,
  });
};

const shareWithPatientApi = (data) => {
  return api({
    method: "POST",
    url: "/clinic/treatmentSetup/shareWithPatient",
    data,
  });
};


export {
  getPatientResourceUploadStageAPI,
  createStripePaymentIntentAPI,
  downloadPatientInvoiceAPI,
  getTreatmentOldAPI,
  setPatientTreatmentApproveAPI,
  getTreatmentPlanPrice,
  updatePaymentStatusApi,
  createClinicLabOrderAPI,
  downloadS3File,
  addPatientNote,
  getPatientNote,
  startTreatmentApi,
  startRevisionTreatmentApi,
  completeTreatment,
  getPatientProgress,
  updatePatientTreatmentData,
  resendRegistrationEmail,
  confirmAppointmentApi,
  updatePatientOrderPaymentStatus,
  createClinicPatientOrderAPI,
  getAppointmentDetailsApi,
  updatePatEmailApi,
  checkPatientEmail,
  getPatientDetailsApi,
  forgetPasswordPatientService,
  getPatientCountryCodesService,
  uploadScanFileAPI,
  confirmScanFileAPI,
  addPatientAddressApi,
  shareWithPatientApi
};
