import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./textEditor.scss";

const TextEditor = ({
  editoInput,
  setEditorInput,
  image = true,
  hideToolbar = false,
  placeholder,
  disabled,
}) => {
  let modules = {
    toolbar: hideToolbar
      ? false
      : [
          [{ header: [1, 2, false] }],
          [{ list: "bullet" }, { list: "ordered" }, "bold", "italic", "underline"],
        ],
  };
  // if (image) {
  //   modules.toolbar[1].push("image");
  // }
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactQuill
        readOnly={disabled}
        theme="snow"
        placeholder={placeholder ? placeholder : ""}
        preserveWhitespace
        value={editoInput}
        onChange={(value) => setEditorInput(value)}
        modules={modules}
        className={disabled ? 'quill-readonly' : ''}
      />
    </div>
  );
};

export default TextEditor;
