import { returnErrors } from "./errorActions";
import axios from "axios";
import { dentistClinicApiTasks } from "../../../models/dentistClinic";
import {
  getClinicDetails,
  getClinicNotification,
  getProfileAndAvailability,
} from "api/clinic";
// import errorModal from "../../../components/shared/modals/errorModal";
import firebase from "firebase/app";

import { getFirebaseToken } from "../../../firebase";

import {
  AUTH_ERROR,
  CLINIC_LOADED,
  CLEAR_CLINIC,
  GET_JOURNEY_STATUS,
  NOTIFICATION_STATE_CHANGE,
  UPDATE_COMMENT_BOX_STATE,
  APPOINTMENT_CREATED,
  SAVE_COUNTRY_CODES,
  CLINIC_NOTIFICATION_LOADED,
  CLINIC_NOTIFICATION_READ,
  PATIENT_COUNTRY_CODES,
  CLINIC_AVAILABILITY,
} from "./types";
import { message } from "antd";
import { getPatientCountryCodesService } from "api/patient";

export const tokenExpired = () => async (dispatch) => {
  dispatch({
    type: AUTH_ERROR,
  });
};

export const fetchJourneyStatus = () => async (dispatch) => {
  try {
    let response = await dentistClinicApiTasks.getAll(
      "clinic/getClinicListOfValues",
      {
        pageName: "patientlist",
      }
    );
    await dispatch({
      type: GET_JOURNEY_STATUS,
      payload: response.body,
    });
  } catch (error) {
    console.log(error);

    message.error(error.message);
    await dispatch(returnErrors(error, error.code));
  }
};

// Check token & load clinic
export const loadClinic = () => async (dispatch, getState) => {
  try {
    const res = await getClinicDetails(getState().auth.user.attributes.email);

    if (firebase.messaging.isSupported()) {
      getFirebaseToken()
        .then(async (token) => {
          try {
            await dentistClinicApiTasks.post("deviceToken/add", {
              user_id: res?.data?.body?.clinic_id,
              device_token: token,
            });
            localStorage.setItem("device_token", token);
            await dispatch(loadClinicNotificationCount());
           await  dispatch(loadClinicAvailability());
            //saveDeviceToken(token)
          } catch (error) {
            console.log(error);
          }
        })
        .catch((err) => console.log("failed: ", err));
    }
    await dispatch({
      type: CLINIC_LOADED,
      payload: res?.data?.body,
    });
  } catch (error) {
    console.log(error);

    message.error(error?.response?.data?.error);
    await dispatch(returnErrors(error, error?.response?.status));
  }
};

export const clearClinic = () => async (dispatch, getState) => {
  await dispatch({
    type: CLEAR_CLINIC,
    payload: null,
  });
};

export const changeNotificationState =
  (isActive) => async (dispatch, getState) => {
    await dispatch({
      type: NOTIFICATION_STATE_CHANGE,
      payload: { isActive },
    });
  };

export const changeCommentBoxState = (payload) => async (dispatch) => {
  await dispatch({
    type: UPDATE_COMMENT_BOX_STATE,
    payload,
  });
};

export const newAppointmentCreated = (payload) => {
  return {
    type: APPOINTMENT_CREATED,
    payload,
  };
};

export const saveCountryAndCodes = (payload) => {
  return {
    type: SAVE_COUNTRY_CODES,
    payload,
  };
};

export const loadClinicNotificationCount = () => async (dispatch, getState) => {
  try {
    const clinicId = getState().clinic.clinicId;
    if (clinicId) {
      const getNotifications = await getClinicNotification({
        user_id: clinicId,
        limit: 1,
        page: 1,
        key: "ALL",
        secondaryFilter: "",
      });

      await dispatch({
        type: CLINIC_NOTIFICATION_LOADED,
        payload: getNotifications?.body?.unreadDataCount
          ? getNotifications?.body?.unreadDataCount
          : 0,
      });
    }
  } catch (error) {
    console.log(error);

    message.error(error?.response?.data?.error);
    await dispatch(returnErrors(error, error?.response?.status));
  }
};
export const loadClinicAvailability = () => async (dispatch, getState) => {
  try {
    const clinicId = getState().clinic.clinicId;
    if (clinicId) {
      const  response  = await getProfileAndAvailability({ clinicId: clinicId });
      await dispatch({
        type: CLINIC_AVAILABILITY,
        payload: response.body,
      });
    }
  } catch (error) {
    console.log(error);

    message.error(error?.response?.data?.error);
    await dispatch(returnErrors(error, error?.response?.status));
  }
};

export const getAllCountriesAndCodes = () => async (dispatch, getState) => {
  try {
    const { data } = await getPatientCountryCodesService();
    await dispatch({
      type: PATIENT_COUNTRY_CODES,
      payload: data?.data?.data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateReadCount = (count) => async (dispatch, getState) => {
  let notificationCount =
    count == 0 ? 0 : getState().clinic.notificationCount - 1;
  if (notificationCount < 1) {
    notificationCount = 0;
  }
  await dispatch({
    type: CLINIC_NOTIFICATION_READ,
    payload: notificationCount,
  });
};

export const addReadCount = () => async (dispatch, getState) => {
  let notificationCount = getState().clinic.notificationCount + 1;
  await dispatch({
    type: CLINIC_NOTIFICATION_READ,
    payload: notificationCount,
  });
};


