import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Row, Col, Typography, Button } from "antd";
// import logo from "../../assets/images/clear-x-logo-black.png";
// import logo from "../../assets/images/smile-genius-logo.png";
// import { ReactComponent as ClearX }  from "../../assets/images/clear-x.svg";
// import appointmentPng from "../../assets/images/appointment.png";
import { Menu, Avatar, Dropdown, Badge } from "antd";
import AddOrEditPatient from "../shared/modals/AddOrEditPatient";
import IframeModel from "../shared/modals/IframeModel";
import LogoutConfirmationModal from "../shared/modals/logoutConfirmationModal";

import { connect } from "react-redux";
import { logout } from "../../services/redux/actions/authActions";

import "../../assets/styles/components/headerComponent.scss";
import { Link, useHistory } from "react-router-dom";
import { dentistClinicApiTasks } from "../../models/dentistClinic";
import Appointment from "../shared/modals/Appointment/Appointment";

import {
  BellOutlined,
  UserOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  headerColor,
  headerButtonColor,
  headerButtonBackgroundColor,
  headerLogo,
  navBarTextColor,
  logoWidth,
  logoMarginLeft,
} from "colors-config";
import SearchBar from "components/shared/SearchBar/SearchBar";

const { SubMenu } = Menu;
const { Text, Title } = Typography;
function HeaderComponent({ logout, clinic }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const history = useHistory();
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [showPopupAddPatient, setShowPopupAddPatient] = useState(false);
  const [showPopupAddAppointment, setShowPopupAddAppointment] = useState(false);

  const [showLogoutConfirmationModel, setShowLogoutConfirmationModel] =
    useState(false);

  const [showBookingIframe, setShowBookingIframe] = useState(false);
  const handleMenuClick = (event) => {
    if (+event.key === 3) {
      setShowLogoutConfirmationModel(true);
    }
    if (+event.key === 2) {
      history.push("/changePassword");
    }
    // if (+event.key === 4) {
    //   history.push("/clinic/integrations");
    // }
  };

  useEffect(() => {
    // Route change detection
    setShowSearchBar(location.pathname !== "/clinic/patients");
  }, [location]);

  useEffect(() => {
    if (queryParams && queryParams.get("appointment") === "open") {
      setShowPopupAddAppointment(true);
    }
  }, []);

  const handleNewItem = (event) => {
    if (+event.key === 1) {
      setShowPopupAddPatient(true);
    }
    if (+event.key === 2) {
      setShowPopupAddAppointment(true);
    }
  };

  const signOut = async () => {
    //debugger
    // localStorage.removeItem("prescription_form_data_array");
    const deviceToken = localStorage.getItem("device_token");
    try {
      await dentistClinicApiTasks
        .remove("deviceToken/delete", {
          user_id: clinic?.clinicId,
          device_token: deviceToken,
        })
        .then(() => {
          console.log("Token removed or updated!");
          localStorage.removeItem("device_token");
          window.Intercom('shutdown');
        });
    } catch (error) {
      console.log("error while removing token", error);
    }
    logout();
  };

  const onExistingPatientClick = () => {
    setShowBookingIframe(false);
    setShowPopupAddAppointment(true);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{
        paddingTop: "0.5rem",
        marginTop: "1.2rem",
        width: "10rem",
      }}
      mode="horizontal"
    >
      <Menu.Item key={2}>Change Password</Menu.Item>
      {/* <Menu.Item key={4}>Integrations</Menu.Item> */}

      <Menu.Item key={3}>Logout</Menu.Item>
    </Menu>
  );
  const AddPatient = (
    <Menu
      onClick={handleNewItem}
      style={{
        paddingTop: "0.5rem",
        marginTop: "0.5rem",
        width: "10rem",
      }}
      mode="horizontal"
    >
      <Menu.Item className="item-patient" key={1} style={{ textAlign: "right" }}>
        <UserAddOutlined style={{ fontSize: "16px" }} /> Patient
      </Menu.Item>

      <Menu.Item key={2} style={{ textAlign: "right" }}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <img
            src={require("../../assets/images/appointment.png")}
            style={{ width: "14px" }}
            alt=""
          />
          Appointment
        </div>
      </Menu.Item>
    </Menu>
  );

  const onSelectCallback = (selectedPatient) => {
    console.log(selectedPatient);

    history.push(`/clinic/patient/${selectedPatient.patient_id}`);
    window.location.reload();
    // window.location = `/clinic/patient/${selectedPatient.patient_id}`
  };

  return (
    <div
      style={{
        backgroundColor: headerColor,
        height: "4rem",

        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        position: "fixed",
        width: "100%",
        left: 0,
        top: 0,
        right: 0,
        paddingBottom: "2rem",
        zIndex: 1000,
      }}
    >
      {showLogoutConfirmationModel && (
        <LogoutConfirmationModal
          title="Logout"
          showModal={showLogoutConfirmationModel}
          onConfirmation={signOut}
          setShow={setShowLogoutConfirmationModel}
        ></LogoutConfirmationModal>
      )}
      {showPopupAddAppointment && (
        <Appointment
          setShowAppointment={setShowPopupAddAppointment}
          showAppointment={showPopupAddAppointment}
          setShowBookingIframe={setShowBookingIframe}
        />
      )}
      {showBookingIframe && (
        <IframeModel
          title={
            <div className="titleSpan">
              <UserAddOutlined style={{ fontSize: "35px" }} />{" "}
              <Title level={5} style={{ margin: "0px" }}>
                Book Appointment
              </Title>
              <Button
                type="primary"
                shape="round"

                onClick={onExistingPatientClick}
              >
                Existing Patient
              </Button>
            </div>
          }
          showModal={showBookingIframe}
          setShow={setShowBookingIframe}
        />
      )}
      {showPopupAddPatient && (
        <AddOrEditPatient
          title={
            <div className="titleSpan">
              <UserAddOutlined style={{ fontSize: "35px" }} />{" "}
              <Title level={5} style={{ margin: "0px" }}>
                NEW PATIENT
              </Title>
            </div>
          }
          showModal={showPopupAddPatient}
          setShow={setShowPopupAddPatient}
          onConfirmation={() => { }}
        />
      )}
      <Row justify="space-between">
        <Col md={8}>
          <div style={{ textAlign: "left" }}>
            <Link to={"/"}>
              <img
                style={{
                  width: logoWidth,
                  marginLeft: logoMarginLeft
                }}
                className={`header-logo`}
                src={headerLogo}
                alt="logo"
              />
            </Link>
          </div>
        </Col>
        <Col md={16}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              justifyContent: "flex-end",
            }}
          >
            {
              clinic.isOnboarded && (
                <div style={{ width: "280px" }}>
                  {showSearchBar && (
                    <SearchBar
                      clinicId={clinic.clinicId}
                      onSelectCallback={onSelectCallback}
                      enterButton={false}
                    />
                  )}
                </div>
              )
            }

            {
              clinic.isOnboarded && (
                <Dropdown
                  overlay={AddPatient}
                  trigger={["click"]}
                  placement="bottomRight"
                  overlayClassName="new-patient-btn-dropdown"
                >
                  <Button
                    type="primary"
                    className="new-patient-btn"
                    style={{
                      borderRadius: "5rem",
                      width: "7rem",
                      fontFamily: "Mulish",
                      color: headerButtonColor,
                      backgroundColor: headerButtonBackgroundColor,
                    }}
                  >
                    +New
                  </Button>
                </Dropdown>
              )

            }

            {
              clinic.isOnboarded && (
                <Badge count={clinic.notificationCount}>
                  <BellOutlined
                    style={{
                      fontSize: "25px",
                      color: "#77849E",
                    }}
                    onClick={() => {
                      history.push("/clinic/notifications");
                    }}
                  />
                </Badge>
              )
            }
            <div
              style={{
                borderLeft: "2px solid #EFEFF9",
                display: "inline-block",
                padding: "14px",
              }}
            >
              <Dropdown overlay={menu} trigger={["hover"]}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar
                    size={39}
                    icon={<UserOutlined  />}
                    style={{ marginRight: "0.5rem" }}
                  />
                  <Text
                    style={{ fontWeight: "lighter", color: navBarTextColor }}
                  >
                    {clinic.clinicName ? clinic.clinicName : ""}
                  </Text>
                </a>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps, { logout })(HeaderComponent);
